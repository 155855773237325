import React from 'react';
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import SignInForm from './SignInForm';
import { signIn } from '../../actions/authActions';
import { processError } from '../../utils/helper';
class SignInFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  handleSubmit(values) {
    this.props
      .mutationsignIn({
        variables: values,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        //console.log('handlesubmit then');
        if (response.data.signIn.errors.length <= 0) {
          //console.log('data response submit login');
          //console.log(response.data.signIn);
          //this.props.signInDispatcher(response.data.signIn.token);
          this.props.signInDispatcher(response.data.signIn);

          //this.props.history.push('/tour?load=1');
          window.location.href = '/registration'; // patch to recharge patch to settingsload , but in the future see how to call a ql and recharge from there , so don' recharge all the whole page
        } else {
          this.setState({
            errors: response.data.signIn.errors,
          });
        }
      })
      .catch((err) => {
        processError(err, this.props);
      });
  }

  render() {
    //console.log('form container props');
    //console.log(this.props);
    return (
      <div>
        <SignInForm
          onSubmit={this.handleSubmit.bind(this)}
          errors={this.state.errors}
          t={this.props.t}
          changeLanguage={this.props.t}
        />
      </div>
    );
  }
}

const signInMutation = gql`
  mutation signIn($nick: String!, $password: String!) {
    signIn(nick: $nick, password: $password) {
      token
      company
      nick
      is
      ai
      pe
      ar
      gu
      errors {
        key
        value
      }
    }
  }
`;

const SignInWithData = graphql(signInMutation, {
  name: 'mutationsignIn',
})(withRouter(SignInFormContainer));

const mapDispatchToProps = (dispatch) => ({
  signInDispatcher(response) {
    dispatch(signIn(response));
  },
});

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const SignInWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInWithData);

export default SignInWithDataAndState;

import React, { Component } from 'react';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import { Button } from '../../layouts/cssstyled';
import ShowIcon from '../icons/ShowIcon';

import {
  fieldsValidator,
  processError,
  processNewValues,
  processValues,
} from '../../utils/helper';
import { resolvePathObj } from '../../utils/commonutils';
import ChannelsMessaging from '../ejobmail/ChannelsMessaging';

const fields = {
  signaturesms: {
    validators: ['maxLength:65'],
  },
};

const nameForm = 'formESettings';

const validate = (values, props) => {
  let result = fieldsValidator(fields, values);
  /*
   if (Object.keys(result).length === 0 ) {
     if (values.newpass !== values.newpass2 ) {
      result.newpass2 = t('mobilsemError.newpassDiff');
     }
   }
  */
  return result;
};

/*

            <Field
              key="email"
              inputName="email"
              name="email"
              nameForm="formSettings"
              component={FormField}
              label= "form.email"
              sublabel= "info.esettingsEmail"
              type="text"
              typeInput="text"
            />

            <Field
              inputName="verified"
              sublabel="info.verified"
              name="verified"
              label="form.verified"
              disabled={true}
              formProps={this.props}
              view={true}
              pathInTables="user.fields.verified"
              nameForm="userForm"
              component={FormField}
              type="checkbox"
              typeInput="checkbox"
            />

            <Field
              inputName="password"
              name="password"
              formProps={this.props}
              label= "form.password"
              sublabel= "info.esettingsPass"
              nameForm="userForm"
              component={FormField}
              type="password"
              autocomplete="new-password"
              typeInput="password"
            />


          <div className="formSectionExpand">
            <Field
              key="smtpserver"
              inputName="smtpserver"
              label="SMTP Server"
              name="smtpserver"
              nameForm="formSettings"
              component={FormField}
              type="text"
              typeInput="text"
            />
            <Field
              key="smtpport"
              inputName="smtpport"
              label="SMTP Port"
              name="smtpport"
              nameForm="formSettings"
              component={FormField}
              type="text"
              typeInput="text"
            />
          </div>
 */
class Form extends Component {
  render() {
    let titleStyle = {
      marginTop: '3px',
      fontSize: '23px',
      color: '#666666',
      marginBottom: '10px',
    };
    let subtitleStyle = {
      marginTop: '15px',
      fontSize: '19px',
      color: '#474747',
      marginBottom: '10px',
    };
    const { error, pristine, invalid, submitFailed, submitting, t } =
      this.props;
    const { handleSubmit } = this.props;
    //console.log('props childe', this.props);

    return (
      <form onSubmit={handleSubmit}>
        <div
          key="accounting"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <div
            className="formLabel"
            style={{ marginLeft: '10px', fontSize: '19px' }}
          >
            {this.props.t('form.channelsfilters')}{' '}
          </div>
          <div
            style={{
              marginLeft: '10px',
              marginTop: '5px',
              marginBottom: '20px',
            }}
          >
            <ChannelsMessaging always="1" {...this.props} />
          </div>

          <Field
            label="form.header"
            inputName="header"
            name="header"
            formProps={this.props}
            nameForm={nameForm}
            component={FormField}
            type="textarea"
            typeInput="textarea"
          />
          <Field
            label="form.signature"
            inputName="signature"
            name="signature"
            formProps={this.props}
            nameForm={nameForm}
            component={FormField}
            type="textarea"
            typeInput="textarea"
          />
          <Field
            label="form.signaturesms"
            inputName="signaturesms"
            name="signaturesms"
            formProps={this.props}
            nameForm={nameForm}
            component={FormField}
            width="550px"
            type="text"
            typeInput="text"
          />

          <Field
            inputName="templatenewpreregistration"
            name="templatenewpreregistration"
            label="form.templatenewpreregistration"
            formProps={this.props}
            formState={this.state}
            pathInTables="ejobmail.fields.etemplate_id"
            nameForm={nameForm}
            component={FormField}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
          />

          <Field
            label={t('form.labels') + ' ' + t('table.etemplate')}
            labelNoTranslate="1"
            inputName="labelstemplate"
            name="labelstemplate"
            formProps={this.props}
            nameForm={nameForm}
            component={FormField}
            type="textarea"
            typeInput="textarea"
          />
          <Field
            label="form.badges"
            inputName="badgetinfo"
            name="badgetinfo"
            formProps={this.props}
            nameForm={nameForm}
            component={FormField}
            type="textarea"
            typeInput="textarea"
          />
        </div>
        <div key="toolbar" style={{ flexDirection: 'column' }}>
          <div className="formRow">
            <div style={{ position: 'relative', margin: 'auto' }}>
              <div className="iconInput">
                <ShowIcon size="25" color="#ffffff" icon="saveblack" />
              </div>
              <Button
                type="submit"
                disabled={submitting}
                style={{ paddingLeft: '35px' }}
              >
                {t('form.save')}
              </Button>
            </div>
          </div>
          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">
              {invalid && submitFailed && t('form.haserrors')}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const ReduxedForm = reduxForm({
  form: nameForm,
  validate,
  enableReinitialize: true, // the state is changed a lot of times so , need this for the last change on state when finally ql is loaded
})(Form);

function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values
  const statesReturn = { myState: state };
  const initialValues = {};
  const record = resolvePathObj(
    ownProps,
    'containerPropsForm.getOrgSettings.getOrgSettings.record'
  );

  if (typeof record !== 'undefined') {
    const values = JSON.parse(record);
    statesReturn.initialValues = {};
    //console.log('fields',ownProps.aFields);
    for (const [key, value] of Object.entries(ownProps.aFields)) {
      if (typeof resolvePathObj(values, key) !== 'undefined') {
        //console.log('key',key);
        let [group, field] = key.split('.');
        //console.log(group,field);
        statesReturn.initialValues[field] = values[group][field];
      }
    }
  }
  return statesReturn;
}

const ComponentWithDataAndState = connect(mapStateToProps, null)(ReduxedForm);

export default ComponentWithDataAndState;

import { toPrice, getInputValue, getToday } from '../../utils/commonutils';
import {
  getRecordFromOptionsIndirectOnFilter,
  getRecordValues,
} from '../../utils/helper';

const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //const log = true;
  const log = false;
  if (log) console.log('onChangeInput crudListCode params:', params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    fns,
  } = params;

  const result = {};

  //  if (inputName === 'tour_id' || inputName === 'customerstatus_id' || inputName === 'registrationstatus_id') {

  if (action !== 'initForm') {
    // initForm dispatched on form creation, have no .fns, so logic can't be executed
    let tollfree;
    // console.log('zzz inputFullName', inputFullName, newValue);
    if (inputFullName === '_multidate' && newValue) {
      formProps.change('inactive', 'null');
    }
  }
  // result.newStates = newStates;

  return result;
};

export default crudCode;

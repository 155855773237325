import React from 'react';
import { resolvePathObj, getInputValue } from '../../utils/commonutils';

const Comp = (props) => {
  const idTour = resolvePathObj(props, 'formProps.initialValues.id', {
    compare: undefined,
    notFound: {},
  });
  const urlTour = `${window?.appConfig?.REACT_APP_SIGNUP_TOUR_URI}/${idTour}`;

  const fieldValue = getInputValue(props, 'onlineregtype', 'tourForm');
  if (!fieldValue || fieldValue.toString() !== '2') return null;
  return (
    <div style={{ paddingTop: '12px', alignItems: 'top' }}>
      <div>
        <label key="label" className="formLabel">
          {props.t('form.linkRegOnline')}
        </label>
      </div>
      <div style={{ paddingTop: '7px' }}>
        <a target="_blank" rel="noopener" href={urlTour}>
          {urlTour}
        </a>
      </div>
      <div style={{ paddingTop: '12px' }}>
        <label key="label" className="formLabel">
          {props.t('form.code')}
        </label>
      </div>
      <div style={{ paddingTop: '7px' }}>{idTour}</div>
    </div>
  );
};

export default Comp;

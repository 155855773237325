const themes = {
  sky: {
    Primary: '#74b4e6',
    PrimaryLight: '#a8e6ff',
    PrimaryDark: '#4984ab',
    PrimarySuperDark: '#316296',
    PrimaryText: '#3f84b3',
    Light: '#96BEDA',
    LightText: '#000000',
    Contrast: '#FFE9AA',
    ContrastSuperDark: '#E4B734',
    ContrastText: '#333333',
    Secondary: '#616161',
    SecondaryLight: '#8e8e8e',
    SecondaryDark: '#5d99c6',
    SecondaryText: '#ffffff',
    Background: '#EEEEEE',
    BackgroundLight: '#FFFFFF',
    BackgroundDark: '#000000',
    TextOnBackgroundPrimary: '#FFFFFF',
    TextOnBackgroundSecondaryLight: '#FFFFFF',
    TextOnBackgroundLight: '#666666',
    TextOnBackgroundDark: '#333333',
  },
  red: {
    Primary: '#ff855c',
    PrimaryLight: '#ffb68a',
    PrimaryDark: '#c75631',
    PrimarySuperDark: '#c71f16',
    PrimaryText: '#000000',
    Contrast: '#bce629',
    ContrastText: '#000000',
    Secondary: '#616161',
    SecondaryLight: '#8e8e8e',
    SecondaryDark: '#89150f',
    SecondaryText: '#89150F',
    Background: '#EEEEEE',
    BackgroundLight: '#FFFFFF',
    BackgroundDark: '#000000',
    TextOnBackgroundPrimary: '#FFFFFF',
    TextOnBackgroundSecondaryLight: '#FFFFFF',
    TextOnBackgroundLight: '#666666',
    TextOnBackgroundDark: '#333333',
  },
  green: {
    Primary: '#9abc80',
    PrimaryLight: '#ffffe1',
    PrimaryDark: '#75955d',
    PrimarySuperDark: '#0c2f0b',
    PrimaryText: '#000000',
    Contrast: '#e68145',
    ContrastText: '#000000',
    Secondary: '#616161',
    SecondaryLight: '#8e8e8e',
    SecondaryDark: '#144E13',
    SecondaryText: '#144E13',
    Background: '#EEEEEE',
    BackgroundLight: '#FFFFFF',
    BackgroundDark: '#000000',
    TextOnBackgroundPrimary: '#FFFFFF',
    TextOnBackgroundSecondaryLight: '#FFFFFF',
    TextOnBackgroundLight: '#666666',
    TextOnBackgroundDark: '#333333',
  },
  dark: {
    Primary: '#7bb4dd',
    PrimaryLight: '#aee6ff',
    PrimaryDark: '#4984ab',
    PrimaryText: '#000000',
    Secondary: '#616161',
    SecondaryLight: '#8e8e8e',
    SecondaryDark: '#5d99c6',
    SecondaryText: '#ffffff',
    Background: '#EEEEEE',
    BackgroundLight: '#FFFFFF',
    BackgroundDark: '#000000',
  },
};
export default themes;

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql, compose } from 'react-apollo';
import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';

import { Field, reduxForm, change } from 'redux-form';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from '../../utils/helper';

import ShowIcon from '../icons/ShowIcon';
import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import { CrudNav } from '../CrudNav';
import CrudFilterOrderBar from '../CrudFilterOrderBar';
import IconLoading from '../icons/IconLoading';
import {
  MsgError,
  ListDataRercord,
  ListDataHeader,
  PageSettings,
} from '../ListData';
import { getInputValue } from '../../utils/commonutils';

import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import { gqlActions } from '../../gqls/gqlactions';
import ListForm from './ListForm';

import CheckdepositdetHeader from './CheckdepositdetHeader';

const templates = [];

const QUERY = loader('./CheckdepositdetListPage.query.gql');

const crudCode = {};
const tableCrud = 'checkdepositdet';
const action = 'list';
const nameForm = '';
const qlName = 'crud_list_' + tableCrud;

const formFilterName = 'listFiltercheckdepositdet';
const formPagerName = 'checkdepositdetPager';
let table = Tables[tableCrud];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crudTable: tableCrud,
      mainForm: formFilterName,
      listMode: 'rows',
      counter: 0,
      dialogOpened: {},
      date: Date.now,
      actionsave: 'list',
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      id: '',
      listviewFields: {},
      visibleListViewFields: false,
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 4000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    if (localStorage.getItem('Error') !== null) {
      this.setState({ date: Date.now() });
      clearInterval(this.state.intervalId);
    }
    if (Tables[tableCrud].pollInterval) {
      this.props[qlName].refetch();
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  handleSubmit = async (values) => {
    await gqlActions('', 'setCheckDepositedDet', this.props, values)
      .then((response) => {
        this.props.crud_list_checkdepositdet.refetch();
      })
      .catch((err) => {
        processError(err, this.props, true);
      });
  };

  navPage = (navTo, page) => {
    let newPage = parseInt('0' + page._page);
    if (navTo === 'right' && page._page < page._totalpages) {
      newPage++;
    }
    if (navTo === 'left' && page._page > 1) {
      newPage--;
    }
    if (navTo === 'first') {
      newPage = 1;
    }
    if (navTo === 'last') {
      newPage = page._totalpages;
    }
    let strNewpage = parseInt('0' + newPage.toString());
    this.props.change('_page', strNewpage);
  };

  render() {
    const { t, ...otherProps } = this.props;

    let aElements = [];

    aElements.push(
      <div
        key="tit2"
        style={{
          fontSize: '20px',
          color: '#7F7F7F',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('table.payments')}
      </div>
    );

    let result;

    if (this.props[qlName] && this.props[qlName].pagecheckdepositdets) {
      result = this.props[qlName].pagecheckdepositdets;
      aElements.push(
        <ListForm
          crudAction="View"
          key="mainform"
          result={result}
          id={this.props.match.params.id}
          onSubmit={this.handleSubmit}
          handleSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          toolbarFunctions={this.toolbarFunctions}
          t={this.props.t}
        />
      );

      if (result.page._totalpages > 1) {
        aElements.push(
          <CrudNav
            key="crudnav2"
            t={t}
            page={result.page}
            navPage={this.navPage}
          />
        );
      } else {
        aElements.push(
          <div className="xrow" key="rfound">
            <div className="cell"></div>
            <div
              className="cell"
              style={{ color: '#999999', paddingTop: '20px' }}
            >
              {result.page._totalrecords} {t('info.recordsFound')}
            </div>
          </div>
        );
      }
    }

    return <div>{aElements}</div>;
  }
}

export default List;

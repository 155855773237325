import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  getInputValue,
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

import TitleRegistrationOnlinePerson from './TitleRegistrationOnlinePerson';
import TitleRegistrationOnlineTour from './TitleRegistrationOnlineTour';

import SubListCheck from './SubListCheckCustomerstatus';

const tableCrud = 'tourcategory';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) =>
  fieldsValidator('tourcategory', values, props, nameForm);

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  updatePageSettingsFields = (e) => {
    // e.target.name = id record
    let fieldsDB = tryParseJSON(
      getInputValue(this.props, 'filteredcustomerstatuss', { nameForm }),
      []
    );

    if (e.target) {
      const fieldIndex = fieldsDB.findIndex(
        (fieldId) => e.target.name === fieldId
      );
      if (fieldIndex === -1) {
        fieldsDB.push(e.target.name);
        // console.log("news fieldsDB after add:", fieldsDB);
      } else {
        fieldsDB.splice(fieldIndex, 1);
        // console.log("news fieldsDB after delete:", fieldsDB);
      }

      this.props.dispatch(
        change(nameForm, 'filteredcustomerstatuss', fieldsDB)
      );
    }
  };

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />

        <div className="formSection">
          <Field
            inputName="name"
            name="name"
            formProps={this.props}
            pathInTables="tourcategory.fields.name"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            autoFocus={this.props.formState.id ? '' : '1'}
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.name
            }
          />
        </div>
        <TitleRegistrationOnlinePerson
          {...this.props}
          containerState={this.state}
          formProps={this.props}
          formState={this.props.formState}
          executeCode={this.props.executeCode}
          t={t}
        />
        <div className="formSection">
          <Field
            inputName="regonline_occupation"
            name="regonline_occupation"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_occupation"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_birthdata"
            name="regonline_birthdata"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_birthdata"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_contactEmergency"
            name="regonline_contactEmergency"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_contactEmergency"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_nationality"
            name="regonline_nationality"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_nationality"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_nameunmarried"
            name="regonline_nameunmarried"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_nameunmarried"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_phones"
            name="regonline_phones"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_phones"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_address"
            name="regonline_address"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_address"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_NationalIdentity"
            name="regonline_NationalIdentity"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_NationalIdentity"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_passport"
            name="regonline_passport"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_passport"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_imageright"
            name="regonline_imageright"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_imageright"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
        </div>
        <TitleRegistrationOnlineTour
          {...this.props}
          containerState={this.state}
          formProps={this.props}
          formState={this.props.formState}
          executeCode={this.props.executeCode}
          t={t}
        />
        <div className="formSection">
          <Field
            inputName="regonline_status"
            name="regonline_status"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_status"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_stopspoint"
            name="regonline_stopspoint"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_stopspoint"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_room_type"
            name="regonline_room_type"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_room_type"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="regonline_room_share"
            name="regonline_room_share"
            formProps={this.props}
            pathInTables="tourcategory.fields.regonline_room_share"
            formState={this.props.formState}
            nameForm="tourcategoryForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
        </div>
        <Field
          inputName="filteredcustomerstatuss"
          name="filteredcustomerstatuss"
          formProps={this.props}
          pathInTables="tourcategory.fields.filteredcustomerstatuss"
          formState={this.state}
          nameForm="tourroomForm"
          component={FormField}
          executeCode={this.executeCode}
          type="hidden"
          typeInput="hidden"
          tableCrud={tableCrud}
        />

        <Field
          inputName="filterbycustomerstatus"
          name="filterbycustomerstatus"
          formProps={this.props}
          pathInTables="tourcategory.fields.filterbycustomerstatus"
          formState={this.state}
          nameForm="tourroomForm"
          component={FormField}
          executeCode={this.executeCode}
          type="checkbox"
          typeInput="checkbox"
          tableCrud={tableCrud}
          onChange={(event, newValue, previousValue) => {
            this.props.executeCode('onChangeInput', {
              nameForm,
              inputFullName: `filterbycustomerstatus`,
              formProps: this.props,
              formState: this.props.formState,
              event,
              newValue,
              previousValue,
            });
          }}
        />

        <SubListCheck
          nameForm={nameForm}
          disabled={
            this.props.formState.disabledFields &&
            this.props.formState.disabledFields['filterbycustomerstatus']
          }
          t={t}
          formProps={this.props}
          formState={this.props.formState}
          updatePageSettingsFields={this.updatePageSettingsFields}
        />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        if (log) console.log('fieldKey', fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import React from 'react';
import ShowIcon from '../icons/ShowIcon';
import { getInputValue, processValues, tt } from '../../utils/commonutils';
import { valPhone } from '../../utils/validators';

import {
  submit,
  change,
  arrayRemove,
  arrayInsert,
  arrayRemoveAll,
} from 'redux-form';
import strFragments from '../../defStrFragmentsQls.js';
import { fetchQl } from '../../apolloClient';
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('params client onChangeInput', params);
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
  } = params;

  /*
   popup denote that value is sent through window, and is here not because there is change, but
   must execute change manually and follow the same logic that onChange
   */
  const result = {};

  // dont add hiddenFields, the logic ist just update when is initForm or on change affected fields
  let newStates = { disabledFields: {}, warningFields: {} };

  if (
    inputFullName === 'firstname' ||
    inputFullName === 'lastname' ||
    action === 'initForm'
  ) {
    newStates.warningFields.firstname = [];

    let lastname;
    let firstname;

    if (inputFullName === 'firstname') {
      firstname = newValue;
    } else {
      firstname = getInputValue(formProps, 'firstname', { nameForm });
    }
    if (inputFullName === 'lastname') {
      lastname = newValue;
    } else {
      lastname = getInputValue(formProps, 'lastname', { nameForm });
    }

    if (lastname) lastname = lastname.trim();
    if (firstname) firstname = firstname.trim();

    let resCheck = [];
    //console.log('lastname && firstname', lastname, firstname);

    if (lastname && firstname) {
      const filter = {
        _and: [{ firstname }, { lastname }],
      };
      if (formState.actionsave === 'update') {
        filter._and.push({ _ne: { id: formState.id } });
      }
      resCheck = await fetchQl(
        `
        query CustomerList ($_filter: String) {
          customers (_filter: $_filter) {
            ${strFragments.customer}
          }
        }`,
        [{ name: '_filter', type: 'String', value: JSON.stringify(filter) }],
        {
          dataName: 'customers',
          props: formProps, // important object that contains myState , for pelemaster resolver org
        }
      );
    }

    if (resCheck && resCheck.length) {
      newStates.warningFields.firstname.push(
        <span key="msg">* {tt(formProps.t, 'info.customer_nameExist')}</span>
      );
      resCheck.map((xcustomer) => {
        const customer = processValues(
          props,
          'customer',
          xcustomer,
          'toClient',
          'view'
        );
        let customerDetails = [];
        customerDetails.push(' ' + customer.name + ' / ');
        if (customer.addresses) {
          if (customer.addresses[0].addressline1)
            customerDetails.push(customer.addresses[0].addressline1);
          if (
            customer.addresses[0].city_id &&
            customer.addresses[0].city_id.name
          )
            customerDetails.push(' ' + customer.addresses[0].city_id.name);
          if (customer.birthdate)
            customerDetails.push(', ' + customer.birthdate);
        }
        newStates.warningFields.firstname.push(
          <div key={customer.id}>
            <a
              target="_blank"
              className="aBlue"
              href={'/customer-view/' + customer.id + '?popup=1'}
            >
              <ShowIcon key="i" size="20" color="#0071e6" icon="pele" />
              {customerDetails}
            </a>
          </div>
        );
      });
    }
  }

  if (
    action === 'initForm' ||
    action === 'addLine' ||
    inputFullName === 'identitydocsidaddressisother' ||
    inputFullName === 'identitydocsidnameisother'
  ) {
    const identitydocsValue = getInputValue(formProps, 'identitydocs', {
      nameForm,
    });
    let totalLines;
    if (
      typeof identitydocsValue === 'undefined' ||
      identitydocsValue === null ||
      !identitydocsValue ||
      !identitydocsValue.length
    ) {
      totalLines = 0;
    } else {
      totalLines = identitydocsValue.length; // with empty lines value is = [] , means, length = 0, || 0 to make sure get numeric value
    }
    if (action === 'addLine') totalLines++;

    let isChangedDisableState = false;
    let isChangedHiddenState = false;
    if (!formState || !formState.disabledFields) {
      isChangedDisableState = true;
    }
    if (!formState || !formState.hiddenFields) {
      isChangedHiddenState = true;
    }
    for (let a = 0; a < totalLines; a++) {
      let idaddressisotherLine;
      let idnameisotherLine;

      // if ( !identitydocsValue[a]) { ; no secure ask for false
      if (action === 'addLine' && a + 1 === totalLines) {
        // default values for new line that not exist
        idnameisotherLine = false;
        idaddressisotherLine = false;
      } else {
        if (inputFullName === 'identitydocsidaddressisother' && line === a) {
          idaddressisotherLine = newValue;
          idnameisotherLine = identitydocsValue[a].idnameisother || false;
        } else if (
          inputFullName === 'identitydocsidnameisother' &&
          line === a
        ) {
          idnameisotherLine = newValue;
          idaddressisotherLine = identitydocsValue[a].idaddressisother;
        } else {
          // check virgin not touched is undefined
          idnameisotherLine = identitydocsValue[a].idnameisother;
          idaddressisotherLine = identitydocsValue[a].idaddressisother;
        }
        // make sure not have undefined, (will be false)
        idnameisotherLine = Object.is(idnameisotherLine, undefined)
          ? false
          : idnameisotherLine;
        idaddressisotherLine = Object.is(idaddressisotherLine, undefined)
          ? false
          : idaddressisotherLine;
      }

      // set local state form with real check

      if (!newStates.hiddenFields) {
        newStates.hiddenFields = {}; // important to dont reset eveery time, just read the changes after initForm for all situations where add hiddenFields
      }

      newStates.hiddenFields['identitydocslastname' + a] = !idnameisotherLine;
      newStates.hiddenFields['identitydocsfirstname' + a] = !idnameisotherLine;

      newStates.hiddenFields['identitydocsaddressline1' + a] =
        !idaddressisotherLine;
      newStates.hiddenFields['identitydocsaddressline2' + a] =
        !idaddressisotherLine;
      newStates.hiddenFields['identitydocsaddressline3' + a] =
        !idaddressisotherLine;
      newStates.hiddenFields['identitydocscp' + a] = !idaddressisotherLine;
      newStates.hiddenFields['identitydocscity' + a] = !idaddressisotherLine;
    }
  }

  //console.log('inputFullName',inputFullName, line);
  if (inputFullName === 'addressescity_id') {
    const citycp = newValue && newValue.pcs ? JSON.parse(newValue.pcs) : null;
    const fullAddresses = getInputValue(formProps, 'addresses', { nameForm });
    //console.log('fullAddresses', fullAddresses);
    let currentAddressLine = getInputValue(formProps, 'addresses', {
      nameForm,
      line,
      notFound: undefined,
      log: false,
    });
    //console.log('currentAddressLine', currentAddressLine);
    let changeRow = false;
    //if (popup ) {  // important uncomment because in the case popup, or case ,
    //                  autocomplete , when there is the only one error on city empty, that customer comes from
    //                  voyages, withoout city_id, the city id is not changed
    //                  and produce a error on redux form, and the values are not more updated for the validation
    //                  so it's need update manually like this
    currentAddressLine = { ...currentAddressLine, city_id: newValue };

    //console.log('currentAddressLine after popup merge', currentAddressLine);
    fullAddresses[line] = currentAddressLine;
    changeRow = true;
    //}
    const currentcp = getInputValue(formProps, 'addresses', {
      nameForm,
      line,
      subfield: 'cp',
      log: false,
    });
    if (citycp) {
      // only if city has cp , valid and smart choose CP
      //console.log('citycp', citycp);
      let foundCurrentCP = false;
      citycp.map((linecp) => {
        if (linecp.pc == currentcp) {
          // == because differnts possible types
          foundCurrentCP = true;
        }
      });
      if (!foundCurrentCP) {
        let newCP;
        if (citycp.length === 1) {
          // only one cp found, then load that
          newCP = citycp[0].pc;
        } else {
          newCP = '';
        }
        //console.log('newCP', newCP);
        // currentline
        if (typeof currentAddressLine === 'undefined' || !currentAddressLine) {
          currentAddressLine = {}; // normally is don't needed because all empty row has at least .id ke
        } else {
          currentAddressLine = {
            ...currentAddressLine,
            cp: { id: newCP, name: newCP },
          };
          // change with dispatch is not working, when cityid comes empty from voyages
          //await formProps.dispatch( change (formProps.form, `addresses[${line}].cp`, { id: newCP , name: newCP }));
          //await formProps.dispatch( change (formProps.form, `addresses[${line}].city_id`, newValue));
        }
        fullAddresses[line] = currentAddressLine;
        // formProps.change('addresses
        changeRow = true;
      }
    }
    if (changeRow) {
      // need clean the row, if some fiels are changed, because redux-form need
      // all the new value for the whole line
      formProps.dispatch(arrayRemoveAll(formProps.form, 'addresses'));
      formProps.change('addresses', fullAddresses);
    }
  }

  if (parentField === 'phones' && action && action === 'delete') {
    // clean warnings for deleted line
    const fieldNameWithLine = `phonesphone${line}`;
    newStates.warningFields[fieldNameWithLine] = null;
  }

  if (inputFullName === 'phonesphone' || action === 'initForm') {
    const fullPhones = getInputValue(formProps, 'phones', { nameForm });

    let start;
    let end;
    if (inputFullName === 'phonesphone') {
      start = line;
      end = line;
    } else {
      start = 0;
      // need - 1 if fullPhones is null or empty, and if is not null, rest -1. length = 1, for 0 to 0
      end = fullPhones ? fullPhones.length - 1 : -1;
    }
    let realphone;
    let phoneError;
    for (let n = start; n <= end; n++) {
      //console.log('start end n ', start, end, n );
      const fieldNameWithLine = `phonesphone${n}`;
      // default wihtout warnings every time that touch the field
      newStates.warningFields[fieldNameWithLine] = []; //null;
      let phone;

      if (inputFullName === 'phonesphone') {
        phone = newValue;
      } else {
        // don't needed use  getInputValue, because fullPhones was retrieved
        // phone = getInputValue(formProps, 'phones', { nameForm, line: n, subfield: 'phone', log:false });
        //console.log(n, fullPhones);
        phone = fullPhones[n].phone;
      }

      //let currentPhonesLine = getInputValue(formProps, 'phones', {nameForm, line: n, notFound: undefined, log:false });
      const typePhone = fullPhones[n].type;

      const resPhone = valPhone(phone, n, formProps, null, null, {
        getCleaned: true,
        typePhone,
      });
      //console.log('resPhone ', resPhone );
      if (resPhone) {
        // if is not null
        realphone = resPhone.realphone;
        phoneError = resPhone.phoneError;
        const countryISO3 = resPhone.countryISO3;

        const warningPhone = (
          <div key="p">
            <ShowIcon
              key="i"
              size="15"
              color={phoneError ? 'red' : 'green'}
              icon={phoneError ? 'close' : 'check'}
            />
            <span>
              {realphone} {countryISO3}
            </span>
          </div>
        );
        newStates.warningFields[fieldNameWithLine] = [warningPhone]; // it's a error
      }
    }
    if (inputFullName === 'phonesphone') {
      const ph = phoneError ? null : realphone;
      //console.log('set realphone', realphone);
      formProps.dispatch(change(formProps.form, `phones[${line}].ph`, ph));
    }

    /*
    DISCONTINUED, reemplaced by previous block
    this block produce a bug and stop validations
    it's due a mixing dispath for remove and change at same time , when are typing values
    dispatch is already dangerous inside a change and .change became worst

    if (typeof currentPhonesLine === 'undefined' || !currentPhonesLine) {
      currentPhonesLine = {}; // normally is don't needed because all empty row has at least .id
    } else {
      currentPhonesLine = { ...currentPhonesLine,  ph: realphone}
    }
    fullPhones[line] = currentPhonesLine;
    // formProps.change('addresses
    changeRow = true;

    if (changeRow) {
      // need clean the row, if some fiels are changed, because redux-form need
      // all the new value for the whole line
      formProps.dispatch( arrayRemoveAll(formProps.form, 'phones'));
      formProps.change('phones', fullPhones);
    }*/
  }
  /*
  // Resolve main mobile phone, suspended; now in trigger
  if (inputFullName === 'phonesphone' || inputFullName === 'phonestype') {
    let mainMobile = null;
    const fullPhones = getInputValue(formProps, 'phones', { nameForm });
    for (let n = 0; n < fullPhones.length; n++) {
      if (inputFullName === 'phonestype' && line === n) {

      }
    }
  }*/
  if (inputFullName === 'addressescp') {
    const citycp = newValue;
    //console.log('citycp', citycp);
    const fullAddresses = getInputValue(formProps, 'addresses', { nameForm });
    let currentcity = getInputValue(formProps, 'addresses', {
      nameForm,
      line,
      subfield: 'city_id',
      log: false,
    });
    let currentAddressLine = getInputValue(formProps, 'addresses', {
      nameForm,
      line,
      notFound: undefined,
      log: false,
    });
    if (typeof currentcity === 'undefined' || !currentcity)
      currentcity = { id: '', name: '' };
    //console.log('currentcity', currentcity);
    if (citycp) {
      // event.preventDefault(); // important if not changes in redux are not taken (maybe due focus)
      fullAddresses[line] = {
        ...currentAddressLine,
        city_id: { id: citycp.city_id, name: citycp.city_name },
        // need to send the cp, because newValue last change is not stored, last char typed
        cp: { id: citycp.id, name: citycp.id },
      };
      formProps.dispatch(
        change(formProps.form, `addresses[${line}].city_id`, {
          id: citycp.city_id,
          name: citycp.city_name,
        })
      );
      formProps.dispatch(
        change(formProps.form, `addresses[${line}].cp`, {
          id: citycp.id,
          name: citycp.id,
        })
      );

      //console.log('fullAddresses[line]', fullAddresses[line]);
      // formProps.dispatch( arrayRemoveAll(formProps.form, 'addresses'));
      // formProps.change('addresses', fullAddresses);
    }
  }

  if (inputFullName === 'customerstatus_id') {
    // autocomplete  get only pure id, before compare changes with old (not really old, but previous value)
    const idNew = newValue && newValue.id ? newValue.id : newValue;
    let idOld = getInputValue(formProps, inputFullName, { nameForm });
    if (idOld && idOld.id) idOld = idOld.id;
    if (idNew !== idOld || !idNew) {
      // if idNew is null, then clean too
      result.changeFieldsLater = { customersubstatus_id: null };
    }
  }
  result.newStates = newStates;
  return result;
};

export default crudCode;

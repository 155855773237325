export const fieldsValidator = (tableName, values, props) => {
  let resulfieldsValidator = {};

  let messageType;

  if (!props) {
    props = values;
    values = tableName;
    messageType = props.messageType;
  } else {
    messageType = props.containerState?.dialogType;
  }


  // %%% this must be on validates
  if (values.body && values.body.includes('{{')) {
    resulfieldsValidator.etemplate_id = 'validator.template';
  }

  if (messageType === 'SMS') {
    if (values.withMobile && values.withMobile === '0') {
      resulfieldsValidator.customer_id = 'validator.required';
    }
    if (!values.templatesms || values.templatesms.length < 4) {
      resulfieldsValidator.templatesms = 'validator.required';
    }
  } else {
    
    if (!values.title || values.title.length < 4) {
      resulfieldsValidator.title = 'validator.required';
    }
    if (!values.template || values.template.length < 4) {
      resulfieldsValidator.template = 'validator.required';
    }
  }

  return resulfieldsValidator;
};

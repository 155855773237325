import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import SelectNext from 'react-select-next';
import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';
import ShowIcon from '../icons/ShowIcon';
import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  getInputValue,
  getPermission,
  tryParseJSON,
  getNameHotelAndRoom,
} from '../../utils/commonutils';
import { getSession } from '../../utils/auth';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

const tableCrud = 'tour';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => {
  if (values.body && values.body.includes('{{')) {
    return { etemplate_id: 'validator.template' };
  }
  return true;
};
const aServices = {
  type: 'String',
  dbtype: 'Json',
  labelChildsShow: true,
  placeHolderShowChildsShow: false,
  subfields: {
    id: {
      type: 'ID',
      typeInput: 'hidden',
      label: 'form.id',
    },
    tourservices_id: {
      type: 'String',
      label: 'form.service',
      typeInput: 'selectAutocomplete',
      validators: ['required'],
      onChange: true,
    },
    validation: {
      label: 'validator.validation',
      type: 'String', // virtual parameter needs to define type for graphql
      realType: 'Float', // real type target field
      typeInput: 'selectBox',
      listOptions: [
        { id: '', name: 'form.selectInputLabel' },
        { id: '1', name: 'validator.required' },
      ],
      defaultValue: '', // always in string
    },
  },
  formNewSection: true,
  typeInput: 'text',
  label: 'form.services',
  mode: {},
};
const aTourRooms = {
  type: 'String',
  dbtype: 'Json',
  labelChildsShow: true,
  placeHolderShowChildsShow: false,
  subfields: {
    id: {
      type: 'ID',
      typeInput: 'hidden',
      label: 'form.id',
    },
    tourroom_id: {
      type: 'String',
      label: 'table.tourroom',
      typeInput: 'selectAutocomplete',
      validators: ['required'],
      onChange: true,
    },
    priceroom: {
      type: 'Float',
      label: 'form.price',
      typeValue: 'Price',
      normalize: 'PriceNegative',
    },
  },
  formNewSection: true,
  typeInput: 'text',
  label: 'table.tourrooms',
  mode: {},
};

class Form extends Component {
  constructor(props) {
    super(props);
    let session = getSession();
    // TWIN TAI231
    let permission = getPermission(
      session,
      tableCrud,
      props.formState.actionsave
    );
    this.state = {
      itineraryVisible: false,
      dialogOpened: {},
      permission,
    };
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  toolbarFunctions = (toolbar, params = {}) => {
    if (toolbar === 'gcu') {
      /*const tour_id =  getInputValue( this.props,  'tour_id', {nameForm: 'listFilterregistration'});
      if (!tour_id) {
        alert ( this.props.t('validator.required') + ': '+ this.props.t('table.tour'));
        return false;
      }*/

      this.setState({
        dialogOpened: { ...this.state.dialogOpened, RichText: true },
        defaultValues: {
          /*customer_id: null,
            tour_id: tour_id,*/
        },
      });
    }
    if (toolbar === 'itinerary') {
      const tour_id = getInputValue(this.props, 'tour_id', {
        nameForm: 'listFilterregistration',
      });
      if (!tour_id) {
        alert(
          this.props.t('validator.required') + ': ' + this.props.t('table.tour')
        );
        return false;
      }
      this.setState({
        dialogOpened: { ...this.state.dialogOpened, Itinerary: true },
        defaultValues: {
          customer_id: null,
          tour_id: tour_id,
        },
      });
    }

    if (toolbar === 'closeDialog') {
      let dialogOpenedNew = this.state.dialogOpened;
      if (dialogOpenedNew[params.dialogName]) {
        delete dialogOpenedNew[params.dialogName];
        this.setState({ dialogOpened: dialogOpenedNew });
      }
    }
  };

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      containerPropsForm,
      t,
      ...otherProps
    } = this.props;

    const listCustomerStatuss = [];
    const customerStatuss =
      containerPropsForm.getTourCustomerStatusAndServices
        .getTourCustomerStatusAndServices;
    const tourServices = containerPropsForm.getTourServices.getTourServices;
    const tourRooms = containerPropsForm.list_tourroom.tourrooms;
    listCustomerStatuss.push(
      <div className="row header" key="header">
        <div className="cell"></div>
        <div className="cell">{t('table.customerstatus')}</div>
        <div className="cell">{t('form.price')}</div>
      </div>
    );

    for (const customerStatus of customerStatuss) {
      const linkItem = `/tour-settings/${this.props.id}/customer-status/${customerStatus.id}`;
      listCustomerStatuss.push(
        <div className="row" key={customerStatus.id}>
          <div>
            <Link
              className="aToolBarExtend"
              title={t('form.watch') + '  ' + t('form.record')}
              key="eye"
              to={linkItem}
            >
              <ShowIcon size="20" color="gray" icon="eye" />
            </Link>
          </div>
          <div className="cell">{customerStatus.nameOnly}</div>
          <div className="cell">{customerStatus.tourprice_price}</div>
        </div>
      );
    }
    aServices.subfields.tourservices_id.listOptions = tourServices;
    aTourRooms.subfields.tourroom_id.listOptions = tourRooms;

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ fontSize: '19px', color: '#bbbbbb' }}>
          {containerPropsForm.crud_view_tour.tour.longname}
        </div>
        <div className="appBodyTitleSeparator" />

        {listCustomerStatuss}
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />
        <div key="toolbar" style={{ flexDirection: 'column' }}>
          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">
              {invalid && submitFailed && t('form.haserrors')}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const statesReturn = { myState: state };
  let initialValues = {};

  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

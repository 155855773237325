
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { MenuItem } from './menu';
import { ISOtoDate, resolvePathObj } from '../utils/commonutils';
import LogoPeleAdmin from '../components/icons/logopele-bgdark';
import Notif from '../components/Notif';
import { getSession } from '../utils/auth';
import ShowIcon from '../components/icons/ShowIcon';


class LayoutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPC: true,
      menuMini: false,
      type: '',
    };
  }

  toMenuMini = ( mini) => {
    this.setState ( (prevState) => {
      return { menuMini: mini};
    });
  };


  handleResize= ( ) => {
  

    this.setState ( (prevState) => {
      let newMini;
      newMini = prevState.mini;
      if (window.matchMedia('screen and (max-width: 840px)').matches ) {
        console.log('is phone');
       
      } else {
        newMini = prevState.mini;
        console.log('is pc');
      }
      return { menuMini: prevState.mini};
    });
  };


  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
  
  
    const checkMediaQuery = () => {
      const type = window.matchMedia("(max-width: 840px)").matches ? 'mobile' : 'desktop';
      if (type !== this.state.type) {
        this.setState({type});
      }
    };

    window.addEventListener('resize', checkMediaQuery);
    checkMediaQuery();

  }

  render() {
    let { component: DisplayComponent, t, changeLanguage, i18n, ...props } = this.props;
    const localPreHeaderStyle = {
      background: props.theme.PrimaryDark,
      height: '29px',
      color: 'white',
      fontSize: '11px',
      textAlign: 'right',
      paddingRight: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      letterSpacing: '2px',
    };
    const localHeaderStyle = {
      background: props.theme.Primary,
      color: props.theme.SecondaryText,
      textAlign: 'center',
      width: '100%',
      fontSize: '20px',
    };
    const localFooterStyle = {
      background: props.theme.Background,
      color: props.theme.TextOnBackgroundLight,
      padding: '10px',
      textAlign: 'right',
      height: '30px',
    };

    const localTitleStyle= {
      fontSize: '18px',
      color: '#FFFFFF',
    };
    let appHeaderNotif;
    let localMenuStyle;
    let localContentStyle;
    let localAppContentStyle ;
    appHeaderNotif = {
      margin: 'auto',
      position: 'absolute',
      padding: '10px',
      bottom: '100px;',
      left: '190px;',
      minHeight: '60px',
      zIndex: '2000',
    };

    localAppContentStyle = {
      display: 'block',
      flexGrow: '1',
      flexShrink: '1',
      overFlow: 'auto',
      paddingTop: '20px',
      paddingLeft: '15px',
      width: '100%',
    };

    localMenuStyle = {
      minHeight: "300px",
      minWidth: "195px",
      background: props.theme.Background,
    };
    let menuMini = false;
    let screenMini = window.matchMedia('screen and (max-width: 840px)').matches ;
    if (screenMini) {
      localAppContentStyle.width = '90%';
    }
    if (this.state.menuMini || screenMini )  {

      menuMini = true;
      appHeaderNotif.bottom= '200px';
      appHeaderNotif.left= '33px';

      localMenuStyle = Object.assign ( localMenuStyle, {
        minWidth: "37px",
        width: "37px",
        overflow: "hidden",
        position: "absolute",
        left: "0",
        top: "27px",
      });


      localAppContentStyle.paddingLeft= '45px'; 
      localAppContentStyle.flexGrow=  '0';


    } else {
    }
    const pagePrivate = !props.public;




    if (pagePrivate) {
      localAppContentStyle.textAlign= 'left';
      localAppContentStyle.paddingTop= '20px';
    } else {
      localAppContentStyle.textAlign= 'center';
      localAppContentStyle.paddingTop= '50px';
    }
                                                                                                                                                                                                                                                                                                                                                                                                                              let session =  getSession();
    let nickuser =  session.nick;
   
    


    let styleLgSelected = { border: 'none', backgroundColor: props.theme.PrimaryDark, color: 'white'};
    let styleLg = { border: 'none', backgroundColor: props.theme.PrimaryDark, color: '#BBBBBB'};

    return (

      <div ref={elem => this.nv = elem}  className="appContainerColumnDirection">
        <div key ="topbar"  className="preHeaderStyle" style={localPreHeaderStyle}>
          <div style= { { alignItems: 'center', display: 'flex' } }>
            {nickuser && <div>
              <a className="aNotHover" href="/signout" style= { { verticalAlign: 'middle',display: 'inline', marginLeft: '9px' } }>
                <ShowIcon icon="powersettings" color="#FFFFFF"/>
              </a>
            </div>}

            <div>
              { nickuser }
            </div>
            {nickuser && <a className="aNotHover" href="/myaccount" style= { { verticalAlign: 'middle',display: 'inline', marginLeft: '7px' } }>
              <ShowIcon icon="person" color="#FFFFFF"/>
            </a>}

            <button style= { i18n.language ==='fr' ? styleLgSelected: styleLg } onClick={() => changeLanguage('fr')}>Franç.</button>
            <button style= { i18n.language ==='es' ? styleLgSelected: styleLg } onClick={() => changeLanguage('es')}>Esp</button>
            <button style= { i18n.language ==='it' ? styleLgSelected: styleLg } onClick={() => changeLanguage('it')}>Ita</button>
            <button style= { i18n.language ==='de' ? styleLgSelected: styleLg } onClick={() => changeLanguage('de')}>Deu</button>
            <button style= { i18n.language ==='en' ? styleLgSelected: styleLg } onClick={() => changeLanguage('en')}>Eng</button>

          </div>
          <div style= { {  marginTop: '5px' } }>
            {props.nameApp}
            <strong> {window?.appConfig?.REACT_APP_VERSION}</strong>
          </div>
        </div>
        {(!menuMini && !props.invisibleHeader) && <div key ="barinfo" style={localHeaderStyle}>
          <div style={ { height: '35px', padding: '5px' } }>

            <div style={ { right: 0, top: '28px', position: 'absolute' } }>
              <LogoPeleAdmin fill="#EDEDED" />
            </div>
            <div className="appContentTitle" style={localTitleStyle}>
              {getSession('company')}
            </div>
          </div>
        </div>}
        <div key="container" className="appContainerRowDirection">
          { pagePrivate &&
          <div key="menu" style={localMenuStyle}>
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              {!screenMini && menuMini && <MenuItem
                theme={props.theme}
                label={t('menu.maximize')}
                menuMini={menuMini}
                icon="navright"
                action={this.toMenuMini}
                actionValue={false}
                currentPath="{props.location.pathname}"
              />}
              {!screenMini && !menuMini &&  <MenuItem
                theme={props.theme}
                label={t('menu.minimize')}
                menuMini={menuMini}
                icon="navleft"
                action={this.toMenuMini}
                actionValue={true}
                currentPath="{props.location.pathname}"
              />}
                


              <MenuItem
                theme={props.theme}
                label={t('menu.dashboard')}
                menuMini={menuMini}
                icon="pollblack"
                goUrl={props.goUrl}
                path="/dashboard"
                currentPath={props.location.pathname}
              />


                  {(resolvePathObj(this.props, 'myState.app.appSettings.modules.ACCO', true)) &&
              <MenuItem
                theme={props.theme}
                label={t('menu.accounting')}
                menuMini={menuMini}
                icon="accounting"
                goUrl={props.goUrl}
                path="/accounting"
                currentPath={props.location.pathname}
              />}


              <MenuItem
                theme={props.theme}
                label={t('menu.messaging')}
                menuMini={menuMini}
                icon="mailing"
                goUrl={props.goUrl}
                path="/mailing"
                currentPath={props.location.pathname}
              />
                
              { (session.ai || !session.gu || (session.pe.customer && session.pe.customer.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.customers')}
                menuMini={menuMini}
                icon="pele"
                goUrl={props.goUrl}
                path="/customer"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.tour && session.pe.tour.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.tours')}
                menuMini={menuMini}
                icon="flight"
                goUrl={props.goUrl}
                path="/tour"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.tourcategory && session.pe.tourcategory.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.tourcategorys')}
                menuMini={menuMini}
                icon="tourcategory"
                goUrl={props.goUrl}
                path="/tourcategory"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.regonline && session.pe.regonline.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.regonlines')}
                menuMini={menuMini}
                icon="tablet"
                goUrl={props.goUrl}
                path="/regonline"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.registration && session.pe.registration.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.registrations')}
                menuMini={menuMini}
                icon="registration"
                goUrl={props.goUrl}
                path="/registration"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.payment && session.pe.payment.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.payments')}
                menuMini={menuMini}
                icon="monetization"
                goUrl={props.goUrl}
                path="/payment"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.export && session.pe.export.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.exports')}
                menuMini={menuMini}
                icon="export"
                goUrl={props.goUrl}
                path="/export"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.hotel && session.pe.hotel.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.hotels')}
                menuMini={menuMini}
                icon="hotel"
                goUrl={props.goUrl}
                path="/hotel"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.stopspoint && session.pe.stopspoint.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.stopspoints')}
                menuMini={menuMini}
                icon="stopspoint"
                goUrl={props.goUrl}
                path="/stopspoint"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.service && session.pe.service.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.services')}
                menuMini={menuMini}
                icon="service"
                goUrl={props.goUrl}
                path="/service"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.city && session.pe.city.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.citys')}
                menuMini={menuMini}
                icon="city"
                goUrl={props.goUrl}
                path="/city"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.registrationstatus && session.pe.registrationstatus.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.registrationstatuss')}
                menuMini={menuMini}
                icon="permcontactcalendar"
                goUrl={props.goUrl}
                path="/registrationstatus"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.confirmationstatus && session.pe.confirmationstatus.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.confirmationstatuss')}
                menuMini={menuMini}
                icon="check"
                goUrl={props.goUrl}
                path="/confirmationstatus"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.bank && session.pe.bank.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.banks')}
                menuMini={menuMini}
                icon="bank"
                goUrl={props.goUrl}
                path="/bank"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.paymentmethod && session.pe.paymentmethod.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.paymentmethods')}
                menuMini={menuMini}
                icon="localatm"
                goUrl={props.goUrl}
                path="/paymentmethod"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.paymentdocstatus && session.pe.paymentdocstatus.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.paymentdocstatuss')}
                menuMini={menuMini}
                icon="paymentdocstatus"
                goUrl={props.goUrl}
                path="/paymentdocstatus"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.customerstatus && session.pe.customerstatus.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.customerstatuss')}
                menuMini={menuMini}
                icon="recordvoice"
                goUrl={props.goUrl}
                path="/customerstatus"
                currentPath={props.location.pathname}
              />}
              { (session.ai || !session.gu || (session.pe.userlog && session.pe.userlog.view)) && 
              <MenuItem
                theme={props.theme}
                label={t('table.userlogs')}
                menuMini={menuMini}
                icon="log"
                goUrl={props.goUrl}
                path="/userlog"
                currentPath={props.location.pathname}
              />}
                


              <MenuItem
                theme={props.theme}
                label={t('form.more')}
                menuMini={menuMini}
                icon="iclistblack"
                goUrl={props.goUrl}
                path="/more"
                currentPath={props.location.pathname}
              />


              <MenuItem
                theme={props.theme}
                label={t('login.signout')}
                menuMini={menuMini}
                icon="powersettings"
                goUrl={props.goUrl}
                path="/signout"
                currentPath={props.location.pathname}
              />
            </div>
          </div>
          }
          <div key ="appcnt" id="test" style={localAppContentStyle}>
            <DisplayComponent t={t} {...props} />
          </div>
        </div>
        {props.notifications.length > 0 &&
        <div key="notif" className="appHeaderNotif" >
          {props.notifications.map((notification) => {
            return <Notif hideNotification={props.hideNotification} key={notification.id} notification={notification} />;

          })}
        </div>
        }
      </div>
    );
  }
};

export default LayoutComponent;
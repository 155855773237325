import { getInputValue, dateStandard } from '../../utils/commonutils';
import { date as dateInvalid } from '../../utils/validators';
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    formState,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
  } = params;

  const result = {};

  let newStates = {
    disabledFields: {},
    hiddenFields: {},
    warningFields: {},
    errors: {},
  };
  let filterbycustomerstatus;
  if (inputFullName === 'filterbycustomerstatus') {
    filterbycustomerstatus = newValue;
  } else {
    // need always validad , since load // // if ( action!== 'initForm') {
    filterbycustomerstatus = getInputValue(
      formProps,
      'filterbycustomerstatus',
      {
        nameForm,
      }
    );
  }

  if (action === 'initForm' || inputFullName === 'filterbycustomerstatus') {
    newStates.disabledFields['filterbycustomerstatus'] = filterbycustomerstatus
      ? false
      : true;
    /*let inactive;
    if (inputFullName === 'inactive') {
      inactive = newValue;
    } else  {
      inactive = getInputValue ( formProps, 'inactive') || false;
    }
    newStates.disabledFields['name'] = inactive;
    newStates.disabledFields['prix'] = inactive;
    newStates.disabledFields['seatsmax'] = inactive;
    newStates.disabledFields['seatsmin'] = inactive;
    newStates.hiddenFields['inactivemessage'] = !inactive;

    result.newStates = newStates;*/
  }
  result.newStates = newStates;
  return result;
};

export default crudCode;

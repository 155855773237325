const moment = require('moment-timezone');
require('moment/locale/fr.js');
const i18countries = require('i18n-iso-countries');
const accounting = require('accounting');
const mustache = require('mustache');
const format = require('date-fns/format');
const he = require('he'); // decode &html codes to normal string
const Tables = require('./../defTables').Tables;

moment.locale('fr'); /// %% actually hardcode , just france, in future customise accord language
//moment().format('D MMM YY');
moment().format('DD-MM-YYYY'); // important to edit in input this format, don't use letters in date
let setDateFormat = localStorage.getItem('setDateFormat');
export const sourcesOptions = {};
sourcesOptions.countries = [];
//forOwn(country3code, (code2, id) => {
i18countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
// //console.log(countries.getNames("fr"));

const country3code = i18countries.getAlpha3Codes();
Object.keys(country3code).forEach((country) => {
  const name = i18countries.getName(country3code[country], 'fr');
  sourcesOptions.countries.push({ id: country, name });
});

sourcesOptions.typeEntry = [
  { id: '', name: 'Yes/No' },
  { id: 'OL', name: 'Option List' },
];

sourcesOptions.countriesSettings = {
  saveIdOnly: true,
  disabledTranslate: true,
};

sourcesOptions.messagesChannel = [
  { id: 1, name: 'email' },
  { id: 3, name: 'SMS' },
  { id: 9, name: 'Doc' },
];

sourcesOptions.messagesChannelSettings = {
  saveIdOnly: true,
  disabledTranslate: true,
};

sourcesOptions.registrationBaseStatus = [
  { id: 1, name: 'not', color: '#fdb749', style: { color: '#fdb749' } },
  { id: 2, name: 'inprocess', color: '#a1f6ab', style: { color: '#a1f6ab' } },
  { id: 4, name: 'yes', color: '#7acaf3', style: { color: '#7acaf3' } },
  //  { id: 6, name: 'yes-toll-free', color: '#7acaf3', style: { color: '#7acaf3' }},
  { id: 14, name: 'cancelled', color: '#333333', style: { color: '#333333' } },
];

sourcesOptions.confirmationBaseStatus = [
  { id: 1, name: 'not', color: '#fdb749', style: { color: '#fdb749' } },
  { id: 2, name: 'inprocess', color: '#a1f6ab', style: { color: '#a1f6ab' } },
  { id: 4, name: 'yes', color: '#7acaf3', style: { color: '#7acaf3' } },
];

sourcesOptions.paymentMethodBaseStatus = [
  { id: 1, name: 'cash' },
  { id: 2, name: 'check' },
  { id: 3, name: 'transfert' },
  { id: 9, name: 'other' },
];

sourcesOptions.entrytype = [
  { id: '', name: 'selection' },
  { id: 1, name: 'text' },
  //{ id:  2, name: 'checkbox' },
  { id: 5, name: 'checkboxs' },
  { id: 8, name: 'radiobuttons' },
  { id: 10, name: 'selectbox' },
];

sourcesOptions.servicefiltertype = [
  { id: 2, name: 'include' },
  { id: 5, name: 'exclude' },
];

sourcesOptions.paymentDocBaseStatus = [
  //  { id: 1, name: 'noprocess', color: '#fdb749', style: { color: '#fdb749' }},
  { id: 4, name: 'inprocess', color: '#a1f6ab', style: { color: '#a1f6ab' } },
  {
    id: 7,
    name: 'inbankaccount',
    color: '#7acaf3',
    style: { color: '#7acaf3' },
  }, //before 2
  { id: 11, name: 'cancelled', color: '#333333', style: { color: '#333333' } },
];

sourcesOptions.actionLOG = [
  { id: 'u', name: 'updated' },
  { id: 'd', name: 'deleted' },
  { id: 'a', name: 'added' },
];

sourcesOptions.bedBase = [
  { id: 1, name: 'simplebed', people: 1 },
  { id: 2, name: 'doublebed', people: 2 },
];

sourcesOptions.filiation = [
  { id: 1, name: 'Couple' },
  { id: 2, name: 'Father' },
  { id: 3, name: 'Mother' },
  { id: 4, name: 'Friend' },
  { id: 5, name: 'Son' },
  { id: 6, name: 'Daughter' },
  { id: 7, name: 'Brother' },
  { id: 8, name: 'Sister' },
  { id: 9, name: 'Relative' },
  { id: 10, name: 'Grandpa' },
  { id: 11, name: 'Grandma' },
  { id: 20, name: 'Other' },
];

sourcesOptions.roomBase = [
  { id: 1, name: 'Single', people: 1 },
  { id: 2, name: 'Double', people: 2 },
  { id: 3, name: 'Couple', people: 2, bedBase: { 2: 1 } },
  { id: 4, name: 'Triple', people: 3 },
  { id: 5, name: '1 Couple , 1 Single', people: 3, bedBase: { 2: 1 } },
  { id: 6, name: '1 Couple , 2 Single', people: 4, bedBase: { 2: 1 } },
  { id: 7, name: '4 Single', people: 4 },
  { id: 105, name: '5 Single', people: 5 },
  { id: 106, name: '6 Single', people: 6 },
  { id: 107, name: '7 Single', people: 7 },
  { id: 108, name: '8 Single', people: 8 },
  { id: 109, name: '9 Single', people: 9 },
  { id: 110, name: '10 Single', people: 10 },
  { id: 111, name: '11 Single', people: 11 },
  { id: 112, name: '12 Single', people: 12 },
  { id: 113, name: '13 Single', people: 13 },
  { id: 114, name: '14 Single', people: 14 },
  { id: 115, name: '15 Single', people: 15 },
  { id: 116, name: '16 Single', people: 16 },
  { id: 117, name: '17 Single', people: 17 },
  { id: 118, name: '18 Single', people: 18 },
  { id: 119, name: '19 Single', people: 19 },
  { id: 120, name: '20 Single', people: 20 },
];

sourcesOptions.processTransaction = [
  { id: 3, name: 'Open' },
  { id: 5, name: 'Closed' },
  //{ id:10, name: 'Cancelled' }, // very hard to manage cancel deposits, so disable
];

sourcesOptions.processRegonline = [
  { id: 1, name: 'NotProcessed' },
  { id: 2, name: 'PaymentAccepted' },
  { id: 3, name: 'Processed' },
  { id: 7, name: 'Rejected' },
  { id: 9, name: 'PaymentError' },
  //{ id:10, name: 'Cancelled' }, // very hard to manage cancel deposits, so disable
];

sourcesOptions.seatingType = [
  { id: 1, name: 'Window' },
  { id: 5, name: 'Aisle' },
  { id: 10, name: 'SingleSolo' },
  //{ id:10, name: 'Cancelled' }, // very hard to manage cancel deposits, so disable
];

sourcesOptions.addressesType = [
  { id: 1, name: 'Principal' },
  { id: 3, name: 'Office' },
  { id: 5, name: 'SecondHome' },
  { id: 7, name: 'HolidayHome' },
  { id: 25, name: 'Other' },
];

sourcesOptions.identitydocsType = [
  { id: 1, name: 'NationalIdentity' },
  { id: 3, name: 'ResidencePermit' },
  { id: 5, name: 'Passport' },
  { id: 10, name: 'SecuritySocial' },
  { id: 15, name: 'HealthInsurance' },
  { id: 20, name: 'DrivingLicense' },
  { id: 25, name: 'Other' },
];

sourcesOptions.diet = [
  { id: '10', name: 'nogluten' },
  { id: '15', name: 'nofish' },
  { id: '20', name: 'nomeat' },
  { id: '25', name: 'nomilk' },
  { id: '30', name: 'nosucre' },
  { id: '35', name: 'noalcohol' },
];

sourcesOptions.handicap = [
  { id: '05', name: 'walk30' },
  { id: '10', name: 'mobility50' },
  { id: '15', name: 'mobility0' },
  { id: '20', name: 'wheelchair' },
  { id: '22', name: 'bed' },
  { id: '25', name: 'vision50' },
  { id: '30', name: 'vision0' },
  { id: '35', name: 'hearing0' },
  { id: '40', name: 'learn0' },
  { id: '45', name: 'psy' },
];

sourcesOptions.aLanguages = [
  { id: 'fr', name: 'Français', base: true },
  { id: 'es', name: 'Español', api: true },
  { id: 'de', name: 'Deutsch', api: true },
  { id: 'it', name: 'Italiano', api: true },
  { id: 'en', name: 'English', api: true },
  { id: 'pt', name: 'Portugês', api: true },
  //{ id: 'pl', name: 'Polsky', api: true},
];
sourcesOptions.aLanguagesSettings = {
  disabledTranslate: true,
};

sourcesOptions.phonesType = [
  { id: 1, name: 'Mobile' },
  { id: 5, name: 'HomeLandline' },
  { id: 6, name: 'HolidayHomeLandLine' },
  { id: 7, name: 'OfficeLandline' },
  { id: 13, name: 'Fax' },
  { id: 50, name: 'Other' },
];

sourcesOptions.servicetBaseStatus = [
  { id: 2, name: 'Transport' },
  { id: 5, name: 'Hotel' },
  { id: 8, name: 'Food' },
  { id: 25, name: 'Other' },
];

sourcesOptions.orgBase = [
  { id: 1, name: 'Inactif' },
  { id: 3, name: 'Utilise autre logiciel' },
  { id: 11, name: 'Demo Actif' },
  { id: 21, name: 'Client Basique' },
  { id: 31, name: 'Client PRO' },
];

sourcesOptions.orgBaseSettings = {
  disabledTranslate: true,
};

sourcesOptions.orgModulesSettings = {
  disabledTranslate: true,
};

sourcesOptions.orgModules = [
  { id: 'ACCO', name: 'Comptabilité' },
  { id: 'EMAIL', name: 'Email' },
  { id: 'SMS', name: 'SMS' },
  { id: 'DOSSIER', name: 'N° de Dossier' },
  { id: 'DOSSIERCUSTOMER', name: 'N° de Dossier Client' },
  { id: 'PAY_SG', name: 'PayOnline - Société Générale' },
  {
    id: 'PAY_SYSP',
    name: "PayOnline - SP Plus - Caisse d'Epargne / Systempay",
  },
  { id: 'PAY_HELLO', name: 'PayOnline - HelloAsso' },
];

// Warning, never use zero like id, logic recognized zero like empty
sourcesOptions.processBaseStatus = [
  { id: 1, name: 'Scheduled', icon: 'loading' },
  { id: 2, name: 'Running', icon: 'loading' },
  { id: 8, name: 'Generated' },
  { id: 5, name: 'Ended' },
  { id: 7, name: 'Cancelled' },
  { id: 10, name: 'Error' },
];

sourcesOptions.contactType = [
  { id: 2, name: 'Emergency' },
  { id: 4, name: 'Doctor' },
  { id: 5, name: 'FullGuardianship' },
  { id: 10, name: 'PartialGuardianship' },
  { id: 15, name: 'Other' },
];

sourcesOptions.yesNoEmpty = [
  { id: 2, name: 'Yes' },
  { id: 4, name: 'No' },
];

sourcesOptions.onlineRegType = [
  { id: '', name: 'Disabled' },
  { id: 2, name: 'ActiveRangeDates' },
];

sourcesOptions.serviceVisibility = [
  { id: '', name: 'joinGlobalService' },
  { id: 1, name: 'separatedTab' },
  { id: 3, name: 'onlyJoinName' },
  { id: 7, name: 'full' },
  { id: 11, name: 'invisible' },
];

sourcesOptions.table = [];
Object.keys(Tables).forEach((key) => {
  sourcesOptions.table.push({
    id: key,
    name: key,
    mode: {
      disabledPermissionOption: Tables[key].disabledPermissionOption
        ? Tables[key].disabledPermissionOption
        : false,
    },
  });
});

export const isNil = (value) => {
  return value == null;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

let datetimeToISO = function (value) {
  if (value === null || typeof value === 'undefined') {
    return '';
  } else {
    return value.toLocaleDateString() + ' ' + value.toLocaleTimeString();
  }
};

export const getToday = () => {
  let d = new Date();
  if (setDateFormat) {
    // twin DAT910
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join('-');
  } else {
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),
    ].join('-');
  }
};

export const getDefaultValue = function (defValue, field = {}) {
  // defValue must be 'f:..type...). Eg: f:today (date of today)
  let value = '';
  const typeValue = defValue.substr(2);
  if (typeValue === 'today') value = getToday();
  return value;
};

export const getDateFileName = function (value, time = false) {
  let d = new Date().toISOString();
  if (time) {
    d = d.substr(0, 19).replace(':', '_');
  } else {
    d = d.substr(0, 10);
  }
  return d;
};

/*
important decimals send in string, easier to compare if parameter was sent
 */

export const toPrice = function (
  price,
  options = { decimals: '2', separatorT: '', currency: '' }
) {
  if (!options.decimals) options.decimals = '2';
  if (!options.separatorT) options.separatorT = ''; // separator thousands
  if (!options.currency) options.currency = '';
  if (options.currency === '€') options.separatorT = ' '; // for euro display is: 1 234.00

  let priceFormated = accounting.formatNumber(
    price,
    parseInt(options.decimals),
    options.separatorT
  );
  if (options.currency) priceFormated += ' ' + options.currency; // hardcode %%% at moment only euros
  return priceFormated;
};

// remove separators
export const dateClean = function (dateString) {
  if (!dateString) {
    return null;
  }
  let p = replaceAll(dateString, /\D/g, ''); // \D = Do a global search for non-digit characters:
  return p;
};

export const yyymmddWithSeparatorToDate = function (dateString) {
  if (!dateString) {
    return null;
  }
  const p = dateString.split(/\D/g); // \D = Do a global search for non-digit characters:
  // const date = new Date(p[0], p[1], p[2]); // twin DAT910 , wht %%% had 'p[1]', but twhin it's 'p[1] -1'?
  let date;
  if (setDateFormat) {
    date = new Date(p[2], p[1] - 1, p[0]); // twin DAT910
  } else {
    date = new Date(p[0], p[1] - 1, p[2]); // twin DAT910
  }
  return date;
};

// validation date

/* remove separator and convert from DD MM YYYY to YYYY MM DD dates: / -, etc*/
export const dateToISO = function (dateString, charJoin = '-') {
  if (!dateString) {
    return null;
  }
  let p = dateString.split(/\D/g); // \D = Do a global search for non-digit characters:
  return [p[2], p[1], p[0]].join(charJoin);
};

export const toTimeZone = function (time = new Date(), zone = 'Europe/Paris') {
  let format = 'YYYY/MM/DD HH:mm:ss ZZ';

  return moment.tz(time, zone).format(format);
};

export const ISOtoDate = function (dateString, dformat = '') {
  // dateString can be string, or mode date, ex: "Thu Aug 06 2020 00:00:00 GMT+0200 (Central European Summer Time)
  //value.toLocaleDateString() +' '+ value.toLocaleTimeString();
  if (!dateString) {
    // not accept null
    return '';
  }
  if (dformat === '-short-nice-') dformat = 'll';
  if (
    typeof dateString.getMonth === 'function' &&
    dateString.getSeconds() > 0 &&
    dateString.getMinutes() > 0 &&
    dateString.getHours() > 0
  ) {
    // never is for input text, can use names for month,etc
    //format = !format || format === '-nice-' ? 'D MMM YY HH:MM' : format;
    dformat = !dformat || dformat === '-nice-' ? 'D MMMYY (h:mm a)' : dformat;
  } else {
    if (dformat === '-nice-') dformat = 'D MMM YY';
    if (!dformat) dformat = 'DD-MM-YYYY'; // important this format for input text %% fuuture see i18n
  }
  return format(dateString, dformat);
  //return moment(dateString,"YYYY-MM-DD HH:mm Z").format(format);
};

export const contains = function (target, pattern) {
  let value = 0;
  pattern.forEach(function (word) {
    value = value + target.includes(word);
  });
  return value === 1;
};

export const replaceAll = function (target, search, replacement) {
  return target.replace(new RegExp(search, 'g'), replacement);
};

export const getRoomInfo = (room_type_id) => {
  //console.log('init bookings',bookings);
  let roombase_type = getObjFromListById(sourcesOptions.roomBase, room_type_id);
  if (!roombase_type.bedBase) {
    roombase_type.bedBase = { 1: 1, 2: 0 };
  }

  //console.log('roomsqty',roomsqty );

  let bedQtyCouple = roombase_type.bedBase['2']; // no real beds, but total capacity per bed type
  let bedQtySingle = roombase_type.people - bedQtyCouple * 2; // no real beds, but capacity  total per bed type
  roombase_type.bedBase['1'] = bedQtySingle; //deduction qty bed single
  let bedQty = bedQtyCouple + bedQtySingle;
  let bedsPerRoom =
    (roombase_type.bedBase['1'] || 0) + (roombase_type.bedBase['2'] || 0);

  return {
    roombase_type,
    bedQty,
    bedsPerRoom,
    bedQtyCouple,
    bedQtySingle,
    peoplePerRoom: roombase_type.people,
  };
};

export const resolvePathObj = (
  o, // object
  s, // string
  props = { compare: undefined, notFound: undefined },
  debug = false
) => {
  /*
    Eg. resolved = resolvePathObj(ownProps, 'containerPropsForm.getOrgSettings.getOrgSettings.record');
    return undefined if is not found, check with:   if ( typeof resolved !== 'undefined') {

    params:
      compare, if a value is sent, then return false or true accord comparison


   */
  //console.log('s',s);
  if (typeof o === 'undefined') return props.notFound;
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  let a = s.split('.');
  let notFound = false;
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (debug) console.log('a[i])', i, a[i]);
    if (k in o) {
      o = o[k];
    } else {
      notFound = true;
      if (debug) console.log('not found');
    }

    // block for not found
    if (notFound || (o === null && i + 1 < n)) {
      // if in the middile find a null, stop, because next loop 'k in o' will throw an error, can't use search in null
      if (typeof props.compare === 'undefined') {
        return props.notFound; // return undefined
      } else {
        // compare
        const defValue =
          typeof props.notFound === 'undefined' ? false : props.notFound; // object not found, nothing to compare then is false
        if (debug)
          console.log(
            'props.notFound / compare',
            props.notFound,
            props.compare,
            defValue === props.compare
          );
        return defValue === props.compare;
      }
    }
  }
  //console.log('o',o);
  if (typeof props.compare === 'undefined') {
    if (debug) console.log('compare', o, props.compare);
    return o; // return value found
  } else {
    // compare
    if (debug) console.log('compare', o, props.compare, o === props.compare);
    return o === props.compare;
  }
};

/*
  search record in records by value;
  value can by string(id) or object ( more one field searched  { field1: value1, field2: value2... })
*/
export const getObjFromListById = (
  listItems,
  value,
  notFoundReturnUndefined = false,
  log = false
) => {
  // somes logics need return empty object when is not found, anothers just false
  let objNotFound = notFoundReturnUndefined ? undefined : {};
  let objFound = notFoundReturnUndefined ? undefined : {};

  if (log)
    console.log('==getObjFromListById: , value, listItems, ', value, listItems);
  if (typeof value === 'undefined' || value === null) return objFound;
  if (typeof value === 'string' || typeof value === 'number') {
    //important if not send object, maybe add more options o change to negative 'object'

    if (realTypeOf(listItems) !== '[object Array]') {
      // twin OAR143
      return objFound;
    }

    objFound = listItems.find((item) => {
      // id can be string from db need to convert a real int, to compare with int
      // but important only if one of two values are 'number' if not
      if (log)
        console.log(
          'getObjFromListById () item.id:  ' +
            item.id +
            ' ===  value: ' +
            value,
          typeof item.id
        );
      if (
        item.id === value ||
        ((typeof item.id === 'number' || typeof value === 'number') &&
          parseInt(item.id) === parseInt(value))
      ) {
        // warning parseInt can be dangerous, undefined give null ? and value if is null?
        return true;
      }
    });
  } else {
    // is object, compara subkeys
    if (log) console.log('getObjFromListById () searching.. values:', value);
    for (let i = 0; i < listItems.length; i++) {
      let found = true;
      for (let key in value) {
        let valueToCompare = value[key];

        /*
          special logic, if value to compare or value compared has .id
          like subkey then use that, example, in usergroup, need to compare
            value : { option: 'registration'}  against
            value : { .... , option: { id: 'registration} }
         */

        // warning valueToCompare can be null, eg, on change asign transport on registration
        if (valueToCompare && valueToCompare.id)
          valueToCompare = valueToCompare.id;

        let valueCompared = listItems[i][key];
        if (valueCompared && valueCompared.id) valueCompared = valueCompared.id;
        //

        if (log)
          console.log(
            'compare this values , with key: ' +
              key +
              ': valueToCompare:' +
              valueToCompare +
              ', valueCompared:',
            valueCompared
          );
        if (valueToCompare !== valueCompared) {
          if (log) console.log(' not match ');
          found = false;
          break;
        } else {
          if (log) console.log('matched');
        }
      }
      if (found) {
        if (log) console.log('row value matched ', listItems[i]);
        return listItems[i];
      }
    }
  }
  // important clone, to dont alterate value on another process
  return objFound ? Object.assign({}, objFound) : objNotFound; // not found , return empty obj really? or what typeof %%
};

export const getOptionFromSource = (listSource, value) => {
  let objName = sourcesOptions[listSource].find((item) => {
    // id can be string or int, from db need to convert a real int
    if (item.id === value || parseInt(item.id) === parseInt(value)) {
      return true;
    }
  });
  return objName; // {id,name}
};

export const startCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getInputValue = (
  props,
  xinputName = '',
  options = {
    initial: false,
    notFound: '',
    nameForm: '',
    hasSubfields: false,
    subfield: '',
    line: undefined,
  },
  log = false
) => {
  // getInputValue is smart, if formname is in formProps, dont' need nameForm,
  // case popup call this code but have no nameForm; because is depende caller

  /*
  two ways to get value from Fieldarray, if get these two values , then it's an array
    subfield / line :
      using name of subfield and line. Eg:  
         getInputValue(formProps, 'filterlist', { nameForm, line, notFound: undefined });

    isSubfield :
      using string, come with .props or deduce if string is like  transportation[1].transportation_id
    initial:
      retrieve original value for field, beforechanges


  hasSubfields , case filter sent false, so dont' look in subfields, for the future, not used


    props' can be:
      props from  input object (FormField.js,)
          in this case have:
            .formProps.myState ( to get field values from store)
      formProps from form object (...Form.js , Crud)

    the important in props is:
      nameForm, can realize there can look for the value;
      it's not easy to get it from an input; the form name is assigned automatic.
      and from a form (formProps), it's not possible to get it auto. always must to
      be sent like parameters o props  by hand

   */
  let formProps;
  let nameForm;

  if (props.myState) {
    // props is propsForm
    formProps = props;
  } else {
    formProps = props.formProps; // come from FormField, has already formProps
    if (!formProps) {
      // console.error('formProps not defined for Field'); <-- for free forms , not necessary
    }
  }
  if (options.log) log = options.log;
  let store = 'values';
  if (options.initial) {
    store = 'initial'; //redux form initialValues before changes
  }
  // nameForm is create by generator, and must always be transfered,
  // from the form container to the field
  // is needed and defined when input is called from FieldArray that draw inputs
  // it's a subfield from container field, need identify the formname,
  // don't needed for direct inputs ( they have not defined nameForm) so nameForm is the default
  // anyway if nameForm is sent , take that
  //console.log( 'props.nameForm ',props.nameForm );

  if (!options.nameForm && props.nameForm) {
    nameForm = props.nameForm; // normally the nameForm is on props; if not probably it's an error
  } else if (!options.nameForm && props.form) {
    // deleted, && xinputName !== '*' , is redundant, becase nameForm is sent
    // from executeCode(); study this speacial case, why is like this
    // %% delete this case, when nameForm is sent, this go to current form. Eg. click icon 'customer' on list 'tour'
    nameForm = props.form;
  } else if (
    options.nameForm !== '' &&
    typeof options.nameForm !== 'undefined'
  ) {
    // %% change all code to send name form; is the right secure way, and delete previous conditions
    // when need value from another form
    nameForm = options.nameForm;
  }
  if (!nameForm) {
    console.error(
      'getInputValue() ERRROR, nameForm is not defined, no .tableCrud  for inputName:' +
        xinputName,
      props
    );
  }
  /* Example for field with subfields:
     inputName = application[0].tour_id
     It must to extract the row,
   */

  // NEW 24 Dec, add posibility to getValue from another fieldName
  let inputName;
  if (xinputName === '') {
    inputName = props.input.name;
  } else {
    inputName = xinputName;
  }
  if (inputName === 'bank_id') {
    //log = true;
  }
  if (log) {
    console.log(
      'getInputValue(), inputName:' +
        inputName +
        'nameForm: ' +
        nameForm +
        ', subfield:' +
        options.subfield +
        ', line: ' +
        options.line +
        ', props: ',
      props
    );
  }

  // xinputName with value, don't read this.props fields because is asking for value from another object input
  // then this.props don't belong to the input who ask value
  let isSubfield = false;
  let inputNameArray;
  // xinputName can be hardcode, then is subfield. E.g: transportation[1].transportation_id

  // TWIN FUL492, decompose input name with line and container form
  if ((xinputName === '' && props.isSubfield) || xinputName.includes('[')) {
    isSubfield = true;

    // this logic is failing sometimes when try retrive value from arrayfield, dont resolve very well the paht to get the value
    //  in this case it's need send this parameter:
    //  options = { subfield, line }
    //  NOW is fixed, support name not only with AZ chars, but _ - too
    inputNameArray = inputName.match(/(.*)\[([\d*]+)\]\.(.*)/);

    if (log)
      console.log(
        'getInputValue(), inputName: ' +
          inputName +
          ' / is Subfield, divided :',
        inputNameArray
      );
    inputName = inputNameArray[1]; // real name
  }
  if (log) {
    if (formProps.myState.form && formProps.myState.form[nameForm])
      console.log(
        'getInputValue(), inputName:' + inputName + ' / formValues:',
        formProps.myState.form[nameForm][store]
      );
  }

  //if (formProps &&  <-- added Nov 2019, for the manual forms like signUp3
  if (
    formProps &&
    formProps.myState &&
    typeof formProps.myState.form !== 'undefined' &&
    typeof formProps.myState.form[nameForm] !== 'undefined' &&
    typeof formProps.myState.form[nameForm][store] !== 'undefined'
  ) {
    if (xinputName === '*') {
      // just asked if form state exist, then return true, otherwise below return ''
      return true;
    }
    //log = true;
    let valueReal;
    if (store === 'initial' && formProps.initialValues) {
      // this propery has the last fresch values, because from myState have un step less state, old values
      // console.log('value real come from initialValues', inputName, formProps.initialValues, formProps.initialValues[inputName]);
      valueReal = formProps.initialValues[inputName];
    } else {
      valueReal = formProps.myState.form[nameForm][store][inputName];
    }

    if (log)
      console.log(
        'getInputValue(), inputName:' +
          inputName +
          ' / diret field, valueReal for ' +
          inputName,
        valueReal
      );
    //console.log('inputname:' + inputName + ', options.subfield', options.subfield, options.line, typeof options.line, valueReal);
    if (options.subfield || typeof options.line !== 'undefined') {
      //  field array, look subfield or line value using subfield name or/and line
      let valueSubfield = null;
      if (valueReal) {
        // && realTypeOf(valueReal) === '[object Array]') {

        valueReal.map((dataline, index) => {
          if (log)
            console.log(
              options.line,
              index,
              dataline,
              typeof options.line,
              typeof index
            );
          // importante from popup line can be string (solved for poped) , left anyway better convert to int
          if (
            typeof options.line !== 'undefined' &&
            parseInt(options.line) === index
          ) {
            if (log) console.log('enter', options.line);
            if (options.subfield) {
              if (log)
                console.log(
                  'getInputValue(), inputName:' + inputName + ' / subfield',
                  options.subfield
                );
              valueSubfield = dataline[options.subfield]; // row, subfield
              if (log) console.log('subfield', valueSubfield);
            } else {
              if (log)
                console.log(
                  'getInputValue(), inputName:' +
                    inputName +
                    ' / dataline[options.line]' +
                    options.line,
                  dataline[options.line]
                );
              valueSubfield = dataline; // entire row
              if (log) console.log('entire row', dataline);
            }
          }
        });
      }
      valueReal = valueSubfield;
    } else if ((props.isSubfield || isSubfield) && valueReal) {
      //  field array, look subfield value using string : fieldcontainer[line]subfifeld

      if (valueReal) {
        //%%% check
        //** Warning don't convert here to .id, its need to send the {} object, conversion is
        // calller function getRecordValues()
        /*if ( valueReal[ inputNameArray[2] ][inputNameArray[3]] &&
          valueReal[ inputNameArray[2] ][inputNameArray[3]].id ) {
          // value can be object Eg: E.g: transportation[1].transportation_id {id: ..,name:..}
          valueReal = valueReal[ inputNameArray[2] ][inputNameArray[3]].id;
        } else {*/
        // console.log('valueReal inputNamen inputNameArray ',valueReal, inputName, inputNameArray )
        if (valueReal[inputNameArray[2]]) {
          // for security, someties valueReal is true, but have no elements
          // may rare case loading? make sure existe before get value

          valueReal = valueReal[inputNameArray[2]][inputNameArray[3]];
        }
        /*}*/
        if (log) console.log('isSubfield, valueReal :', valueReal);
      }
    }
    /*
     Important need to set from redux value :
        ruta: state, form, formname, values, field name
     for the input name to Select autocomplete component
     using  the method getValue()
     para saber que campo tomar de redux, usa la propiedad del componente select "this.props.input.name"
      */
    if (log)
      console.log(
        'getInputValue(), inputName:' + inputName + ' / resolution value:',
        valueReal
      );
    return valueReal;
  } else {
    if (log)
      console.log(
        'getInputValue(), inputName:' + inputName + ' / empty value, not found'
      );
    return options.notFound;
  }
};
export const getTheme = () => {
  let theme = window?.appConfig?.REACT_APP_THEME; // eslint-disable-line
  // const themeStorage = localStorage.getItem("theme");
  // if (themeStorage) theme = themeStorage ;
  return theme;
};

// dont' support numbers
export const getCamelCaseArray = (camel) => {
  return camel.replace(/([a-z](?=[A-Z]))/g, '$1 ');
};

export const smartTitleCase = (words, changeFullLongPhrase = false) => {
  const lowers = [
    'a',
    'an',
    'the',
    'and',
    'but',
    'or',
    'for',
    'nor',
    'as',
    'at',
    'the',
    'per',
    'by',
    'for',
    'from',
    'in',
    'is',
    'into',
    'near',
    'of',
    'on',
    'onto',
    'to',
    'with',
    'de',
    'le',
    'la',
    'du',
    'des',
    'pour',
    'par',
    'con',
    'es',
    'del',
    'ser',
    'para',
    'por',
    'solo',
    'y',
    'der',
    'ist',
    'von',
    'aus',
    'nach',
    'è',
    'di',
    'della',
  ];

  //console.log('words', words);

  const awords = words.split(' ');
  let newWord = awords
    .map((word, index) => {
      //console.log('index, word, words.length, awords.length ', index, word, word.length, ' ', awords.length);
      let longWordInLongPhrase =
        index > 0 && word.length >= 2 && !lowers.includes(word.toLowerCase());
      //if (index>0) console.log('word', word, awords[index-1].substr( -1));
      if (word === word.toUpperCase() && word.length > 1) {
        // dont' do startcase with words are already in uppercase. Eg: SMS -> Sms (wrong)
        return word;
      } else if (awords.length <= 3) {
        // phrase <=2 words
        if (index === 0 || !lowers.includes(word.toLowerCase())) {
          // important convert to lower before compare
          return startCase(word);
        } else {
          return word.toLowerCase();
        }

        // below case for phrase has more than 3 words
      } else if (
        // // first word is always upper, but from second word check if not lower hardcode
        index === 0 ||
        (changeFullLongPhrase && longWordInLongPhrase)
      ) {
        // changeFullLongPhrase ,is a parameter, normall all phrases are not title case
        return startCase(word);
      } else if (
        // // first word is always upper, but from second word check if not lower hardcode
        index > 0 &&
        '.>)'.includes(awords[index - 1].substr(-1)) &&
        !'('.includes(awords[index - 1].substr(0, 1)) // dont upper "( ) w", just: ") W"
      ) {
        // changeFullLongPhrase ,is a parameter, normall all phrases are not title case
        return startCase(word);
      } else {
        return word.toLowerCase();
      }
      return word;
    })
    .join(' ');
  return newWord;
};

export const getJSONValue = (fieldValue, log = false) => {
  let aValues;
  // dont convert type json array, or object
  if (log) {
    console.log(
      'fieldValue && realTypeOf(fieldValue)',
      fieldValue,
      realTypeOf(fieldValue)
    );
  }
  if (
    fieldValue &&
    realTypeOf(fieldValue) !== '[object Array]' &&
    realTypeOf(fieldValue) !== '[object Object]'
  ) {
    aValues = JSON.parse(fieldValue);
  } else {
    // dont need conversion
    aValues = fieldValue;
  }
  if (!aValues) aValues = []; // transport can be null in field
  if (log) console.log('aValues', aValues);

  return aValues;
};
export const disassemblyId = (id, idTour = true) => {
  if (!id) {
    return id;
  }
  const posChar = id.indexOf('/');
  if (posChar === -1) {
    return id;
  } else {
    if (idTour) {
      return id.substr(posChar + 1);
    } else {
      // normally disamble return tourid, but case export return id service
      return id.substr(0, posChar);
    }
  }
};

export const assemblyId = (id, extraid) => {
  if (!id) {
    return id;
  }
  const posChar = id.indexOf('/');
  if (posChar === -1 && extraid) {
    return id + '/' + extraid;
  } else {
    return id;
  }
};

export const getOptions = (
  sourceObject,
  props,
  joinFields = {},
  filterByObj = {},
  allfields = false,
  subfield = '',
  log = false
) => {
  // Warning sourceObject if is ql, need to be sent lile object { ql: qlName}
  // subfield  , JSON field with the options [ {id, option} ]
  // allfields = true, get all the fields from record, not only, id and name, warning: don't use to
  //                   save the objects in database, it's too much info, just id and name normally
  let tableName;
  let queryData;
  let queryArrayName;
  let tableRelated = '';

  //let log =  (tableName==='paymentdocstatus'?true:false);
  if (sourceObject && sourceObject.ql) {
    tableName = '';
    queryData = sourceObject.ql;
    queryArrayName = sourceObject.ql;
    // log= true;
  } else {
    tableName = sourceObject;
    queryData = `list_${tableName}`;
    queryArrayName = `${tableName}s`;
  }
  // if (tableName==='transportation') log = true;
  if (log)
    console.log(
      '==getOptions(), table:' +
        tableName +
        ', queryData:' +
        queryData +
        ', queryArrayName:' +
        queryArrayName
    );
  if (log) console.log('props:', props);
  let rootProps;
  if (props.containerPropsForm) {
    rootProps = props.containerPropsForm;
  } else {
    rootProps = props;
  }
  if (
    !rootProps ||
    !rootProps[queryData] ||
    !rootProps[queryData][queryArrayName]
  ) {
    // if (typeof props.containerPropsForm[queryData] === 'undefined') console.log('undefined queryData:'+queryData);
    // if (typeof props.containerPropsForm[queryData][queryArrayName] === 'undefined') console.log('undefined arrayDaya: '+queryData+'.' +queryArrayName);
    if (log) {
      console.log(
        'getOptions(), table:' +
          tableName +
          ' / not loaded; not found queryData :' +
          queryData +
          ' in props.containerPropsForm[' +
          queryData +
          '][' +
          queryArrayName +
          ']'
      );
      console.log('props', props);
    }
    return [];
  }
  if (log)
    console.log(
      'tableName, queryData, queryArrayName ,rootProps[queryData][queryArrayName]  ',
      tableName,
      queryData,
      queryArrayName,
      rootProps[queryData][queryArrayName]
    );
  let valueRows = [];

  if (
    Object.keys(joinFields).length === 0 &&
    Object.keys(filterByObj).length === 0
  ) {
    if (log)
      console.log(
        'getOptions(), table:' +
          tableName +
          ' / sent options directly, no joinFields'
      );
    // warning is returning all the fields ...
    // its ok, just that id and name properties must be present
    return rootProps[queryData][queryArrayName];
  } else {
    if (tableName) {
      // look at original id
      tableRelated = Tables[tableName];
    }
    //console.log(props.containerPropsForm[queryData][queryArrayName]);
    //console.log('tableRelated',tableRelated);

    //console.log(realTypeOf(joinFields),joinFields);
    let rows;
    if (subfield) {
      rows = rootProps[queryData][queryArrayName];
    } else {
      rows = rootProps[queryData][queryArrayName];
    }
    if (log)
      console.log(
        ' count records, options will be treated accord joinFields:',
        rows.length,
        joinFields
      );
    if (log) console.log(' rows to analyze', rows);
    rows.map((record, index) => {
      // %% Dec 2017, disabled ! not the right place? fast solution include all fields, so can be used in validation forms
      // list of fields come from field setted like .listmini key
      let valueRow = { id: record.id };
      //let valueRow =  Object.assign ( {}, record);
      let i = 1;

      // version complet TWIN L2031
      let nameRow = '';
      if (
        tableRelated &&
        realTypeOf(joinFields) === '[object Array]' &&
        joinFields.length > 0
      ) {
        // going to modify name if there are joinFields, only supported for related tables
        joinFields.map((fieldName) => {
          let field = tableRelated.fields[fieldName];
          //console.log('fieldName',fieldName);
          //console.log('field',field);
          /*
          look for subRelated table. Eg. registration has tourroom_id related (tourroom table),
          tourroom has hotel_id (hotel table) is subrelated table
           */
          let sqlfieldName;
          let fieldrow;
          if (field.listSource) {
            //console.log('*listSource',field.listSource,record[fieldName] );
            // it's a obj, with id and name
            let objOption = getOptionFromSource(
              field.listSource,
              record[fieldName]
            );
            fieldrow = objOption && objOption.name ? objOption.name : '';
          } else if (tableRelated.related && tableRelated.related[fieldName]) {
            //is table related
            // ['room_type','tour_id.name','tourroomhotel_id.name','tourroomtour_id.name' ])}
            const fieldSubRelated = tableRelated.related[fieldName].key;
            // const tableSubRelated = fieldSubRelated.table; // by the momment don't need it
            let nameFieldSql = tableName + fieldSubRelated;
            fieldrow =
              record[nameFieldSql] && record[nameFieldSql].name
                ? record[nameFieldSql].name
                : '';
          } else {
            sqlfieldName = fieldName;
            fieldrow = record[sqlfieldName]
              ? (field.typeValue && field.typeValue.substr(0, 5) === 'Price'
                  ? '€ '
                  : '') + record[sqlfieldName]
              : '';
          }
          //if ( i < joinFields.length && fieldrow && nameRow) nameRow += ' - ';
          if (fieldrow) nameRow += (nameRow ? ', ' : '') + fieldrow;

          i++;
        });
        valueRow.name = nameRow;
      } else if (
        tableRelated &&
        realTypeOf(joinFields) === '[object Object]' &&
        Object.keys(joinFields).length > 0
      ) {
        // going to modify name if there are joinFields, only supported for related tables
        //joinFields.map( (fieldName) => {
        // console.log('joinFields',joinFields);
        for (const [fieldName, objField] of Object.entries(
          joinFields.subfields
        )) {
          let field = tableRelated.fields[fieldName];
          /*
          look for subRelated table. Eg. registration has tourroom_id related (tourroom table),
          tourroom has hotel_id (hotel table) is subrelated table
           */
          let sqlfieldName;
          let fieldrow;
          if (field.listSource) {
            //console.log('*listSource',field.listSource,record[fieldName] );
            // it's a obj, with id and name
            let objOption = getOptionFromSource(
              field.listSource,
              record[fieldName]
            );
            fieldrow = objOption && objOption.name ? objOption.name : '';
          } else if (tableRelated.related && tableRelated.related[fieldName]) {
            //is table related
            // ['room_type','tour_id.name','tourroomhotel_id.name','tourroomtour_id.name' ])}
            const fieldSubRelated = tableRelated.related[fieldName].key;
            // const tableSubRelated = fieldSubRelated.table; // by the momment don't need it
            let nameFieldSql = tableName + fieldSubRelated;
            fieldrow =
              record[nameFieldSql] && record[nameFieldSql].name
                ? record[nameFieldSql].name
                : '';
          } else {
            sqlfieldName = fieldName;
            fieldrow = record[sqlfieldName]
              ? (field.typeValue && field.typeValue.substr(0, 5) === 'Price'
                  ? '€ '
                  : '') + record[sqlfieldName]
              : '';
          }
          //if ( i < joinFields.length && fieldrow && nameRow) nameRow += ' - ';
          if (fieldrow) nameRow += (nameRow ? ', ' : '') + fieldrow;

          i++;
        } //);
        valueRow.name = nameRow;
      } else {
        valueRow.name = record.name;
      }
      if (log) console.log('candidate valueRow to insert:', index, valueRow);
      if (log) console.log('original record:', record);
      let insertRow = true;

      if (filterByObj) {
        if (log) console.log('filterByObj:', filterByObj);
        Object.keys(filterByObj).forEach((fieldFilter) => {
          let recordValue;
          if (subfield) {
            // special subfield field, the field to compare is the id, in this case is hardcode the field
            recordValue = record.id;
            if (log) console.log(' compare 1 to:', recordValue);
          } else if (fieldFilter.includes('[')) {
            // importante use [\d*] , to match asterix or numbers, case
            // stopspoint_id: {  filterBy: ['transportation[*].transportation_id','tour_id']
            let inputNameArray = fieldFilter.match(/(\w+)\[([\d*]+)\]\.(\w+)/);
            // look in direct field by name on the record, no deep inside
            // E.g:  record : { field: value} , don't support search in subfiled of a record
            if (inputNameArray) {
              // check rare case like fieldarray registration. transport. stoppoint, bug code fails
              recordValue = record[inputNameArray[3]];
            } else {
              console.log(
                'error gettint inputNameArray field filter, record',
                fieldFilter,
                record
              );
            }
            if (log)
              console.log(
                ' compare 2 to inputNameArray[3]:',
                inputNameArray,
                recordValue
              );
          } else {
            recordValue = record[fieldFilter];
            if (log)
              console.log(
                ' compare 3 to fieldFilter:',
                fieldFilter,
                recordValue
              );
          }
          if (log)
            console.log(
              'recordValue ',
              recordValue,
              'filterObj',
              filterByObj,
              'fieldFilter',
              fieldFilter,
              'record',
              record
            );

          if (log)
            console.log(
              'filterByObj[fieldFilter] ?',
              fieldFilter,
              filterByObj[fieldFilter]
            );
          if (filterByObj[fieldFilter]) {
            // check null
            let valueFilter = filterByObj[fieldFilter].id
              ? filterByObj[fieldFilter].id
              : filterByObj[fieldFilter];
            //if (log)
            //console.log('field: '+fieldFilter+' , record[fieldFilter]: '+recordValue +' ,valueFilter: ',valueFilter ,  ' fillRecordValue',record);

            if (recordValue !== valueFilter) {
              insertRow = false;
              if (log)
                console.log(
                  'insertRow = false, recordValue !== valueFilter ',
                  recordValue,
                  valueFilter
                );
            } else {
              if (subfield) {
                insertRow = false; // false  , because will add manually
                let aSubFieldRows = [];
                let jsonSubValues = tryParseJSON(record[subfield]);
                if (jsonSubValues) {
                  jsonSubValues.map((subrecord) => {
                    valueRows.push({
                      id: subrecord.id,
                      name: subrecord.option,
                    });
                  });
                }
              } else {
                if (log)
                  console.log('insertRow = YESS ', recordValue, valueFilter);
              }
            }
          } else {
            // is null, then dont' insert
            if (log) console.log('insertRow = false');
            insertRow = false;
          }
        });
      }

      if (insertRow) valueRows.push(valueRow);
    });
    if (log)
      console.log(
        'getOptions(), table:' + tableName + '  / valueRows Rows:',
        valueRows
      );
    return valueRows;
  }

  // return [{id: "8b8d3a2e-31fd-404d-8dbf-e4358f1b489f", name: "ALPHAND, Josiane"}];
};

export const realTypeOf = (value) => {
  /*
  [object Undefined]
  [object Null]
  [object Array]
  [object String]
  [object Arguments]
  [object Function]
  [object Error]
  [object Boolean]
  [object Number]
  [object Date]
  [object RegExp]
  [object Object]
  [object JSON]
  [object Set]
  [object Map]
   */
  return Object.prototype.toString.call(value);
};

/*const transMsg = (msg, p = {}) => {

  const lang = localStorage.getItem('lang');
  const msgParts = msg.split('::');
  const words = msgParts[0].split('.');

  const aTranslate = allTranslate[lang];

  //console.log('word',words);
  if (sourcesOptions[words[0]] && sourcesOptions[words[0]+ 'Settings'] && sourcesOptions[words[0]+ 'Settings'].disabledTranslate) {
    //console.log('dont translate', words[0]);
    return words[1];
  }

  let msgTranslated = aTranslate[words[0]] && aTranslate[words[0]][words[1]] ?  aTranslate[words[0]][words[1]] : '**'+msg;
  if (p.long && aTranslate[words[0]] && aTranslate[words[0]][words[1]+'_long']) msgTranslated = aTranslate[words[0]][words[1]+'_long'];

  for (let p = 0; p + 1 < msgParts.length; p++) {
    msgTranslated = msgTranslated.replace(`{${p}}`, msgParts[p + 1]);
  }
  return msgTranslated;
};*/

// important for safari format date
export const dateStandard = (date) => {
  if (!date) return date;
  if (setDateFormat) {
    // twin DAT910
    const dateParts = date.split(/\D/g); // \D = Do a global search for non-digit characters:
    const formatD = format(
      new Date(dateParts[2], dateParts[1] - 1, dateParts[0]),
      'YYYY/MM/DD'
    );
    return formatD;
  } else {
    return date;
  }
};

/*
this tt functions envolves logic before to call to t function for translate
It's used in the case where translated need processed, or parameters can't be passed directely
Eg.  validations messages
 */
export const tt = (t, msg, p = {}) => {
  const lang = localStorage.getItem('lang');
  if (!msg) {
    console.trace();
    console.error('translate, input text null:');
    return ''; // fails, msg is not sent
  }
  const msgParts = msg.split('::');
  const words = msgParts[0].split('.');

  // don't translate:
  if (
    sourcesOptions[words[0]] &&
    sourcesOptions[words[0] + 'Settings'] &&
    sourcesOptions[words[0] + 'Settings'].disabledTranslate
  ) {
    return words[1];
  }
  // at the moment don't support _long conversion
  let msgTranslated = msgParts[0];
  // block TWIN TPAR211
  let params = {};
  for (let p = 0; p + 1 < msgParts.length; p++) {
    params['p' + (p + 1)] = msgParts[p + 1];
  }
  msgTranslated = t(msgTranslated, params);
  return msgTranslated;
};

function isValidDate(s) {
  // temporal function to know if date type french date is valid
  // format D(D)/M(M)/(YY)YY
  let dateFormat = /^\d{1,4}[\.|\/|-]\d{1,2}[\.|\/|-]\d{1,4}$/;

  if (dateFormat.test(s)) {
    // remove any leading zeros from date values
    s = s.replace(/0*(\d*)/gi, '$1');
    let dateArray = s.split(/[\.|\/|-]/);

    // correct month value
    dateArray[1] = dateArray[1] - 1;

    // correct year value
    if (dateArray[2].length < 4) {
      // correct year value
      dateArray[2] =
        parseInt(dateArray[2]) < 50
          ? 2000 + parseInt(dateArray[2])
          : 1900 + parseInt(dateArray[2]);
    }

    let testDate = new Date(dateArray[2], dateArray[1], dateArray[0]);
    if (
      testDate.getDate() != dateArray[0] ||
      testDate.getMonth() != dateArray[1] ||
      testDate.getFullYear() != dateArray[2]
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

/*
processValues is shared by app server and app client
WARNING:  for exclusive use in app server exist another function: graphqlResolver.js :: processValues()
 */
export const processValues = (
  props,
  tableName,
  values,
  target,
  action = '',
  options = { log: false }
) => {
  /*
    props: not used at the moment
  different from graphqlResolver.js :: processValues():
    dont' create an object new and add properties accord to fields of tables or related
      just modify the list of field found

   */
  let table = Tables[tableName];

  let log = options && options.log;
  // if (target.includes('toClient')) log = true;
  // if (target.includes('toServer')) log = true;

  //if (tableName === 'regonline' ) log = true; //&& target==='toServer' && action==='update'
  //if (tableName=== 'customer') log = true;

  if (log)
    console.log(
      `(client) ProcessValues -----------, table:${table.name.singular}, target:${target}, action:${action}, values`,
      values
    );

  //if (target === 'toServer') {

  /// sometimes is loading,  or hoc let to pass and draw the componente before data
  /// be loaded
  if (typeof values === 'undefined' || values === null) {
    // for recursive related tables process, can be null
    return values;
  }
  let newValues = { ...values }; // skip inmutability values // Object.assign fails silent when try update values
  let newGroupedValues = {};
  const ValuesofGroup = {};
  let addField;
  for (const fieldName in table.fields) {
    addField = true;
    //log =  (target.includes('toClient') && fieldName === 'id');
    const field = table.fields[fieldName];
    let value = values[fieldName];

    if (log)
      console.log(
        `${fieldName.toUpperCase()} *** ,type:${field.dbtype}, typeInput:${
          field.typeInput
        }, dbtype:${field.dbtype}, typeof: ` + typeof value
      );
    if (log) console.log('field def:', field);
    if (log) console.log('** value primitive:', value);

    if (fieldName === 'id') {
      addField = true; // always add, look at the cases like action='update' , target='toServer' if this don't be sent
    } else {
      // ex; caso delete just send id and user dlete hardcode, so dont give error here
      if (typeof value === 'undefined') {
        // || field.nameGroup ) {
        addField = false;
        if (log) console.error(`value is undefined for:${fieldName}`);
      } else {
        if (isString(value) && field.dbtype === 'String') value = value.trim(); // so des not affec another data like JSON
        /*
            TO  SERVER
         */
        if (target.includes('toServer')) {
          if (log) console.log(`Value primitive: typeof ${typeof value}`);

          if (
            field.dbAutoUpdate ||
            (action === 'update' && field.disabledSystemUpdate)
          ) {
            addField = false; // LOGIC TWIN J233
            if (log)
              console.log(
                'disable update for field: dbAutoUpdate or disabledUpdate'
              );
          } else if (
            field.typeInput === 'selectAutocomplete' &&
            !field.saveonly
          ) {
            /*
            value it's a object , from record from related table
             */
            if (typeof value === 'undefined' || isNil(value)) {
              // console.log(' has no vale, is ?');
              if (log) {
                console.log('value', value);
                if (typeof value === 'undefined') console.log('undefineddd');
                if (isNil(value)) console.log('null');
              }
              value = null;
            } else {
              /* data recovered from record , put the value like string,
              and not like object {id, name}, so
               in this case dont' as for value.id but directly by value why ?%%
               */
              if (value.id) {
                if (log) console.log('value.id found');
                value = value.id;
              } else {
                if (log) console.log('value.id not found, but value');
                value = value;
              }
            }
          } else {
            if (field.dbtype === 'Json') {
              if (log) console.log('is Json, going to Parse');
              /*
              json in textarea is not parse, is already string
               */
              if (value) {
                /*
                Analyse parsed values,  to discontinue when update cascade with triggers is working on  server
                 */
                // TWIN SUB728
                if (field.subfields) {
                  // has subfields, value is array
                  if (realTypeOf(value) === '[object Object]') {
                    // twin OAR143
                    console.error(
                      'Mobilsem ERROR 3: subfields are not Array: ' +
                        realTypeOf(value) +
                        ' , in: tableName:' +
                        tableName +
                        ', field:' +
                        fieldName
                    );
                    value = [];
                  }
                  /*
                    DISCONTINUED, unstable and very dangerous, better use trigger and reprocess
                    */
                  /*
                  value.map( ( rowvalue, index ) => {
                    Object.keys(field.subfields).forEach( (key) => {
                      let subfield = field.subfields[key];
                      let subvalue = rowvalue[key];
                      if (action === 'update' && !subfield.saveonly && subfield.typeInput === 'selectAutocomplete'
                        && (subfield.table || subfield.ql)
                       ) {
                        subvalue = subvalue.id ? subvalue.id : subvalue;
                        console.log('subvalue', subvalue);
                        let sourceOpts = subfield.table ? subfield.table : { ql: subfield.ql };
                        const record = getRecordFromOptionsIndirectOnFilter(props, sourceOpts, subvalue);
                        console.log('record',record);
                        if (record && record.name ) { // in theorie finds always
                          value[index][key] = { id: subvalue, name: record.name };
                        } else {
                          console.error('not foud name for subvalue '+ key+ ' :',subvalue);
                        }
                      }
                    });
                  });*/
                }
              }

              /*
              type input textarea that contains json (dbtype), must not be stringfied (add extra quotes at begin and end)
              and needed unescape quotes
               */
              if (value && field.typeInput === 'textarea') {
                value = replaceAll(value, '"', '"');
              } else {
                value = JSON.stringify(value);
              }
            }
            // if (field.dbtype === 'Int' && isBoolean(value)) value = value ? 1 : 0;
            if (field.dbtype === 'Date') {
              if (typeof value === 'undefined' || !value) {
                value = null;
              } else if (setDateFormat) {
                // twin DAT910
                const dateParts = value.split(/\D/g); // \D = Do a global search for non-digit characters:
                value = format(
                  new Date(dateParts[2], dateParts[1] - 1, dateParts[0]),
                  'YYYYMMDD'
                );
                // console.log('new valuedate', value);
              }
            } else if (field.dbtype === 'Int' || field.dbtype === 'Float') {
              // TWIN FI103
              /*
              TWIN Server FI108
              important for zero save like 0, and not like null, is not null and is not undefined
               */
              if (typeof value === 'undefined' || value === '') {
                // important if is empty string consider null, to skip error graphl
                // numeric type cant have empy string, but correct is null (different to zero)
                value = null;
              } else {
                // sometimes the value comes from store, already type number,
                // in this case not convert
                if (typeof value === 'string') {
                  // remove blank spaces
                  // convert to float
                  value = parseFloat(value.replace(/\s+/g, ''));
                }
              }
            }
          }

          /*
           previous blockresolve value, importante, like date
           must be null and not in '', to really save data
           */
          if (field.nameGroup) {
            // just in mode 'toServer' when data is going to send to server change
            const nameGroup = field.nameGroup;
            if (log) console.error('actual Newvalues');
            if (log) console.log(newValues);

            ValuesofGroup[nameGroup] = ValuesofGroup[nameGroup] || {};

            ValuesofGroup[nameGroup][fieldName] = value;
            if (log) console.log('after settted');
            if (log) console.log(ValuesofGroup);
          }
          /*
             TO CLIENT
         */
        } else if (target.includes('toClient')) {
          if (log && fieldName === 'info')
            console.error(`to Client check, dbtype:${field.dbtype}`);

          if (value) {
            if (field.typeValue && field.typeValue === 'Price') {
              if (typeof value === 'number') {
                //typeof give lowercase
                // float is sent always like string, despite  in Graphql and Sequelize is setted like Float
                value = toPrice(value); // format, but no problem for input to have a string value
              }
            }
            // convert the date to client
            if (field.dbtype === 'Date' && setDateFormat) {
              // twin DAT910
              // date come from server like 'yyyy-mm-dd'
              value =
                value.substr(8, 2) +
                '-' +
                value.substr(5, 2) +
                '-' +
                value.substr(0, 4);
            }

            // special fake Json. Eg. customer_id on registration, in Client must convert it, because is {id,name}(string) and autocomplet sync need obj
            // but 'inServer' does not convert to don't get error , because value is type 'ID' (string)
            if (
              (field.dbtype === 'Json' && !target.includes('inServer')) ||
              (field.dbtype === 'Json' &&
                target.includes('inServer') &&
                !(field.saveonly && field.saveonly === 'id'))
            ) {
              if (log) console.log('is Json, going to Parse');

              if (value) {
                /*
                for json field that is type textarea don't parse, treat like string
                 */
                if (
                  !target.includes('inServer') &&
                  field.typeInput !== 'textarea'
                ) {
                  if (log) console.log('going to JSON parse value', value);
                  // study distinct cases, is .loadAsync, .saveonly, fieldarray or direct field, etc
                  // %% actualy only basic check error conversion for not json type
                  try {
                    value = JSON.parse(value); // just case toClient from same appClient that get json in string
                    if (field.inputTypeObject && value) {
                      // must create values with name field +   input + '__' + id; Example: registration.objects
                      for (let [key, object] of Object.entries(value)) {
                        // get members of object: ignore value and id, the rest , must create inputs
                        for (let [subkey, subobject] of Object.entries(
                          object
                        )) {
                          const objectId = key; // %%% future, maybe add an id // = object.id;
                          // for now suboject.id === key
                          if (subkey === 'value') {
                            newValues[fieldName + 'Input__' + key] = subobject;
                          } else if (subkey !== 'id') {
                            // id goes to previeous input
                            newValues[fieldName + subkey + '__' + key] =
                              subobject;
                          }
                        }
                      }
                    }
                  } catch (e) {
                    // dont convert
                  }
                } else {
                  // 'toClient' process values like for the client, but in server to server, json is not string from db
                  // don't need convert it
                }
                /*
                Analyse parsed values,
                   - repair old format date (DD-MM-YYYY) without browser management
                to discontinue when update cascade with triggers is working on  server
                 */
                // TWIN SUB728
                if (field.subfields) {
                  // has subfields, value is array
                  if (realTypeOf(value) === '[object Object]') {
                    // twin OAR143
                    console.error(
                      'Mobilsem ERROR 3: subfields are not Array: ' +
                        realTypeOf(value) +
                        ' , in: tableName:' +
                        tableName +
                        ', field:' +
                        fieldName
                    );
                    value = [];
                  }

                  value.map((rowvalue, index) => {
                    Object.keys(field.subfields).forEach((key) => {
                      let subfield = field.subfields[key];

                      let subvalue = rowvalue[key];
                      if (log)
                        console.log(
                          'subfield, key, rowvalue',
                          subfield,
                          key,
                          rowvalue
                        );
                      if (subfield.dbtype && subfield.dbtype === 'Date') {
                        // Descontinuar, en el futuro, era para antiguos datos
                        if (isValidDate(subvalue)) {
                          // old format convert to YYYYMMDD
                          value[index][key] = dateToISO(rowvalue[key]);
                          //console.log('key:'+key+ ', index:'+index  ,value[index][key]) ;
                        }
                      }
                      /*
                      for no sync selectbox, convert  object: {id, name } to string:id
                      so can refresh name with the real and actual record, with object the problems
                      was always have different name when table record name has changed
                      Never for action ='list', another actions (by momement only action='form')
                      is changed only if is not async (!saveonly)
                      check if is undefined
                       */
                      if (
                        typeof subvalue !== 'undefined' &&
                        !target.includes('inServer') &&
                        action === 'view'
                      ) {
                        // for the client, if id is saved
                        if (
                          subfield.typeInput === 'selectAutocomplete' &&
                          subfield.saveonly
                        ) {
                          // only if subava
                          if (subvalue && !subvalue.id && subfield.nameisid) {
                            subvalue = { id: subvalue.id, name: subvalue.id };
                          }
                        }
                      }
                      /*


                       }
                      DISCONTINUED, unstable and very dangerous, better use trigger and reprocess
                      if (action === 'view' && !subfield.saveonly && subfield.typeInput === 'selectAutocomplete'
                        && (subfield.table || subfield.ql)
                       ) {
                        // important convert from Object: {id, name} to String:id,
                        // because still with reprocess.js, not every name in subfield is correctly changed
                        // E.g. Third column in Tour: Service (selectbox) names, accord to 'services' table
                        if (subvalue && subvalue.id ) {
                          value[index][key] = subvalue.id;
                        }
                      }*/
                    });
                  });
                }

                if (log) console.log('value parsed:', value);
              } else {
                // is empty or null, then value is null
                if (log) console.error('value parsed gave null');
                value = null;
              }
              /*
              old block when null was sent like string 'null'
              if (isString(value)) {
                // console.log('is string');
                if (isJSON(value)) {
                  if (log) console.log('is valid json');
                  value = JSON.parse(value); // reprocessa en recursive
                  if (log) console.log('after parse');
                  if (log) console.log(value);
                } else {
                  // if String invalid json , so convert to null
                  value = null;
                }
              } else {
                // value is not String, but 'object object' or 'array
                const objectType = toString.call(value);
                // just is accepted object with real arrays, or object with real objects
                // null or indefined result something like [object Undefined] [object Null]
                if (objectType !== '[object Array]' && objectType !== '[object Object]') {
                  value = null;
                }
              }*/
              if (field.fatherGroup && value) {
                // desempaquete las propiedades para mezclarlos fuera de su propiedad padre
                if (log) console.error('before mix propierties nameGroup');
                if (log) console.log(newValues, value);

                // WARNING: Using
                newGroupedValues = Object.assign(newGroupedValues, value);
              } else {
                //  Json field is not father group; it's just a isolated field, like 'changes' field to log
              }
              /* if (isJSON(value)) {
                value = JSON.parse(value);

                if (field.nameGroup ) { // desempaquete las propiedades para mezclarlos fuera de su propiedad padre
                  //console.error ('before mix propierties nameGroup');
                  //console.log(newValues );
                  newValues = Object.assign ( newValues, value  )
                  //console.error ('after mix propierties nameGroup');
                  //console.log(newValues );

                }

              } else {
                value = []; // Error JSON, maybe old string, or trash data, so
              }
               */
            }
            /*
             * Don't alterate another values sent by the server on the client, best the process be on the server
             * just Json, because is more complicated data and can have childrens too
             * */
          }
        }
      }
    }

    if (field.nameGroup) {
      addField = false;
    }
    if (addField) {
      newValues[fieldName] = value;
      if (log) console.log(`** value final:${value}`, value);
    } else {
      delete newValues[fieldName]; // newValues is copied from values, so need to delete
      if (log) console.log(`** field excluded`);
    }
  }

  const newValuesofGroupJson = {};
  if (target === 'toServer') {
    //forOwn(ValuesofGroup, (value, key) => {
    for (const [key, value] of Object.entries(ValuesofGroup)) {
      newValuesofGroupJson[key] = JSON.stringify(value);
      // //console.error('store key:'+key+' array full:');
      // //console.log(newValuesofGroupJson );
      //});
    }
    // import new values second parameter will overwritte first  object properties
    newValues = Object.assign(newValues, newValuesofGroupJson);
  } else {
    newValues = Object.assign(newValues, newGroupedValues);

    /*
    toClient, process subrelated
       */

    if (table.related && action !== 'delete') {
      //forOwn(table.related, (tableRelated) => {
      for (const [key, tableRelated] of Object.entries(table.related)) {
        if (target === 'toClient' && tableRelated.subrelated) {
          if (log) console.log('** related Tables for:' + tableRelated.table);
          if (log) console.log(tableRelated.fields);

          // special recursive only works for some case; example payment in checkdepositdet
          let subvalues = processValues(
            props,
            tableRelated.table,
            values[tableRelated.alias],
            target,
            action
          );
          //processValues  (tableRelated.table, values[tableRelated.alias], target, action , props)
          if (log) console.log('subvalues', subvalues);
          newValues[tableRelated.alias] = subvalues;
        } else {
          //fieldsAndValues[tableRelated.alias] = values[tableRelated.alias];
        }
        // reprocess related fields
      }
    }
  }

  // this block assigne values hardcode, important be after block read .values to be not overwritten
  // with null values, these values will keep assigned

  /*
    !! users id now resolved in server side !!
  if (action === 'update') {
    newValues.updated_user_id = getSession('iu');
  } else if (action === 'add') {
    newValues.created_user_id = getSession('iu');
  } else if (action === 'delete') {
    newValues.deleted_user_id = getSession('iu');
  }*/

  /*
  // START check related Fields
  //console.log('is related.........');
  //console.log(table.related);
  //console.log(table);
  if (table.related) {
    //console.log('------------- related tables:');
    //console.log(table.related);
    forOwn(table.related, (tableRelated, aliasKey) => {
      newValues[aliasKey] = values.aliasKey; // alis is important
    });
    //process.exit(1);
  }
  // END check related
*/
  if (log) console.log('newvalues-----------');
  if (log) console.log(newValues);
  return newValues;
};

export const intersection = (array1, array2, tableCrud = '', fieldKey = '') => {
  /*
  search intersection for templates for list fields, filters, and orders
  array1 = listView.fields[nameField].templates= [...list of templates]
  array2 = props.tempaltes  => [...list of templates]
   */

  // when templates are defined, automatic insert his name
  //   for future templates that send list of fields to displays
  const array1b = [...array1];
  const array2b = [...array2];

  // no templates defined on field react component, took tablecrud name, so can match with [.] defined in tables field
  if (array2b.length === 0 && tableCrud) array2b.push(tableCrud);

  if (fieldKey) array1b.push(fieldKey);

  let result = array1b.filter(function (n) {
    // '.' is a field included for the current table list,
    // sometimes list ist calles from another crud list. Eg: ajournal call list payment
    if (n === '.') n = tableCrud;
    return array2b.indexOf(n) !== -1;
  });
  return result.length === 0 ? null : result;
};

/*
http://krasimirtsonev.com/blog/article/Javascript-template-engine-in-just-20-line

 */
export const templateEngine = (html, data = {}, toText = false) => {
  try {
    /// %%% upgrade mustache version, use 'parse' method, actually need convert to html, and again to text for most of cases
    let resText = mustache.to_html(html, data);
    if (toText) {
      resText = he.decode(resText); // reconvert template coded (accents,etc) with &.. , to normal text
    }
    return resText;
  } catch (err) {
    return html;
  }
  /*return html.replace(
    /{(\w*)}/g,
    function( m, key ){
      return data.hasOwnProperty( key ) ? data[ key ] : "";
    }
  );*/
};

/*
translate function is needed
 */
export const setTemplateData = (
  data,
  currency = '€',
  translateFn,
  settings = null
) => {
  if (!data) return {};
  let newData = {};
  if (data.registrationcustomer_id) {
    //;
  }
  if (data.aCustomers) {
    newData.fullname = data.aCustomers.name;
    newData.firstname = data.aCustomers.firstname;
    newData.lastname = data.aCustomers.lastname;
    newData.email = data.aCustomers.email;
    newData.fulladdress = data.aCustomers.fulladdress;
    newData['adressecomplète'] = data.aCustomers.fulladdress;
    newData.gendertype = data.aCustomers.customergendertype_id
      ? data.aCustomers.customergendertype_id.name
      : '';
    newData.nomcomplet = newData.fullname;
    newData['prénom'] = newData.firstname;
    newData.firstnameLower = newData.firstname
      ? toTitleCase(newData.firstname)
      : '';
    newData['prénomMin'] = newData.firstnameLower;
    newData['nom'] = newData.lastname;
    newData['civilité'] = newData.gendertype;
  }

  if (data.resTour) {
    newData.voyageNomCourt = data.resTour.name;
    newData.voyageNomLong = data.resTour.longname;
    newData.voyageNameShort = newData.voyageNomCourt;
    newData.voyageNameLong = newData.voyageNomLong;

    newData.voyageDateStart = ISOtoDate(data.resTour.datestart);
    newData.voyageDateEnd = ISOtoDate(data.resTour.dateend);
    newData['voyageDateDébut'] = newData.voyageDateStart;
    newData['voyageDateFin'] = newData.voyageDateEnd;
  }
  //console.log('data.aCustomers', data.aCustomers);

  /*
  resolve room
   */
  if (data.resRoom && data.resRoom.room_type) {
    newData['room-type'] = getOptionFromSource(
      'roomBase',
      data.resRoom.room_type
    ).name;
  } else {
    newData['room-type'] = '';
  }
  newData['chambre-type'] = newData['room-type'];

  newData['hotel-name'] = '';
  if (data.resHotel) {
    // old hotel records can have longname empty
    newData['hotel-name'] = data.resHotel.longname || data.resHotel.name;
  }

  newData['hotel-address'] = '';
  newData['hotel-contacts'] = '';

  if (data.resHotel) {
    const infoExtraFields = getJSONValue(data.resHotel.info);
    const hotelContacts = data.resHotel.contacts; // it will be loossen because array objs deep
    data.resHotel = { ...data.resHotel, ...infoExtraFields };
    data.resHotel.contacts = hotelContacts;
    if (data.resHotel.addressline1)
      newData['hotel-address'] += '' + data.resHotel.addressline1;
    if (data.resHotel.addressline2)
      newData['hotel-address'] += ' ' + data.resHotel.addressline2;
    if (data.resHotel.cp) newData['hotel-address'] += ' ' + data.resHotel.cp;
    if (data.resHotel.city)
      newData['hotel-address'] += ' ' + data.resHotel.city;

    if (data.resHotel.contacts) {
      data.resHotel.contacts = getJSONValue(data.resHotel.contacts);

      data.resHotel.contacts.map((contact) => {
        if (contact.name) newData['hotel-contacts'] += ' ' + contact.name;
        if (contact.phone) newData['hotel-contacts'] += ' ' + contact.phone;
        if (contact.email) newData['hotel-contacts'] += ' ' + contact.email;
      });
    }
  }

  newData['hébergement-nom'] = newData['hotel-name'];
  newData['roommates'] = '';
  let room;
  if (data.bookingcustomers) {
    const customerroom = getObjFromListById(
      data.bookingcustomers,
      data.customer_id,
      true
    );
    // control exist:
    // normally must exist, except there is contradiction between  customers and bookings
    if (customerroom) {
      //console.log('data.customer_id, customerroom', data.customer_id, customerroom);
      room = data.bookings[customerroom.room];
      //console.log('customerroom, room', customerroom, room);
      if (room) {
        //console.log('room', room);
        for (const bed of room.beds) {
          if (bed.customers) {
            for (const roommate of bed.customers) {
              //console.log('roomate', roommate);
              if (roommate.id !== data.customer_id) {
                newData['roommates'] += ' *' + roommate.name;
              }
            }
          }
        }
      }
    }
  }
  newData['chambre-partenaires'] = newData['roommates'];
  /*
  end resolve room
   */

  /*
  resolve payments
   */

  let paymentList = '';
  let paymentListLong = '';
  let totalPayments = 0;
  //console.log('data.aPayments', data.aPayments);
  let paiementEmetteur = '';
  if (data.aPayments) {
    let p = 1;
    for (const payment of data.aPayments) {
      //console.log('data.aPayments payment ', payment );
      totalPayments += payment.amountline;

      /*
      def translates and fill translations
       */
      let aTranslates = {
        'form.datereception': '',
        'form.check_number': '',
        'form.datedeferred': '',
        'table.paymentdocstatus': '',
      };

      for (const [key, value] of Object.entries(aTranslates)) {
        let resTranslated;
        if (settings) {
          // special function to work on server, settings needed to resolve the language
          resTranslated = translateFn(settings, key);
        } else {
          resTranslated = translateFn(key);
        }
        aTranslates[key] = resTranslated;
      }
      /*
      end translate
       */

      //console.log('**************** (payment.datereception)', payment.datereception);

      // this variable actually it's used only for exportation comptable, where there is only one payment, and not all records
      paiementEmetteur += (paiementEmetteur ? '\n' : '') + payment.name;

      paymentList +=
        (paymentList ? '\n' : '') +
        p +
        ') ' +
        payment.paymentpaymentmethod_id.name +
        ': ' +
        toPrice(payment.amountline, { currency });
      if (payment.amoutline !== payment.amount)
        paymentList += ' (' + toPrice(payment.amount, { currency }) + ')';

      paymentListLong +=
        (paymentListLong ? '\n' : '') +
        p +
        ') ' +
        payment.paymentpaymentmethod_id.name +
        ': ' +
        toPrice(payment.amountline, { currency });
      if (payment.amountline !== payment.amount)
        paymentListLong += ' (' + toPrice(payment.amount, { currency }) + ')';

      paymentListLong +=
        ' ' +
        '  ' +
        aTranslates['form.datereception'] +
        ': ' +
        ISOtoDate(payment.datereception);

      if (payment.info && payment.info.check_number) {
        paymentListLong +=
          ', ' +
          aTranslates['form.check_number'] +
          ': ' +
          payment.info.check_number;
      }

      if (payment.datedeferred) {
        paymentListLong +=
          ', ' +
          aTranslates['form.datedeferred'] +
          ': ' +
          ISOtoDate(payment.datedeferred);
      }
      if (payment.paymentpaymentdocstatus_id) {
        paymentListLong +=
          ', ' +
          aTranslates['table.paymentdocstatus'] +
          ': ' +
          payment.paymentpaymentdocstatus_id.name;
      }

      p++;
      //console.log('** paymentList  ', paymentList  );
    }
  }

  newData.paymentListLong = paymentListLong;
  newData.paiementEmetteur = paiementEmetteur;
  newData['paimentlistdétaillé'] = newData.paymentListLong;
  newData.paymentList = paymentList;
  newData.paimentlist = newData.paymentList;

  /*
  resolve transport
   */
  let listTransportations = '';
  let stopspoint_id = '';
  if (data.aTransports) {
    for (const resTransport of data.aTransports) {
      //console.log('resTransport', resTransport);
      const routeplanner = JSON.parse(resTransport.routeplanner);
      const stoppointTour = getObjFromListById(
        routeplanner,
        resTransport.stopspoint_id,
        true
      );
      let stoppointDet = '';
      if (stoppointTour) {
        const stoppointTable = getObjFromListById(
          data.resStopsPoints,
          stoppointTour.from_stopspoint_id.id,
          true
        );
        // console.log('stoppointTable', stoppointTable);
        if (stoppointTable) {
          stoppointDet = stoppointTable.name;
          if (stoppointTour.sinfo) {
            stoppointDet += ': ' + stoppointTour.sinfo; // info routerplanner for tour transort (eg; time )
          }
          if (stoppointTable.sinfo) {
            stoppointDet += '\n' + stoppointTable.sinfo; // info table general stop
          }
        }
      }
      listTransportations +=
        (listTransportations !== '' ? '\n' : '') + `${resTransport.name}`;
      if (resTransport.driverinfo)
        listTransportations += `\n${resTransport.driverinfo}`;
      if (stoppointDet) listTransportations += `\n${stoppointDet}`;
    }
  }
  newData.transportationsList = listTransportations;
  newData.moyensdetransport = newData.transportationsList;

  newData.debtor = data.balance && data.balance > 0;
  newData['débiteur'] = newData.debtor;

  const price = data.price || 0;

  newData.balance = toPrice(data.balance || 0, { currency });
  newData.solde = newData.balance;

  newData.paid = toPrice(data.paid || 0, { currency });
  newData['totalpayé'] = newData.paid;

  newData.totalPrice = toPrice(price, { currency });
  newData.prixtotal = newData.totalPrice;

  let listServices = '';
  let totalServices = 0;

  if (data.aServices) {
    for (let service of data.aServices) {
      totalServices += service.amount;
      listServices += (listServices ? '\n' : '') + service.nameOnly; // use only name service
      listServices += ': ' + toPrice(service.amount, { currency });
    }
  }
  newData.servicesList = listServices;
  newData.serviceslist = newData.servicesList;

  newData.basePrice = toPrice(price - totalServices, { currency });
  newData.prixdebase = newData.basePrice;

  newData.numbering = data.numbering;
  // console.log("  newData.numbering = data.numbering;", data.numbering, data);
  newData.dossier = newData.numbering;

  newData.totalServices = toPrice(data.totalServices, { currency });
  newData.totalservices = newData.totalServices;

  return newData;
};

export const wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
export const isObject = (myVar) => myVar !== null && typeof myVar === 'object';
export const isString = (value) => {
  return typeof value === 'string' || value instanceof String;
};

export const processPayments = (
  payments,
  tour_id,
  customer_id,
  logPayment = false
) => {
  const paymentsProcessed = [];
  for (let p = 0; p < payments.length; p++) {
    let application = payments[p].application;
    if (application) {
      //console.log('application',application);

      if (isString(application)) application = JSON.parse(application);
      if (isString(payments[p].info)) {
        payments[p].info = JSON.parse(payments[p].info); // contains checknumber
      }
      for (let a = 0; a < application.length; a++) {
        // only select application for the current tour and registration customer
        // let ==, in case comparation string with another
        if (
          application[a].tour_id &&
          application[a].tour_id.id == tour_id &&
          application[a].customer_id &&
          application[a].customer_id.id == customer_id &&
          application[a].amount
        ) {
          // default is Credit if 'D' is not found // TWIN DENE81
          const amountline =
            (parseFloat(application[a].amount) || 0) *
            (payments[p].anature === 'D' ? -1 : 1);
          delete application[a].amount;
          paymentsProcessed.push({
            ...payments[p],
            ...application[a],
            amountline,
          });
        }
      }
    }
  }
  return paymentsProcessed;
};

export const getSMS = (wsms) => {
  let qtysms;
  if (!wsms) {
    qtysms = 0;
  } else if (wsms.length <= 160) {
    qtysms = 1;
  } else {
    qtysms = Math.ceil(wsms.length / 160);
  }

  return { lengthsms: wsms ? wsms.length : 0, qtysms };
};

export const getMainAddress = (resCustomer) => {
  let customerAddress = '';
  // console.log(' resCustomer ', resCustomer);
  // comes JSONB in string format, so convert to JSON
  if (
    resCustomer.addresses &&
    realTypeOf(resCustomer.addresses) === '[object String]'
  )
    resCustomer.addresses = JSON.parse(resCustomer.addresses);

  if (resCustomer.addresses && resCustomer.addresses.length > 0) {
    let mainAddresses = getObjFromListById(
      resCustomer.addresses,
      { type: 1 },
      true
    );
    // console.log(' mainAddresses ', mainAddresses );
    if (!mainAddresses) {
      //&& resCustomer.addresses.length>0
      mainAddresses = resCustomer.addresses[0];
    }
    if (mainAddresses.addressline1)
      customerAddress +=
        (customerAddress ? '\n' : '') + mainAddresses.addressline1;
    if (mainAddresses.addressline2)
      customerAddress +=
        (customerAddress ? '\n' : '') + mainAddresses.addressline2;
    if (mainAddresses.addressline3)
      customerAddress +=
        (customerAddress ? '\n' : '') + mainAddresses.addressline3;
    if (mainAddresses.cp || mainAddresses.city_id) {
      customerAddress += '\n';
      // twin client CP1203 fix cp value for wrong saved cp
      //  console.log('mainAddresses.cp', mainAddresses.cp);
      // warning can be {id:'' , name: ''} // it's for that triple or
      if (mainAddresses.cp) {
        if (
          mainAddresses.cp.name &&
          realTypeOf(mainAddresses.cp.name) === '[object String]'
        ) {
          customerAddress += mainAddresses.cp.name;
        } else if (
          mainAddresses.cp &&
          realTypeOf(mainAddresses.cp) === '[object String]'
        ) {
          customerAddress += mainAddresses.cp;
        }
      }
      if (mainAddresses.city_id && mainAddresses.city_id.name) {
        customerAddress += ' ' + mainAddresses.city_id.name;
      }
      const countryDet = getObjFromListById(
        sourcesOptions.countries,
        mainAddresses.country.id || mainAddresses.country
      );
      if (countryDet && countryDet.id !== 'FRA')
        customerAddress += '\n' + countryDet.name.toUpperCase();
    }
  }
  // console.log('*customerAddress', customerAddress);
  return customerAddress;
};

export const tryParseJSON = (jsonString, isNotReturn = false) => {
  try {
    if (
      realTypeOf(jsonString) === '[object Object]' ||
      realTypeOf(jsonString) === '[object Array]'
    ) {
      return jsonString; // dont need to change, is already a JSON, %%% and for []?
    }

    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return isNotReturn;
};

export const getPermission = (session, tableName, actionsave) => {
  // TWIN TAI231
  let permission = { add: false, update: false, delete: false, save: true };

  if (Tables[tableName].ai) {
    // for tables restrictes ( Eg: user and usergroup) only for admin. or permission setted
    permission.add =
      session.ai || (session.pe[tableName] && session.pe[tableName].add);
    permission.update =
      session.ai || (session.pe[tableName] && session.pe[tableName].update);
    permission.delete =
      session.ai || (session.pe[tableName] && session.pe[tableName].delete);
  } else {
    // for rest of tables, it's enough if is admin, or have no usergroup, or if have group will have the access to option
    permission.add =
      session.ai ||
      !session.gu ||
      (session.pe[tableName] && session.pe[tableName].add);
    permission.update =
      session.ai ||
      !session.gu ||
      (session.pe[tableName] && session.pe[tableName].update);
    permission.delete =
      session.ai ||
      !session.gu ||
      (session.pe[tableName] && session.pe[tableName].delete);
  }
  if (actionsave === 'update') {
    //'view') {
    permission.save = permission.update; // 'view' is update
  } else if (actionsave === 'add') {
    permission.save = permission.add;
  }
  return permission;
};

export const getNameHotelAndRoom = (t, tourroom, showPrice = true) => {
  let NameHotelAndRoom = '';
  NameHotelAndRoom = `${
    tourroom.tourroomhotel_id && tourroom.tourroomhotel_id.name
      ? tourroom.tourroomhotel_id.name
      : ''
  } `;
  const typeRoom = getObjFromListById(
    sourcesOptions.roomBase,
    tourroom.room_type
  ).name;
  const NameHotelAndRoomInfo = tourroom.name ? ` ${tourroom.name} ` : '';
  if (tourroom.isfullname && tourroom.name) {
    NameHotelAndRoom += `${NameHotelAndRoomInfo}`;
  } else {
    NameHotelAndRoom += `${
      typeRoom ? tt(t, 'roomBase.' + typeRoom) : ''
    }${NameHotelAndRoomInfo}`;
  }
  if (showPrice) {
    NameHotelAndRoom += ` €${tourroom.priceroom ? tourroom.priceroom : 0}`;
  }

  return NameHotelAndRoom;
};

export const getPriceService = (object, servicesObj, onlyName = false) => {
  let qty = object.q || 1;

  let serviceAmount = parseFloat(servicesObj.amount || 0);
  if (servicesObj.typeEntry === 'OL') {
    let optionFound = false;
    if (servicesObj.listOptions) {
      optionFound = servicesObj.listOptions.find(
        (option) => option.id === object.value
      );
    } else {
      console.log('not found listOptions for servicesObj', servicesObj);
    }
    // console.log("option found", optionFound);
    if (optionFound) {
      if (onlyName) {
        return servicesObj.name + ' : ' + optionFound;
      }
      // replace the price because option was founded with price
      serviceAmount = parseFloat(optionFound.amount || 0);
      // console.log("serviceAmount", serviceAmount);
    }
  } else {
  }
  if (onlyName) {
    return servicesObj.name;
  }
  serviceAmount = serviceAmount * qty; //isolation before to sum, so invalid amounts don't set total like NAN

  return serviceAmount;
};

export const getRelatedTourService = (
  processedTourServices,
  idTourService,
  valueObjectService,
  params = { name: '' }
) => {
  /*
  resolve not only servie related, but accord the value sent
  */
  let relatedTourService = getObjFromListById(
    processedTourServices,
    idTourService,
    true
  );
  if (relatedTourService) {
    // with lenght deduce is selectbox
    if (
      valueObjectService &&
      valueObjectService.value &&
      relatedTourService.listOptions.length > 0
    ) {
      const optionRelated = relatedTourService.listOptions.find(
        (option) => option.id === valueObjectService.value
      );
      if (optionRelated) {
        const nameOnly = relatedTourService.nameOnly;
        if (params.name === 'withPrice') {
          relatedTourService.nameOnly = optionRelated.fullNameAmount;
        } else if (params.name === 'optionName') {
          relatedTourService.nameOnly = optionRelated.name;
        } else {
          relatedTourService.nameOnly =
            nameOnly + ': ' + optionRelated.nameOnly;
        }
        relatedTourService.amount = optionRelated.amount;
      }
    }
  }
  return relatedTourService;
};

/*
const allExports = {
  dateToISO,
  datetimeToISO,
  ISOtoDate,
  contains,
  toPrice,
  replaceAll,
  toTimeZone,
  getObjFromListById,
  getDateFileName,
  getRoomInfo,
  sourcesOptions,
  getCamelCaseArray,
  resolvePathObj,
  toTitleCase,
  tt,
  isNil,
  getOptionFromSource,
  getOptions,
  getInputValue,
  realTypeOf,
  startCase,
  processValues,
  getTheme,
  getDefaultValue,
  getToday,
  intersection,
  wait,
  dateClean,
  yyymmddWithSeparatorToDate,
  isObject,
  isString,
  templateEngine,
  setTemplateData,
  processPayments,
  getSMS,
  smartTitleCase,
  getJSONValue,
  getMainAddress,
  tryParseJSON,
};
export default  allExports;

*/

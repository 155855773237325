import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql, compose } from 'react-apollo';
import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';

import { Field, reduxForm, change } from 'redux-form';

import ShowIcon from '../icons/ShowIcon';
import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import { CrudNav } from '../CrudNav';
import CrudFilterOrderBar from '../CrudFilterOrderBar';
import IconLoading from '../icons/IconLoading';
import { MsgError, ListDataHeader, ListDataControlRercord } from '../ListData';
import { getInputValue } from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
  getListviewFields,
} from '../../utils/helper';

import Header from './RegistrationHistoryHeader';

const templates = [];

const crudCode = {};
const tableCrud = 'registrationhistory';
const action = 'list';
const nameForm = '';
const qlName = 'crud_list_' + tableCrud;
const qlNameData = 'pageregistrationhistorys';
const formFilterName = 'listFilterregistrationhistory';
const formPagerName = 'registrationhistoryPager';
let table = Tables[tableCrud];
let aQlFiltered = {
  crud_list_registrationhistory: { table: 'registrationhistory' },
  list_user: { table: 'user' },
  list_tour: { table: 'tour' },
  list_tourroom: { table: 'tourroom' },
  list_registrationstatus: { table: 'registrationstatus' },
  list_confirmationstatus: { table: 'confirmationstatus' },
  view_registration: { table: 'registration' },
};
let tableCtrl = 'registration';

let aCtrlFields = [
  'registrationstatus_id',
  'dateregistration',
  'customerstatus_id',
  'confirmationstatus_id',
  'tourroom_id',
  'tollfree',
  'price',
  'transportation',
  'services',
  'notes',
  'inactive',
];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crudTable: tableCrud,
      mainForm: formFilterName,
      listMode: 'rows',
      counter: 0,
      dialogOpened: {},
      date: Date.now,
      actionsave: 'list',
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      id: '',
      listviewFields: {},
      pagesSettings: [],
      pageSettingId: null,
      visibleListViewFields: false,
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 4000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    if (localStorage.getItem('Error') !== null) {
      this.setState({ date: Date.now() });
      clearInterval(this.state.intervalId);
    }
    if (Tables[tableCrud].pollInterval) {
      this.props[qlName].refetch();
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  navPage = (navTo, page) => {
    let newPage = parseInt('0' + page._page);
    if (navTo === 'right' && page._page < page._totalpages) {
      newPage++;
    }
    if (navTo === 'left' && page._page > 1) {
      newPage--;
    }
    if (navTo === 'first') {
      newPage = 1;
    }
    if (navTo === 'last') {
      newPage = page._totalpages;
    }
    let strNewpage = parseInt('0' + newPage.toString());
    this.props.change('_page', strNewpage);
  };

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (methodCode === 'onChangeInput' && params.action !== 'initForm') {
      const { inputFullName, line, action, event, newValue } = params;
      const oldValue = getInputValue(this.props, inputFullName, {
        nameForm: formFilterName,
      });
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.props.dispatch(change(formPagerName, '_page', 1));
      }
    }

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  render() {
    const { t, ...otherProps } = this.props;

    let aElements = [];

    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
      />
    );

    aElements.push(<Header key="Header" {...this.props} />);

    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar1"
        crudCode={crudCode}
        executeCode={this.executeCode}
      />
    );

    aElements.push(
      <CrudFilterOrderBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudFilter"
        executeCode={this.executeCode}
        templates={templates}
      />
    );

    let result;
    let recordsLoaded;

    if (
      !resultCheck.globalLoading &&
      !resultCheck.messageError &&
      this.props[qlName] &&
      this.props[qlName][qlNameData]
    ) {
      recordsLoaded = true;
      result = this.props[qlName][qlNameData];
    }

    aElements.push(
      <form key="listForm">
        <Field key="_page" name="_page" component="input" type="hidden" />
      </form>
    );

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<IconLoading key="load" />);
    }

    if (recordsLoaded) {
      aElements.push(
        <CrudNav
          key="crudnav1"
          t={t}
          page={result.page}
          navPage={this.navPage}
        />
      );
      aElements.push(
        <ListDataHeader
          key="list"
          t={t}
          tableCrud={tableCrud}
          settings={this.state}
          extraCols={['form.updates']}
          refresh={
            !Tables[tableCrud].pollInterval && this.props[qlName]
              ? this.props[qlName].refetch
              : false
          }
        />
      );

      for (let r = 0; r < result.nodes.length; r++) {
        let record = result.nodes[r];
        let nextrecord =
          table.listSendNextRecord && r + 1 < result.nodes.length
            ? result.nodes[r + 1]
            : {};
        aElements.push(
          <ListDataControlRercord
            aCtrlFields={aCtrlFields}
            containerPropsForm={this.props}
            tableCtrl={tableCtrl}
            key={record.id}
            t={t}
            toolbarFunctions={this.toolbarFunctions}
            tableCrud={tableCrud}
            lastRecord={r + 1 === result.nodes.length ? true : false}
            record={record}
            nextrecord={nextrecord}
            settings={this.state}
            isLogHistory={true}
          />
        );
      }

      if (result.page._totalpages > 1) {
        aElements.push(
          <CrudNav
            key="crudnav2"
            t={t}
            page={result.page}
            navPage={this.navPage}
          />
        );
      } else {
        aElements.push(
          <div className="xrow" key="rfound">
            <div className="cell"></div>
            <div
              className="cell"
              style={{ color: '#999999', paddingTop: '20px' }}
            >
              {result.page._totalrecords} {t('info.recordsFound')}
            </div>
          </div>
        );
      }
    }

    return <div>{aElements}</div>;
  }
}

const ComponentWithData = reduxForm({
  form: formPagerName,
  enableReinitialize: true,
})(List);

const withRouterList = withRouter(ComponentWithData);
const withGraphqlandRouter = compose(
  graphql(defQls[tableCrud].ListPage, {
    name: qlName,
    options: (props) => {
      let filters = getFilters(tableCrud, props, formPagerName, formFilterName);
      const optionsValues = { variables: { ...filters, _qlType: 'ListPage' } };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !(
        props.myState &&
        props.myState.form &&
        props.myState.form[formFilterName] &&
        props.myState.form[tableCrud + 'Pager'] &&
        props.myState.form[formFilterName].values &&
        props.myState.form[tableCrud + 'Pager'].values
      );
    },
  }),
  graphql(defQls.user.ListMini, {
    name: 'list_user',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),

  graphql(defQls.tour.ListMini, {
    name: 'list_tour',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.tourroom.ListMini, {
    name: 'list_tourroom',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.registrationstatus.ListMini, {
    name: 'list_registrationstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.confirmationstatus.ListMini, {
    name: 'list_confirmationstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.registration.View, {
    name: 'view_registration',
    options: (props) => {
      return {
        variables: { id: props.match.params.parentid, _qlType: 'View' },
        fetchPolicy: 'network-only',
      };
    },
  })
)(withRouterList);

function mapStateToProps(state, ownProps) {
  let initialValues = { _page: 1 };
  const statesReturn = { myState: state };
  if (
    state &&
    state.form &&
    state.form[formPagerName] &&
    typeof state.form[formPagerName].values
  ) {
    statesReturn.initialValues = initialValues;
  }
  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(withGraphqlandRouter);

export default ComponentWithDataAndState;

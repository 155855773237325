import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import {
  Field,
  FieldArray,
  reduxForm,
  change,
  submit,
  getFormSyncErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FieldReadOnly from '../FieldReadOnly';
import FormArrayField from '../FormArrayField';

import { Button } from '../../layouts/cssstyled';
import ShowIcon from '../icons/ShowIcon';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
  getObjFromListById,
  sourcesOptions,
  getRelatedTourService,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

const tableCrud = 'regonline';
let nameForm = `${tableCrud}Form`;

// const validate = (values, props) => fieldsValidator('regonline', values, props, nameForm);

const validate = (values, props) => {
  let result = {}; // fieldsValidator(fields, values);
  if (values.state === 3) {
    if (!values.customer_id) {
      result.customer_id = props.t('validator.required');
    }
    if (!values.registrationstatus_id) {
      result.registrationstatus_id = props.t('validator.required');
    }
  }
  return result;
};

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: null,
    };
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  handleCheckChieldElement = (event) => {
    // this logic is simple, last value, overwrite existint it, from first object
    let customer_id;

    if (this.state.customer_id === event.target.value) {
      customer_id = null; // state is already this, then unckeck
    } else {
      customer_id = event.target.value;
    }
    this.setState({ customer_id });
    // modified input, need send it like json string {id, name}
    this.props.dispatch(
      change(nameForm, 'customer_id', { id: customer_id, name: '' })
    );
  };

  onProcessBtn = (state) => {
    this.props.dispatch(change(nameForm, 'state', state)); // modified input
    this.props.dispatch(submit('nameForm'));
  };
  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    // synchronousError - special  var created on redux  to get errors on props
    const {
      processing,
      error,
      handleSubmit,
      pristine,
      invalid,
      reset,
      submitting,
      t,
      changeLanguage,
      synchronousError,
      aHotels,
      aServices,
      customerStatus,
      customerSubStatus,
      nameRoom,
      data,
    } = this.props;

    // regonline
    const aRegs = [];
    const aPreRegs = [];
    const aPostRegs = [];
    const aRegsTitre = [];
    const aPerson = data.regonlineperson_id;
    const aCustomer = data.regonlinecustomer_id;

    let nameCustomerStatus = '';
    if (customerStatus) {
      nameCustomerStatus = customerStatus.name;
      if (customerSubStatus) {
        nameCustomerStatus =
          nameCustomerStatus + ' - ' + customerSubStatus.name;
      }
    }

    const aServicesSelects = [];
    const objects = tryParseJSON(data.objects, {});
    for (let [key, object] of Object.entries(objects)) {
      let servicetour = getRelatedTourService(aServices, key, object, {
        name: 'fullNameWithoutPrice',
      });
      if (servicetour) {
        aServicesSelects.push(
          <div className="formSection" key={key}>
            {servicetour.nameOnly} € {servicetour.amount}
          </div>
        );
      }
    }

    let countAssociated = 0;
    if (data.state === 1) {
      const aMatchedCustomers =
        this.props.containerPropsForm.getMatchedPersonCustomers
          .getMatchedPersonCustomers;
      if (aPerson) {
        const aPersonPhones = tryParseJSON(aPerson.phones);
        const aPersonAddresses = tryParseJSON(aPerson.addresses);
        const aPersonInfo = tryParseJSON(aPerson.info);
        aRegs.push(
          <div key="perg" style={{ paddingLeft: '3px' }}>
            <div style={{ paddingBottom: '7px' }}>
              <label className="formLabel">
                {t('form.personRegistrated')} :
              </label>
            </div>
            <div style={{ paddingLeft: '20px' }}>
              <div style={{ fontSize: '15px' }}>{aPerson.name}</div>
              <div>
                <strong>{t('form.nationality')}:</strong>{' '}
                {
                  getObjFromListById(
                    sourcesOptions.countries,
                    aPerson.nationality
                  ).name
                }
                &nbsp;<strong>{t('form.residence')}:</strong>{' '}
                {
                  getObjFromListById(
                    sourcesOptions.countries,
                    aPerson.residence
                  ).name
                }
                &nbsp;
                {aPersonInfo && aPersonInfo.birthcountry && (
                  <span>
                    {' '}
                    <strong>{t('form.birthcountry')}:</strong>{' '}
                    {
                      getObjFromListById(
                        sourcesOptions.countries,
                        aPersonInfo.birthcountry
                      ).name
                    }
                  </span>
                )}
              </div>
              <div>{aPerson.birthdate}</div>
              <div>{aPerson.email}</div>
              <div>
                {aPersonPhones &&
                  aPersonPhones.map((phone) => {
                    return <div key={phone.id}>{phone.ph}</div>;
                  })}
              </div>
              <div>
                {aPersonAddresses &&
                  aPersonAddresses.map((address) => {
                    return (
                      <div key={address.id}>
                        <div>
                          {address.addressline1} {address.addressline2}{' '}
                          {address.addressline3}
                        </div>
                        <div>
                          {address.cp.name} {address.city_id}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );

        let exsteCustomerId = null;

        if (aMatchedCustomers.length > 0) {
          {
            !aCustomer &&
              aRegsTitre.push(
                <div
                  key="jcc"
                  style={{
                    paddingBottom: '10px',
                    fontSize: '15px',
                    color: '#777777',
                  }}
                >
                  {t('info.matchperson')}
                </div>
              );
          }
          // first: if regonline have already customer_id then bring only that customer in  aMatchedCustomers, and make invisible checkbox
          // second: busca si en  la lista de customer (match), ya existe; regonline.person_id = customer.person_id

          aMatchedCustomers.map((aCustomer) => {
            if (aCustomer.person_id === aPerson.id) {
              exsteCustomerId = aCustomer.id;
              countAssociated++;
            }
          });
          aMatchedCustomers.map((aMatchedCustomer) => {
            // aMatchedCustomer is really a customer
            aPostRegs.push(
              <div
                key={aMatchedCustomer.id}
                className={aCustomer ? 'formSection' : null}
                style={{
                  paddingTop: '3px',
                  display: 'flex',
                  paddingBottom: '10px',
                  fontSize: '13px',
                  color: '#333333',
                  backgroundColor: !aCustomer ? '#ccebff' : null,
                  paddingLeft: '3px',
                }}
              >
                <div style={{ width: '25px' }}>
                  {(!aCustomer || aMatchedCustomers.length > 1) && (
                    <input
                      type="checkbox"
                      checked={this.state.customer_id === aMatchedCustomer.id}
                      onChange={this.handleCheckChieldElement}
                      value={aMatchedCustomer.id}
                    />
                  )}
                </div>
                <div style={{ paddingTop: '5px', paddingLeft: '5px' }}>
                  {aCustomer && aMatchedCustomers.length === 1 && (
                    <div
                      key="jccd"
                      className="formLabel"
                      style={{ paddingBottom: '5px', marginLeft: '-22px' }}
                    >
                      {t('info.customerlinked')}
                    </div>
                  )}
                  <div>{aMatchedCustomer.name}</div>
                  <div>{aMatchedCustomer.birthdate}</div>
                  <div>{aMatchedCustomer.email}</div>
                  <div>
                    {aCustomer &&
                      tryParseJSON(aCustomer.phones, []).map((phone, index) => {
                        return <div key={`phone${index}`}>{phone.phone}</div>;
                      })}
                  </div>
                  <div>
                    {aCustomer &&
                      tryParseJSON(aCustomer.addresses, []).map((address) => {
                        const city =
                          address.city_id && address.city_id.name
                            ? address.city_id.name
                            : '';
                        return (
                          <div key={address.id}>
                            <div>
                              {address.addressline1} {address.addressline2}{' '}
                              {address.addressline3}
                            </div>
                            <div>
                              {address.cp.name || address.cp} {city}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          });
        }

        // only if aCustomer  does not exist in regOnline,  come like {id, name} from the db record
        if (data.state === 1) {
          let personaMatchedOrSimilar = false;
          if (aCustomer || countAssociated === 1) {
            personaMatchedOrSimilar = true;
          }
          aPostRegs.push(
            <div
              key="pFound"
              style={{
                paddingTop: '7px',
                paddingBottom: '10px',
                paddingLeft: '3px',
              }}
            >
              <div
                style={{
                  fontSize: '15px',
                  color: '#333333',
                }}
              >
                <span>
                  Personne trouvée dans la base de donné ? &nbsp;&nbsp;&nbsp;
                </span>

                <span
                  style={{
                    color: personaMatchedOrSimilar ? '#144b7f' : 'darkred',
                    fontSize: '15px',
                  }}
                >
                  {personaMatchedOrSimilar ? 'Oui' : 'Non'}
                </span>
              </div>
              <div
                style={{
                  paddingTop: '3px',
                  display: 'flex',

                  fontSize: '11px',
                }}
              >
                (par nom, email ou date de naissance)
              </div>
            </div>
          );

          if (!personaMatchedOrSimilar) {
            aPostRegs.push(
              <div
                key="note"
                className="formSection"
                style={{
                  display: 'flex',
                  paddingBottom: '5px',
                  fontSize: '13px',
                  color: '#333333',
                  paddingLeft: '3px',
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    checked={this.state.customer_id === '*'}
                    onChange={this.handleCheckChieldElement}
                    value="*"
                    style={{
                      border: 'solid 1px blue',
                      background: 'radial-gradient(#B2DBFF, #dfdfdf)',
                    }}
                  />
                </div>
                <div style={{ paddingLeft: '7px' }}>
                  Cochez ici pour confirmer l'ajout d'un nouveau pelerin dans la
                  base de données
                </div>
              </div>
            );

            if (synchronousError && synchronousError.customer_id) {
              aPostRegs.push(
                <div
                  key="errcc"
                  className="formError"
                  style={{ color: '#be0000', paddingLeft: '3px' }}
                >
                  {synchronousError.customer_id}
                </div>
              );
            }
          }
        }
      }
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />
        {data.state > 1 && (
          <div className="formSection">
            <FieldReadOnly
              value={aPerson && aPerson.name ? aPerson.name : ''}
              label={t('table.person')}
              direction="row"
              noWidth={true}
            />
            <FieldReadOnly
              value={
                data.regonlinecustomer_id && data.regonlinecustomer_id.name
                  ? data.regonlinecustomer_id.name
                  : ''
              }
              label={t('table.customer')}
              direction="row"
              noWidth={true}
            />
          </div>
        )}
        {data.state <= 1 && (
          <Field
            inputName="customer_id"
            name="customer_id"
            typeInput="hidden"
            component={FormField}
            label=""
          />
        )}
        <div className="formSection">
          <FieldReadOnly
            value={
              data.regonlinetour_id && data.regonlinetour_id.longname
                ? data.regonlinetour_id.longname
                : ''
            }
            label={t('table.tour')}
            direction="row"
            noWidth={true}
          />
        </div>
        <div className="formSection">
          <Field
            inputName="dateregistration"
            name="dateregistration"
            formProps={this.props}
            pathInTables="regonline.fields.dateregistration"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <FieldReadOnly
            value={nameCustomerStatus}
            label={t('table.customerstatus')}
          />
          <FieldReadOnly value={nameRoom} label={t('table.tourroom')} />

          <Field
            inputName="room_shared"
            name="room_shared"
            formProps={this.props}
            pathInTables="regonline.fields.room_shared"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            disabled={this.props.id ? true : false}
          />
          <Field
            inputName="stopspoint_id"
            name="stopspoint_id"
            formProps={this.props}
            pathInTables="regonline.fields.stopspoint_id"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            disabled={this.props.id ? true : false}
          />
          <Field
            inputName="state"
            name="state"
            formProps={this.props}
            pathInTables="regonline.fields.state"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            disabled={this.props.id ? true : false}
            listSource="processRegonline"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="price"
            name="price"
            formProps={this.props}
            pathInTables="regonline.fields.price"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
          <Field
            inputName="paid"
            name="paid"
            formProps={this.props}
            pathInTables="regonline.fields.paid"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
        </div>
        {aServicesSelects}

        <Field
          inputName="notes"
          name="notes"
          formProps={this.props}
          pathInTables="regonline.fields.notes"
          formState={this.props.formState}
          nameForm="regonlineForm"
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
          disabled={this.props.id ? true : false}
        />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />
        {aPreRegs}
        <div
          className="formSection"
          style={{
            flexDirection: 'column',
            color: '#555555',
            fontSize: '14px',
          }}
        >
          {aRegs}
        </div>
        <div>{aRegsTitre}</div>
        <div>{aPostRegs}</div>
        {data.state === 1 && (
          <Field
            inputName="registrationstatus_id"
            name="registrationstatus_id"
            formProps={this.props}
            pathInTables="regonline.fields.registrationstatus_id"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
          />
        )}
        {
          // only accept processs,  when regonline has not manual state (processed or reject )
          // error paiement, paid, others , it's always possible confirm manually
          !processing && ![3, 7].includes(data.state) && (
            <div style={{ display: 'flex', paddingBottom: '15px' }}>
              <div style={{ position: 'relative', margin: 'auto' }}>
                <Button
                  onClick={() => this.onProcessBtn(3)}
                  disabled={submitting}
                >
                  <ShowIcon size="18" color="lightgreen" icon="check" />
                  {t('info.vAccept')} {t('table.regonline')}
                </Button>
              </div>
              <div style={{ position: 'relative', margin: 'auto' }}>
                <Button
                  onClick={() => this.onProcessBtn(7)}
                  disabled={submitting}
                >
                  <ShowIcon size="18" color="darkred" icon="close" />
                  {t('info.vReject')} {t('table.regonline')}
                </Button>
              </div>
            </div>
          )
        }
        <div
          style={{ color: 'darkred', fontSize: '15px', paddingBottom: '10px' }}
        >
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  // especial to get detailes errors by field
  statesReturn.synchronousError = getFormSyncErrors(nameForm)(state);
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import SelectNext from 'react-select-next';

import FormField from '../FormField';
import FormArrayField from '../FormArrayField';
import ShowIcon from '../icons/ShowIcon';
import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  getInputValue,
  getPermission,
  tryParseJSON,
} from '../../utils/commonutils';
import { getSession } from '../../utils/auth';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

import OnlineInscriptionUrl from './OnlineInscriptionUrl';
import ServiceCustomerStatus from './ServiceCustomerStatus';
import ServiceInputOptions from './ServiceInputOptions';

import LaunchRichText from '../slatejs/LaunchRichText';

let preComponents = {
  additionalservicesentryType: ServiceInputOptions,
  additionalservicescustomerstatus: ServiceCustomerStatus,
};
let preComponentsSet = {
  additionalservicesentryType: { position: 'insideBottom' },
  additionalservicescustomerstatus: { position: 'replaceInput' },
};

const tableCrud = 'tour';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) =>
  fieldsValidator('tour', values, props, nameForm);

class Form extends Component {
  constructor(props) {
    super(props);
    let session = getSession();
    // TWIN TAI231
    let permission = getPermission(
      session,
      tableCrud,
      props.formState.actionsave
    );
    this.state = {
      itineraryVisible: false,
      dialogOpened: {},
      permission,
    };
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  toolbarFunctions = (toolbar, params = {}) => {
    if (toolbar === 'gcu') {
      /*const tour_id =  getInputValue( this.props,  'tour_id', {nameForm: 'listFilterregistration'});
      if (!tour_id) {
        alert ( this.props.t('validator.required') + ': '+ this.props.t('table.tour'));
        return false;
      }*/

      this.setState({
        dialogOpened: { ...this.state.dialogOpened, RichText: true },
        defaultValues: {
          /*customer_id: null,
            tour_id: tour_id,*/
        },
      });
    }
    if (toolbar === 'itinerary') {
      const tour_id = getInputValue(this.props, 'tour_id', {
        nameForm: 'listFilterregistration',
      });
      if (!tour_id) {
        alert(
          this.props.t('validator.required') + ': ' + this.props.t('table.tour')
        );
        return false;
      }
      this.setState({
        dialogOpened: { ...this.state.dialogOpened, Itinerary: true },
        defaultValues: {
          customer_id: null,
          tour_id: tour_id,
        },
      });
    }

    if (toolbar === 'closeDialog') {
      let dialogOpenedNew = this.state.dialogOpened;
      if (dialogOpenedNew[params.dialogName]) {
        delete dialogOpenedNew[params.dialogName];
        this.setState({ dialogOpened: dialogOpenedNew });
      }
    }
  };

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;

    // const textInitValue = JSON.parse( resolvePathObj(this.props,'containerPropsForm.crud_view_tour.tour.textgcu', { notFound: '[]'}));
    const textInitValue = getInputValue(this.props, 'textgcu', { nameForm });
    // console.log('formprops', this.props);
    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />

        <div className="formSection">
          <Field
            inputName="name"
            name="name"
            formProps={this.props}
            pathInTables="tour.fields.name"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            autoFocus={this.props.formState.id ? '' : '1'}
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.name
            }
          />
          <Field
            inputName="longname"
            name="longname"
            formProps={this.props}
            pathInTables="tour.fields.longname"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.longname
            }
          />
          <Field
            inputName="country"
            name="country"
            formProps={this.props}
            pathInTables="tour.fields.country"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            listSource="countries"
          />
        </div>
        <Field
          inputName="description"
          name="description"
          formProps={this.props}
          pathInTables="tour.fields.description"
          formState={this.props.formState}
          nameForm="tourForm"
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
        />
        <div className="formSection">
          <Field
            inputName="seatsmax"
            name="seatsmax"
            formProps={this.props}
            pathInTables="tour.fields.seatsmax"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.IntPositive}
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.seatsmax
            }
          />
          <Field
            inputName="seatsmin"
            name="seatsmin"
            formProps={this.props}
            pathInTables="tour.fields.seatsmin"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.IntPositive}
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.seatsmin
            }
          />
        </div>
        <div className="formSection">
          <Field
            inputName="datestart"
            name="datestart"
            formProps={this.props}
            pathInTables="tour.fields.datestart"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'datestart',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="dateend"
            name="dateend"
            formProps={this.props}
            pathInTables="tour.fields.dateend"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'dateend',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="inactive"
            name="inactive"
            formProps={this.props}
            pathInTables="tour.fields.inactive"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="registrationstart"
            name="registrationstart"
            formProps={this.props}
            pathInTables="tour.fields.registrationstart"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'registrationstart',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="registrationend"
            name="registrationend"
            formProps={this.props}
            pathInTables="tour.fields.registrationend"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'registrationend',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="tourcategory_id"
            name="tourcategory_id"
            formProps={this.props}
            pathInTables="tour.fields.tourcategory_id"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            mustOnChange={true}
          />
          <Field
            inputName="onlineregtype"
            name="onlineregtype"
            formProps={this.props}
            pathInTables="tour.fields.onlineregtype"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'onlineregtype',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
            listSource="onlineRegType"
          />
        </div>
        {this.props.formState.actionsave === 'update' && (
          <OnlineInscriptionUrl
            {...this.props}
            containerState={this.state}
            formProps={this.props}
            formState={this.props.formState}
            executeCode={this.props.executeCode}
            t={t}
          />
        )}
        <div className="formSection">
          <Field
            inputName="gcu"
            name="gcu"
            formProps={this.props}
            pathInTables="tour.fields.gcu"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="radio"
            typeInput="radio"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'gcu',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: '65px' }}>&nbsp;</div>
            <div>
              <a
                onClick={() => {
                  this.toolbarFunctions('gcu');
                }}
              >
                <ShowIcon icon="create" color="#222222" />
              </a>
              {this.state.dialogOpened.RichText && (
                <LaunchRichText
                  key="LaunchRichText"
                  {...this.props}
                  ql="tourUpdate"
                  nameForm={nameForm}
                  permission={this.state.permission}
                  nameField="textgcu"
                  id={this.props.id}
                  textInitValue={textInitValue}
                  containerState={this.state}
                  toolbarFunctionsContainer={this.toolbarFunctions}
                />
              )}
            </div>
            <Field
              inputName="urlgcu"
              name="urlgcu"
              formProps={this.props}
              pathInTables="tour.fields.urlgcu"
              formState={this.props.formState}
              nameForm="tourForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          </div>
        </div>

        <div className="formSection" style={{ display: 'block' }}>
          <div style={{ color: '#555555', fontSize: '17px' }}>
            {t('form.summary')} {t('table.registrations')}
          </div>
          <div style={{ display: 'flex' }}>
            <Field
              inputName="total"
              name="total"
              formProps={this.props}
              pathInTables="tour.fields.total"
              formState={this.props.formState}
              nameForm="tourForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              normalize={normalizes.Price}
              typeInput="text"
              disabled={true}
            />
            <Field
              inputName="paid"
              name="paid"
              formProps={this.props}
              pathInTables="tour.fields.paid"
              formState={this.props.formState}
              nameForm="tourForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              normalize={normalizes.Price}
              typeInput="text"
              disabled={true}
            />
            <Field
              inputName="balance"
              name="balance"
              formProps={this.props}
              pathInTables="tour.fields.balance"
              formState={this.props.formState}
              nameForm="tourForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              normalize={normalizes.Price}
              typeInput="text"
              disabled={true}
            />
          </div>
        </div>
        <div className="formSection">
          <Field
            inputName="price"
            name="price"
            formProps={this.props}
            pathInTables="tour.fields.price"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.price
            }
          />
        </div>
        <div className="formSection">
          <Field
            inputName="cancelledprice"
            name="cancelledprice"
            formProps={this.props}
            pathInTables="tour.fields.cancelledprice"
            formState={this.props.formState}
            nameForm="tourForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={
              this.props.formState.disabledFields &&
              this.props.formState.disabledFields.cancelledprice
            }
          />
        </div>
        <div className="formSection">
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.modules.ACCO',
            true
          ) && (
            <Field
              inputName="acode_id"
              name="acode_id"
              formProps={this.props}
              pathInTables="tour.fields.acode_id"
              formState={this.props.formState}
              nameForm="tourForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="selectAutocomplete"
              tableCrud={tableCrud}
              typeInput="selectAutocomplete"
            />
          )}
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.modules.ACCO',
            true
          ) && (
            <Field
              inputName="acostcode_id"
              name="acostcode_id"
              formProps={this.props}
              pathInTables="tour.fields.acostcode_id"
              formState={this.props.formState}
              nameForm="tourForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="selectAutocomplete"
              tableCrud={tableCrud}
              typeInput="selectAutocomplete"
            />
          )}
        </div>
        <div className="formSection">
          {this.state.itineraryVisible && (
            <React.Fragment>
              <a
                onClick={() => {
                  this.setState({ itineraryVisible: false });
                }}
              >
                <ShowIcon icon="eyeclosed" color="#d08e8e"></ShowIcon>
              </a>
              <FieldArray
                inputName="itinerary"
                name="itinerary"
                formProps={this.props}
                pathInTables="tour.fields.itinerary"
                formState={this.props.formState}
                nameForm="tourForm"
                fieldParentKey="itinerary"
                component={FormArrayField}
                tableCrud={tableCrud}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                t={t}
                mode={{}}
              />
            </React.Fragment>
          )}
          {!this.state.itineraryVisible && (
            <React.Fragment>
              <a
                onClick={() => {
                  this.setState({ itineraryVisible: true });
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <ShowIcon icon="eye" color="#5f97c1"></ShowIcon>
                  </div>
                  <div
                    style={{
                      fontSize: '19px',
                      color: 'rgb(71, 71, 71)',
                      paddingLeft: '4px',
                      letterSpacing: '2px',
                    }}
                  >
                    {t('form.itinerary')}
                  </div>
                </div>
              </a>
              <Field
                inputName="itinerary"
                name="itinerary"
                formProps={this.props}
                pathInTables="tour.fields.itinerary"
                formState={this.props.formState}
                nameForm="tourForm"
                fieldParentKey="itinerary"
                component={FormField}
                tableCrud={tableCrud}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="hidden"
                typeInput="hidden"
                t={t}
                mode={{}}
              />
            </React.Fragment>
          )}
        </div>
        <div className="formSection">
          <FieldArray
            inputName="additionalservices"
            name="additionalservices"
            formProps={this.props}
            pathInTables="tour.fields.additionalservices"
            formState={this.props.formState}
            nameForm="tourForm"
            fieldParentKey="additionalservices"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            preComponents={preComponents}
            preComponentsSet={preComponentsSet}
            t={t}
            mode={{}}
          />
        </div>
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        if (log) console.log('fieldKey', fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

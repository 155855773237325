import React from 'react';
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from '../../utils/commonutils';

const Comp = (props) => {
  const fieldValue = resolvePathObj(
    props,
    'formProps.containerPropsForm.crud_view_registration.registration.registrationregonline_id.stopspoint_id',
    { notFound: null }
  );
  if (!fieldValue) {
    return null;
  }
  const fieldRelatedValue = getObjFromListById(
    props.formProps.containerPropsForm.list_stopspoint.stopspoints,
    fieldValue,
    { notFound: null }
  );
  if (!fieldRelatedValue) {
    return null;
  }

  return (
    <div className="linkedContainer" style={{ marginLeft: '30px' }}>
      <div>{fieldRelatedValue.name}</div>
    </div>
  );
};

export default Comp;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PageHeader from '../PageHeader';

class MyAccount extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <PageHeader
          title="menu.accounting"
          icon="accounting"
          key="pageheader"
          t={t}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link style={{ margin: '10px' }} to="/ajournal">
            ► {t('form.ajournal')}
          </Link>
          <Link style={{ margin: '10px' }} to="/ajournalcode">
            ► {t('table.ajournalcodes')}
          </Link>
          <Link style={{ margin: '10px' }} to="/aplan" className="navbar">
            ► {t('table.aplans')}
          </Link>
          <Link style={{ margin: '10px' }} to="/acostplan" className="navbar">
            ► {t('table.acostplans')}
          </Link>
          {/* <Link style = {{ margin: '10px' }} to='/bankstatement' className="navbar">► {t('form.bankstatement')}</Link> */}
        </div>
      </div>
    );
  }
}
export default MyAccount;

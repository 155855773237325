// www.mobilsem.com  - Copyright .src/components/settings/Settings.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';
import { bindActionCreators } from 'redux';

import PageHeader from '../PageHeader';
import defQls from '../../defQls';
import { processError } from '../../utils/helper';
import Form from './SettingsForm';
import { gqlActions } from '../../gqls/gqlactions';

import {
  appSettingsLoad,
  appSubmitStart,
  appSubmitStop,
} from '../../actions/appActions';

let aFields = {
  'settingsforms.customer_socialsn': {},
  'settingsforms.customer_nationality': {},
  'settingsforms.customer_residence': {},
  'settingsforms.customer_fullnameparent1': {},
  'settingsforms.customer_fullnameparent2': {},
  'settingsforms.customer_extrafieldstring1': {},
  'settingsforms.transport_seats': {},

  //'settingsforms.registration_tip': {},
  'settingsforms.gcu': {},
  'settingsforms.urlgcu': {},
  'settingsforms.onlineterms': {},
  //'settingsforms.registration_stopspointfree': {},
  'settingsforms.mailing_windowenvelope': {},
  'settingsforms.export_gender': {},
  'settingsforms.export_age': {},
  'settingsforms.export_nameseparated': {},
  'settingsforms.export_datecreation': {},
  'settingsforms.export_inscriptionlast': {},
  'settingsforms.export_transport_from_time': {},
  'settingsforms.export_transportphones': {},
  'settingsforms.export_payment_customerstatus': {},
  'accounting.orgAccountingName': {},
  'accounting.orgBankId': {},
  'accounting.orgBankAccountNumber': {},
  'accounting.inbank_paymentdocstatus_id': {},
  'accounting.exportdetailtemplate': {},
  textgcu: { type: 'Json', typeInput: 'RichText' },
};

class Settings extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = async (values) => {
    //console.log('values',values);
    // need to reload organisator config immediately for forms impact (eg. fields tip in inscription)
    let newValues = {};
    // this Settings is stringified by every key before to save
    let newSettings = {};

    const aNoGroupKey = []; // keys fields without separator 'field.subfield'
    for (const [key, fieldSource] of Object.entries(aFields)) {
      const [groupKey, subkey] = key.split('.');
      if (subkey) {
        if (!newValues[groupKey]) {
          newValues[groupKey] = {};
        }
        newValues[groupKey][subkey] = values[subkey];
      } else {
        aNoGroupKey.push(groupKey);
        if (values[groupKey]) {
          // if not null
          newValues[groupKey] = values[groupKey];
          if (fieldSource.type === 'Json') {
            newValues[groupKey] = JSON.stringify(newValues[groupKey]);
          }
          // console.log('groukey value', groupKey,values[groupKey],newValues[groupKey]);
        }
      }
    }

    for (const [key, value] of Object.entries(newValues)) {
      if (!aNoGroupKey.includes(key)) {
        // only if - field is  'key.subkey', // pure fileds was already stringifiy
        newSettings[key] = JSON.stringify({ ...value });
      } else {
        newSettings[key] = value;
      }
    }

    gqlActions('', 'setOrgSettings', this.props, newSettings)
      .then((response) => {
        this.props.appSettingsLoad(newValues);
      })
      .catch((err) => {
        processError(err, this.props, true);
      });
  };

  render() {
    const { error, pristine, invalid, reset, submitting, t } = this.props;

    let aElements = [];
    aElements.push(
      <PageHeader
        key="pageheader"
        icon="settings"
        title="menu.settings"
        t={t}
      />
    );

    aElements.push(
      <Form
        key="form"
        onSubmit={this.handleSubmit}
        t={t}
        aFields={aFields}
        containerPropsForm={this.props}
      />
    );

    return <div>{aElements}</div>;
  }
}

const withRouterList = withRouter(Settings);
const withGraphqlandRouter = compose(
  graphql(defQls.setOrgSettings, {
    name: 'setOrgSettings',
    options: (props) => {
      const optionsValues = { variables: { id: props.match.params.id } };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.getOrgSettings, {
    name: 'getOrgSettings',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'network-only';
      let filters = {};

      optionsValues.variables = { ...filters };
      return optionsValues;
    },
  }),
  graphql(defQls.paymentdocstatus.ListMini, {
    name: 'list_paymentdocstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  })
)(withRouterList);

const mapStateToProps = (state, ownProps) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSettingsLoad, appSubmitStart, appSubmitStop, dispatch },
    dispatch
  );
};

const ComponentFull = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentFull;

import React from 'react';
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from '../../utils/commonutils';

const Comp = (props) => {
  const { t } = props;
  const fieldValue = resolvePathObj(
    props,
    'formProps.containerPropsForm.crud_view_customer.customer.customerperson_id.addresses',
    { notFound: null }
  );
  if (!fieldValue) {
    return null;
  }
  const resultField = [];
  let oLines;
  try {
    oLines = JSON.parse(fieldValue);
    if (oLines.length === 0) {
      resultField.push(<div>{props.t('login.errorEmptyField')}</div>);
    }
  } catch (err) {
    return null;
  }

  oLines.map((line, key) => {
    resultField.push(
      <div key={'add1' + key}>
        {t(
          'addressesType.' +
            getObjFromListById(sourcesOptions.addressesType, line.type.id, null)
              .name
        )}
        : &nbsp;
        {line.addressline1}&nbsp;{line.addressline2}&nbsp;{line.adressline3}/{' '}
        <strong>{t('form.cp')}:</strong> {line.cp.name || line.cp}&nbsp;
        <strong>{t('form.city')}:</strong> {line.city_id.name || line.city_id}
        &nbsp;
      </div>
    );
  });

  return (
    <div className="linkedContainer" style={{ marginLeft: '2px' }}>
      <div>{resultField}</div>
    </div>
  );
};

export default Comp;

import { getInputValue } from '../../utils/commonutils';
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    formState,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
  } = params;

  const result = {};

  let newStates = { disabledFields: {}, hiddenFields: {} };

  /*
  block outside action, permanent
   */
  let isadmin;
  if (inputFullName === 'isadmin') {
    isadmin = newValue;
  } else {
    isadmin = getInputValue(formProps, 'isadmin', { nameForm });
  }

  //console.log('isadmin ',isadmin );

  let isChangedHiddenState = false;
  let isChangedDisableState = false;

  if (isadmin) {
    isChangedHiddenState = true;
    newStates.hiddenFields['usergroup_id'] = true;
  }

  if (isChangedDisableState || isChangedHiddenState) {
    result.newStates = newStates;
  }

  if (action === 'initForm') {
  }
  //console.log('result onChangeInput()', result);
  return result;
};

export default crudCode;

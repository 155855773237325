import { startCase } from '../utils/commonutils';
import defQls from '../defQls';
import { processError, uuid, resolveLink } from '../utils/helper';
import { Tables } from './../defTables';

/*
action = startCase,
table = lower case
table+action (camelCase) = gql name. Eg.
  ('customer','Add) => export const gqlCustomerAdd = gql` mutation customerAdd...`
__typename: startCase(table) = table name accord schema server. Eg.
    'Customer' => const typeDefs = `type Customer  { ...`
 */
// export const gqlActions = (table, action, props, values) => {

export const gqlActions = async (table, action, props, values, mode = {}) => {
  // hier only is used containerPropsForm, not used props form
  props = props.containerPropsForm ? props.containerPropsForm : props;

  /*
  Important to send to this function: props.appSubmitStop , to stop global variable 'loading'
   */
  // Normally cache will be setted in options with options.fetchPolicy, so this block var not needed
  // I left it waiting apollo v2 version to see if it's still useful
  const cacheManagement = true;
  let log = false; // zzzzz

  if (action === 'Add') {
    if (!values.id) values.id = uuid();
  }
  const tableStartCase = startCase(table);
  const tableListObj = `${table}s`; // Query fot the list (Eg 'customers' in server schema =>  customers: [Customer]  )

  //console.log(`gqlActions:${table} ${action}`);
  if (!cacheManagement) {
    //console.log('no cache managemenet');
  } else {
    /*
     Name Mutation in Component that contains gql. E.g.
     const graphqlCustomerAdd = graphql(gqlsCustomer.gqlCustomerAdd,  {
     name: 'mutationCustomer'} <-- options to identify the gql
     */
    // const mutationName = `mutation${tableStartCase}`;

    let mutationName;
    if (table) {
      mutationName = 'crud_' + action.toLowerCase() + '_' + table; // automatic name accord action and table
    } else {
      mutationName = action;
    }

    if (log) console.error('gqlActions: mutation name: ', mutationName);
    /*if (action === 'Update' || action === 'Add') {
      mutationName = 'crud_';
    } else if (action === 'Delete') {
      mutationName = 'mutationDelete';
    } else {
      processError(`mobilsemError.client::Mutation not found for action: ${action}`, props, true);
      return false;
    }*/

    // //console.log('values');
    // //console.log(values);

    /*const valuesOptimistic = {
      ...Object.assign(
        {},
        values,
        { id: Math.round(Math.random() * -1000000), __typename: tableStartCase },
      ),
    }; */

    //console.log('mutation:'+mutationName);
    if (!props[mutationName]) {
      console.error(
        'mobilsemError.client::gqlAction not found. Mutation not defined:' +
          mutationName
      );
      return Promise.reject(
        'mobilsemError.client::gqlAction not found. Mutation not defined:' +
          mutationName
      );
    }

    const response = await props[mutationName]({
      variables: values,
      fetchPolicy: 'no-cache',
      /*optimisticResponse: {
          [tableAction]: valuesOptimistic,
        },
        update: (store, { data: { [tableAction]: reponseRecord } }) => {
        },*/
    }).catch((err) => {
      console.log('there are errorsr');
      processError(err, props, true); // new 19 Dec. 2017
    });

    //console.error('values result mutationafter submit',response);
    // when an error is produce in previous block, response does not exist, eg: network error
    // is just when the server can send error description the below block is useful
    let namePropsData = '';
    let responseDate;
    if (typeof response !== 'undefined') {
      if (!response.errors) {
        // //console.log('ok');
        if (action === 'Delete') {
          props.showNotificationWithTimeout(props.t('alert.datadeleted'), 3);
        } else {
          props.showNotificationWithTimeout(props.t('alert.datasaved'), false);
        }

        // this.props.history.push('/customers');
        if (log) console.error('response', response);

        // view update does not change page
        // resolveLink ,  second parameter: data, is the name of ql accort to action, Add, Update...
        let link = '';

        if (action === 'Delete') {
          namePropsData = table + 'Delete';
          link = resolveLink(table, response.data[namePropsData], 'list');
          if (log) console.log('delete redirect to:' + link);
        } else if (action === 'Add') {
          if (
            mode.redirect === 'dontredirect' &&
            (mode.action === 'Add' || mode.action === '*')
          ) {
            // case preinscription possible payment online, manage manually from TourAdd
            return response; // return data result (view record)
          } else if (Tables[table].afterAddGoView) {
            namePropsData = table + 'Add';
            link = resolveLink(table, response.data[namePropsData], 'view');
            if (log) console.log('added afterAddGoView redirect to:' + link);
          } else {
            namePropsData = table + 'Add';
            link = resolveLink(table, response.data[namePropsData], 'list');
            if (log) console.log('added redirect to:' + link);
          }
        } else if (action === 'Update') {
          // dont need to resolve link
          namePropsData = table + 'Update';
          if (Tables[table].hardReloadAfterSave) {
            link = resolveLink(table, response.data[namePropsData], 'view');
          }
        }

        if (response.data && response.data[namePropsData]) {
          // new, for some cases to handle submit
          //console.log('found', response.data[namePropsData]);
          responseDate = response.data[namePropsData];
        }

        // 2024 for the case like 'etemplates' same table is used for two differents behavioirs forms
        const urlParams = new URLSearchParams(props.location.search);
        const type = urlParams.get('type');
        if (type !== null) {
          link = link + '?type=' + type;
        }

        if (log) console.log('action: ' + action + ', link', link);
        // does not change link if is a subwindows, so does not produce errot to try get popup url value

        // TWIN OPEN2222
        // don't use window.opener on voyage, produce error when comes from click from pelerinages, look for possible solutions, with
        // try did not work
        if (
          link &&
          (window?.appConfig?.REACT_APP_NAME.includes('Voyages') ||
            !window.opener)
        ) {
          let sameComponent = false;
          if (responseDate) {
            // try delete id from path to detect if redirect go to another component
            let linkPreviousPure = props.location.pathname;
            if (props.match && props.match.params && props.match.params.id) {
              linkPreviousPure = linkPreviousPure.replace(
                props.match.params.id,
                ''
              );
            }
            const linkNewPure = link.replace(responseDate.id, '');
            if (Tables[table].hardReloadAfterSave) {
              window.location.href = link;
            } else {
              setTimeout(() => {
                props.history.push(link);
              }, 500);
            }
            if (linkPreviousPure !== linkNewPure) {
              // change componente, just enough redirect to load another componente
              // for the same componente same if id is different, redirect does not reload
              // al the data , so must go on below and return responseDate to be load manually
              // and redux form set with intialvalues
              return 'dontreloaddata'; // enought to return
            }
          }
        }
      } else {
        //if (log)
        //console.error('gqlActions: error: ',response.errors);
        processError(response.error, props, true);
        return false; // don't must reload values, because error
      }
    }
    // warning execute this block before break all logic and form return old values
    // in the future this block must not be here, but in code that calls this block
    if (props && props.appSubmitStop) {
      //console.log('submit stop-----------');
      props.appSubmitStop();
    }
    if (responseDate) {
      // new, for some cases to handle submit
      //console.log('responseDate', responseDate);
      return responseDate;
    }

    /*

    // sync function
    props[mutationName](
      {
        variables: values,
        optimisticResponse: {
          [tableAction]: valuesOptimistic,
        },
        update: (store, {data: {[tableAction]: reponseRecord}}) => { */

    // suspend, errores in store throw error and dont' go ok to then block
    // Read the data from the cache,
    /* if (get(store.data, 'ROOT_QUERY.'+tableListObj)) {
            //console.log('***update store');
            //console.log(store);
            const data = store.readQuery({query: defQls[table.toLowerCase()]['List'] });
            //console.log('ready query data from store');
            //console.log(data);

            if (action === 'Update') {
              data[table.toLowerCase()].filter(record => (record.id === reponseRecord.id))[0] = reponseRecord;
            } else if (action === 'Add') {
              data[table.toLowerCase()].push(reponseRecord);
            }
            // Write the data back to the cache.
            store.writeQuery({query: defQls[table.toLowerCase()]['List'] , data});

            //console.log('***new store');
            //console.log(store);
          } else { */
    /* sometimes when it's moved to list page quickly from a edit page without to habe been in list page,
             * get on the screen 'Loading...' lot of seconds because of timer.., wait the real connection of red
             *
          } */
    /* },
      },
    )
      .then((response) => {
        ////console.log('response');
        ////console.log(response);
        if (!response.errors) {
          ////console.log('ok');
          if (action === 'Delete') {
            props.showNotificationWithTimeout(props.t('alert.datadeleted'),3);
          } else {
            props.showNotificationWithTimeout(props.t('alert.datasaved'),false);
          }

          // this.props.history.push('/customers');
          if (action === 'Add' || action === 'Delete') props.history.push('/'+table);
        } else {
          processError(response.error, props, true);
        }
      })
      .catch((err) => {
        // Looks never come here, because apollo client send the errors in response,
        // in previous block
        processError(err, props, true);
        //console.log('response catch error');
        //console.error(err);
      }); */
  }
};

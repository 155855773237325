import React from 'react';
import { Link } from 'react-router-dom';
import ShowIcon from '../components/icons/ShowIcon';
import { realTypeOf } from '../utils/commonutils';
import { Tables } from './../defTables';
export const getSubFieldsToPrint = (
  objField, // normally = {true}, or other case have propieties like  { linkview: "..."},
  mainfieldsRelatedTabledAlias,
  tableName,
  containerFieldName,
  keyName,
  listViewField,
  fieldSource,
  recordParsed,
  values
) => {
  let log = false;
  if (log)
    console.log(
      '*** there is subfields definition , values:',
      values,
      'listViewField.subfields',
      listViewField.subfields,
      'recordParsed',
      recordParsed,
      'containerFieldName',
      containerFieldName,
      'tableName',
      tableName,
      'keyName',
      keyName
    );

  let newvalue = [];
  if (!values) {
    return null;
  }
  for (let i = 0; i < values.length; i++) {
    const item = values[i];
    let subvalueJoined = [];

    /*
          iteration sub fields from a Row value
           */

    for (const key in listViewField.subfields) {
      let nameSubField = key;
      let subitemValue = item[nameSubField];

      let listViewSubField = listViewField.subfields[key];

      if (log) {
        console.log(
          'containerFieldName',
          containerFieldName,
          'key',
          key,
          'field: ',
          keyName,
          ' ,listViewSubField:',
          nameSubField + ', subitemValue ',
          subitemValue
        );
        console.log('fieldSource', fieldSource);
      }

      let subfieldSource;
      if (containerFieldName) {
        // case related from related (eg checkdepositdet->payment_id->application)
        subfieldSource =
          Tables[tableName].fields[containerFieldName].subfields[nameSubField];
      } else {
        subfieldSource = fieldSource.subfields[key];
      }

      if (typeof subitemValue !== 'undefined') {
        //if (subvalueJoined !== '') {
        if (subvalueJoined.length > 0) {
          subvalueJoined.push(
            listViewField.separatorsubfield
              ? listViewField.separatorsubfield
              : ' '
          );
        }
        // value can be object containing name
        let subValue = '';
        /*console.log(
          "key",
          key,
          "objField",
          objField,
          "fieldSource",
          fieldSource,
          "subfieldSource",
          subfieldSource,
          "listViewSubField",
          listViewSubField,
          "recordParsed",
          recordParsed
        );*/
        if (subfieldSource.typeInput === 'urlLink') {
          //twin U3902
          if (subitemValue) {
            // only if has content
            let url = subfieldSource.url;
            url = url.replace('{link}', subitemValue);
            if (url.includes('/exports/')) url = url.replace('/exports/', ''); // old export, correct link, remove first /
            url = url.replace(
              '{DOWN_URI}',
              window?.appConfig?.REACT_APP_DOWN_URI
            );
            subValue = (
              <a key={key} href={url}>
                <ShowIcon icon="download" color="#62a7d0" />
              </a>
            );
          }
        } else if (
          subfieldSource.typeValue &&
          subfieldSource.typeValue.substr(0, 5) === 'Price'
        ) {
          subValue = parseFloat(subitemValue || 0).toFixed(2);
        } else {
          if (realTypeOf(subitemValue) === '[object Object]') {
            // it's important dont add objects,
            // add the moment just is reconigze name
            if (realTypeOf(subitemValue.name) === '[object String]') {
              // object is type { id, name}
              if (subitemValue.name) {
                subValue = subitemValue.name;
              } else {
                let logErrorData =
                  'nameSubField: ' +
                  nameSubField +
                  ' =>value is object {id, name} with empty name ';
                // name is empty, ask for id
                if (!subitemValue.id) {
                  logErrorData += ' with empty id';
                }
                console.log(logErrorData, 'record', recordParsed);
              }
            } else {
              /*console.log(
                "subValue problem is object:",
                realTypeOf(subitemValue.name),
                subitemValue,
                "record",
                recordParsed
              );*/
            }

            if (
              listViewSubField &&
              listViewSubField.linkview &&
              subitemValue.id
            ) {
              subValue = (
                <Link
                  key={key}
                  to={`/${listViewSubField.linkview}-view/${subitemValue.id}`}
                >
                  {subValue}
                </Link>
              );
            }
          } else {
            subValue = subitemValue;
          }
          if (
            subValue &&
            listViewSubField.maxLength &&
            subValue.length > listViewSubField.maxLength
          )
            subValue = subValue.substr(0, listViewSubField.maxLength) + '...';
          if (subfieldSource.labeltemplate)
            subValue = subfieldSource.labeltemplate.replace(
              '{value}',
              subValue
            );

          if (subfieldSource.icons) {
            if (subfieldSource.icons[subValue]) {
              subValue = (
                <ShowIcon
                  key="ico"
                  icon={subfieldSource.icons[subValue].icon}
                  color={subfieldSource.icons[subValue].color}
                  size="15"
                />
              );
            }
          }
        }

        subvalueJoined.push(subValue);

        if (log) console.log(' value: ', subitemValue);
      } else {
        if (log) console.log(' no value');
      }
    }
    //if (subvalueJoined.trim() !== '') {

    if (realTypeOf(subvalueJoined) === '[object Object]') {
      console.log('subvalueJoined problem is object', subvalueJoined);
    } else if (subvalueJoined.length > 0) {
      // separator is blank or undefined, then every row has a different line
      const display = listViewField.separator ? 'inline' : 'block';
      const whiteSpace = listViewField.separator ? 'normal' : 'nowrap';
      // console.log('subvalueJoined ',realTypeOf(subvalueJoined), subvalueJoined);

      // console.log('subvalueJoined',subvalueJoined, recordParsed)
      newvalue.push(
        <div style={{ whiteSpace, display }} key={keyName + i}>
          {subvalueJoined}{' '}
        </div>
      );
      if (!listViewField.joinsubfields) {
        newvalue.push(<br key={keyName + i + 'br'} />);
        if (log) console.log(' insert br separator, not joinsubfields setted ');
      } else if (!listViewField.separator === '') {
        if (i + 1 < values.length)
          newvalue.push(<span key={'sep' + i}> - </span>);
        if (log) console.log(' joinsubfields detected, no br separator');
      }
    }

    if (listViewField.limit && i + 1 === listViewField.limit) break;
  }
  return newvalue;
};

// www.mobilsem.com  - Copyright .src/components/transportation/TransportationView.js
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, change, touch, initialize } from 'redux-form';
//import { Values } from 'redux-form-website-template';

import Form from './TransportationBookingForm';

import defQls from '../../defQls';

import { Tables } from '../../defTables';

import {
  submitValues,
  deleteRecord,
  processError,
  errorTranslate,
  checkLoadCrud,
  getFilters,
} from '../../utils/helper';

import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import IconLoading from '../icons/IconLoading';
import { MsgError } from '../ListData';
import RecordInfo from '../RecordInfo';

// import RoomHeader from './RoomHeader';
import {
  tt,
  resolvePathObj,
  realTypeOf,
  processValues,
} from '../../utils/commonutils';
import { showConfirm } from '../../actions/confirmActions';

const crudCode = {};

const tableCrud = 'transportation';
const action = 'view';

const formFilterName = 'listFiltertransportation';

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: 'update',
      actionsave_origen: 'update',
    };
  }

  handleSubmit = async (values) => {
    return await submitValues(
      tableCrud,
      this.state.actionsave,
      this.props,
      false
    );
  };

  toolbarFunctions = (toolbar) => {
    if (toolbar === 'duplicate') {
      this.setState({ actionsave: 'add', actionsave_origen: 'duplicate' });
    }
  };

  changePage = (link) => {
    this.props.goUrl(link);
  };

  handleDeleteRecord = () => {
    deleteRecord(tableCrud, this.props);
  };

  render() {
    const { t, ...otherProps } = this.props;

    let aElements = [];
    let aQlFiltered = {
      list_stopspoint: { table: 'stopspoint' },
      crud_view_transportation: { table: 'transportation' },
      crud_update_transportation: { table: 'transportation' },
      crud_add_transportation: { table: 'transportation' },
      crud_delete_transportation: { table: 'transportation' },
      view_tour: { table: 'tour' },
      list_transportation: { table: 'transportation' },
    };

    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        icon="seat"
        title="table.seatbooking"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        id={this.props.match.params.id}
      />
    );

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: '100%', height: '300px' }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }
    if (this.state.actionsave === 'add') {
      aElements.push(
        <div key="addlegend" style={{ color: 'darkred' }}>
          {t('info.' + this.state.actionsave_origen)}
        </div>
      );
    }

    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      // aElements.push ( <RoomHeader key="RoomHeader" {...this.props} t={t} /> );

      aElements.push(
        <Form
          changePage={this.changePage}
          crudAction="View"
          key="mainform"
          id={this.props.match.params.id}
          parentid={this.props.match.params.parentid}
          data={this.props['crud_view_' + tableCrud][tableCrud]}
          onSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          t={this.props.t}
        />
      );
    }

    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <RecordInfo
          key="recordinfo"
          tableCrud={tableCrud}
          {...this.props}
          data={this.props['crud_view_' + tableCrud][tableCrud]}
        />
      );
    }
    return <div>{aElements}</div>;
  }
}

const withGraphqlandRouter = compose(
  graphql(defQls.stopspoint.ListMini, {
    name: 'list_stopspoint',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.transportation.View, {
    name: 'crud_view_transportation',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'View' },
      };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.getTransportationCustomers, {
    name: 'getTransportationCustomers1',
    options: (props) => {
      const transid = resolvePathObj(
        props,
        'myState.form.transportationForm.values.transportation_id1',
        { notFound: null }
      );

      let variables = { _qlType: 'View' };
      if (transid) {
        // (transid && transid.id) autocomplete very complex to manage , reeplaced by selectbox
        variables.transportation_id = transid;
      } else if (realTypeOf(transid) === '[object String]') {
        // is blank
        variables.transportation_id = 'f3';
      } else {
        if (props.match.params.id) {
          variables.transportation_id = props.match.params.id;
        } else {
          variables.transportation_id = '';
        }
      }
      variables.tour_id = props.match.params.parentid; // always must send the tourid

      const optionsValues = { variables };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  // below block discontinued, not used
  graphql(defQls.getTransportationCustomers, {
    name: 'getTransportationCustomers2',
    options: (props) => {
      const transid = resolvePathObj(
        props,
        'myState.form.transportationForm.values.transportation_id2',
        { notFound: null }
      );
      let variables = { _qlType: 'View' };
      if (transid) {
        variables.transportation_id = transid;
      } else {
        variables.tour_id = props.match.params.parentid;
      }
      const optionsValues = { variables };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.tour.View, {
    name: 'view_tour',
    options: (props) => {
      return {
        variables: { id: props.match.params.parentid, _qlType: 'View' },
        fetchPolicy: 'network-only',
      };
    },
  }),
  graphql(defQls.transportation.ListMini, {
    name: 'list_transportation',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = {
        _qlType: 'ListMini',
        tour_id: props.match.params.parentid,
      };

      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState; //withCrud (withState);  // hoc be at the end of another wrappers

export default ComponentFull;

import React, { Component, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';
import ShowIcon from '../icons/ShowIcon';
import { getHeaders } from '../../apolloClient';

import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

import {
  // fieldsValidator, // will use customized validator below
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
  tt,
} from '../../utils/commonutils';

import { fieldsValidator } from './helpersjob';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

import CustomersRecipients from './CustomersRecipients';
import ChannelsMessaging from './ChannelsMessaging';
import ChannelsMessagingSMS from './ChannelsMessagingSMS';

const tableCrud = 'ejobmail';
let nameForm = `${tableCrud}Form`;

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateTypeInput: 'hidden',
    };
    this.headers = getHeaders();

    delete this.headers['Accept'];
    delete this.headers['Content-Type'];

    this.propsUpload = {
      name: 'uploaded_file',
      action: `${window?.appConfig?.REACT_APP_GRAPHQL_URI}/fload`,
      headers: this.headers,
      onChange: this.handleChange,
    };
  }

  beforeUpload = (file) => {
    if (
      !'application/msword application/vnd.openxmlformats-officedocument.wordprocessingml.document application/vnd.ms-excel application/vnd.openxmlformats-officedocument.spreadsheetml.sheet image/jpeg image/png application/pdf'.includes(
        file.type
      )
    ) {
      //this.setState([...this.state]);
      this.props.showNotificationWithTimeout(
        //this.props.t('mobilsemError.LIMIT_UNEXPECTED_FILE::' + file.type),
        tt(this.props.t, 'mobilsemError.LIMIT_UNEXPECTED_FILE:: ' + file.type),
        3,
        6
      );
      return false;
    } else {
      // this.setState({ fileList: [...this.state.fileList, file] });
      const findDuplicate = this.props.formState.fileList.find((fileInfo) => {
        if (fileInfo.name === file.name) {
          return true;
        }
      });
      if (findDuplicate) {
        this.props.showNotificationWithTimeout(
          tt(this.props.t, 'mobilsemError.fileExists:: ' + file.name),
          3,
          6
        );
        return false;
      }
      return true;
    }
  };

  handleChange = (data) => {
    // console.log('info upload ', data.file.response);
    //console.log('bbbbbb');
    if (data.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (data.file.status === 'error') {
      this.props.showNotificationWithTimeout(
        this.props.t('mobilsemError.uploadFile'),
        3,
        6
      );
    } else if (data.file?.response?.success === false) {
      this.props.showNotificationWithTimeout(
        this.props.t(data.file.response.error),
        3,
        6
      );
    } else if (data.file.status === 'done') {
      this.props.showNotificationWithTimeout(
        this.props.t('info.vFileAdded'),
        false
      );
      this.props.updateFileList('add', data.file);
    }
  };

  getItemRender = () => {
    const files = [];
    if (this.props.crudAction === 'View') {
      const attachFiles = tryParseJSON(
        this.props.containerPropsForm?.crud_view_ejobmail?.ejobmail
          ?.attachfiles,
        []
      );
      attachFiles.map((fileName, index) => {
        files.push(
          <div style={{ padding: '4px', display: 'flex' }}>
            <div style={{ paddingLeft: '8px', fontSize: '13px' }}>
              {fileName}
            </div>
          </div>
        );
      });
    } else {
      if (this.props.formState.fileList) {
        this.props.formState.fileList.map((fileInfo, index) => {
          if (fileInfo.response?.success === true) {
            files.push(
              <div style={{ padding: '4px', display: 'flex' }}>
                <div>
                  <button
                    onClick={() => this.props.updateFileList('delete', index)}
                  >
                    <ShowIcon size="12" icon="close" color="darkred" />
                  </button>
                </div>
                <div style={{ paddingLeft: '8px', fontSize: '13px' }}>
                  {fileInfo.response.filename}
                </div>
              </div>
            );
          }
        });
      }
    }

    return files;
  };

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 2000);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }

    //window.removeEventListener('message', this.readMessage, true);
  }

  /*readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };*/
  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,

      ...otherProps
    } = this.props;

    let messageType = this.props.messageType;
    if (this.props.formState.actionsave_origen !== 'add') {
      // resolve type message
      if (this.props?.initialValues?.sms) {
        messageType = 'SMS';
      } else {
        messageType = 'TXT';
      }
    }
    const itemRender = this.getItemRender(this.props.formState);
    const listChannels = [{ id: 20, name: 'form.sendOnlySMS' }];

    return (
      <React.Fragment>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="appBodyTitleSeparator" />
          <div className="columnBigContainer">
            <div className="columnContainerLeft">
              <div className="formSection">
                <Field
                  inputName="customer_id"
                  name="customer_id"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.customer_id"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="selectAutocomplete"
                  tableCrud={tableCrud}
                  typeInput="selectAutocomplete"
                  disabled={this.props.id ? true : false}
                  mustOnChange={true}
                />
              </div>

              <div className="formSection">
                <Field
                  inputName="channelsfilters"
                  name="channelsfilters"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.channelsfilters"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  listOptions={messageType === 'SMS' ? listChannels : null}
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="selectBox"
                  typeInput="selectBox"
                  onChange={(event, newValue, previousValue) => {
                    this.props.executeCode('onChangeInput', {
                      nameForm,
                      inputFullName: 'channelsfilters',
                      formProps: this.props,
                      formState: this.props.formState,
                      event,
                      newValue,
                      previousValue,
                    });
                  }}
                />
              </div>

              <div className="formSection" style={{ maxWidth: '650px' }}>
                <Field
                  inputName="tour_id"
                  name="tour_id"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.tour_id"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="selectAutocomplete"
                  tableCrud={tableCrud}
                  typeInput="selectAutocomplete"
                  disabled={true}
                  mustOnChange={true}
                />
                <Field
                  inputName="withEmail"
                  name="withEmail"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.withEmail"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  type="hidden"
                  typeInput="hidden"
                />
                <Field
                  inputName="withMobile"
                  name="withMobile"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.withMobile"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  type="hidden"
                  typeInput="hidden"
                />
                <Field
                  inputName="limitexceeded"
                  name="limitexceeded"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.limitexceeded"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  type="hidden"
                  typeInput="hidden"
                />
                <Field
                  inputName="totalMessaging"
                  name="totalMessaging"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.totalMessaging"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="hidden"
                  typeInput="hidden"
                />
                {messageType != 'SMS' && (
                  <Field
                    inputName="confirmationstatus_id"
                    name="confirmationstatus_id"
                    formProps={this.props}
                    pathInTables="ejobmail.fields.confirmationstatus_id"
                    formState={this.props.formState}
                    nameForm="ejobmailForm"
                    component={FormField}
                    executeCode={this.props.executeCode}
                    syncCode={this.props.syncCode}
                    type="selectAutocomplete"
                    tableCrud={tableCrud}
                    typeInput="selectAutocomplete"
                  />
                )}
                <Field
                  inputName="state"
                  name="state"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.state"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="selectBox"
                  typeInput="selectBox"
                  disabled={this.props.id ? true : false}
                  listSource="processBaseStatus"
                />
              </div>
              <Field
                inputName="customersrecipients"
                name="customersrecipients"
                formProps={this.props}
                pathInTables="ejobmail.fields.customersrecipients"
                formState={this.props.formState}
                nameForm="ejobmailForm"
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="hidden"
                typeInput="hidden"
              />
              <Field
                inputName="templatedata"
                name="templatedata"
                formProps={this.props}
                pathInTables="ejobmail.fields.templatedata"
                formState={this.props.formState}
                nameForm="ejobmailForm"
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="text"
                typeInput="text"
              />
              <Field
                inputName="channel"
                name="channel"
                formProps={this.props}
                pathInTables="ejobmail.fields.channel"
                formState={this.props.formState}
                nameForm="ejobmailForm"
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="hidden"
                typeInput="hidden"
              />
              <Field
                inputName="sent"
                name="sent"
                formProps={this.props}
                pathInTables="ejobmail.fields.sent"
                formState={this.props.formState}
                nameForm="ejobmailForm"
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="text"
                typeInput="text"
                disabled={true}
              />
              {messageType != 'SMS' && (
                <ChannelsMessaging
                  {...this.props}
                  containerState={this.state}
                  formProps={this.props}
                  formState={this.props.formState}
                  executeCode={this.props.executeCode}
                  t={t}
                />
              )}
              {messageType == 'SMS' && (
                <ChannelsMessagingSMS
                  {...this.props}
                  containerState={this.state}
                  formProps={this.props}
                  formState={this.props.formState}
                  executeCode={this.props.executeCode}
                  t={t}
                />
              )}

              <div style={{ marginBottom: '12px', marginTop: '20px' }}>
                {itemRender}
              </div>
              {messageType != 'SMS' && this.props.crudAction !== 'View' && (
                <div style={{ width: '100%', marginBottom: '20px' }}>
                  <Upload
                    {...this.propsUpload}
                    beforeUpload={this.beforeUpload}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t('form.addAttachFile')}
                    </Button>
                  </Upload>
                </div>
              )}
              {this.props.containerPropsForm.containerState && (
                <CustomersRecipients
                  {...this.props}
                  containerState={this.state}
                  messageType={messageType}
                  formProps={this.props}
                  formState={this.props.formState}
                  executeCode={this.props.executeCode}
                  t={t}
                />
              )}
            </div>

            <div className="columnContainerRight">
              <Field
                inputName="etemplate_id"
                name="etemplate_id"
                formProps={this.props}
                pathInTables="ejobmail.fields.etemplate_id"
                formState={this.props.formState}
                nameForm="ejobmailForm"
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="selectAutocomplete"
                tableCrud={tableCrud}
                typeInput="selectAutocomplete"
                disabled={this.props.id ? true : false}
                mustOnChange={true}
              />
              {messageType != 'SMS' && (
                <Field
                  inputName="title"
                  name="title"
                  formProps={this.props}
                  pathInTables="ejobmail.fields.title"
                  formState={this.props.formState}
                  nameForm="ejobmailForm"
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="text"
                  typeInput="text"
                  onChange={(event, newValue, previousValue) => {
                    this.props.executeCode('onChangeInput', {
                      nameForm,
                      inputFullName: 'title',
                      formProps: this.props,
                      formState: this.props.formState,
                      event,
                      newValue,
                      previousValue,
                    });
                  }}
                />
              )}
              {messageType != 'SMS' &&
                this.state.templateTypeInput === 'hidden' && (
                  <div
                    style={{
                      color: '#999999',
                      display: 'float',
                      alignItems: 'middle',
                    }}
                  >
                    <a
                      onClick={() => {
                        this.setState({ templateTypeInput: 'textarea' });
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div>
                          <ShowIcon icon="eye" color="#5f97c1" />
                        </div>
                        <div>
                          {t('info.vView')} {t('table.etemplate')}
                        </div>
                      </div>
                    </a>
                  </div>
                )}
              {messageType != 'SMS' && (
                <React.Fragment>
                  <Field
                    inputName="template"
                    name="template"
                    formProps={this.props}
                    pathInTables="ejobmail.fields.template"
                    formState={this.props.formState}
                    nameForm="ejobmailForm"
                    component={FormField}
                    executeCode={this.props.executeCode}
                    syncCode={this.props.syncCode}
                    type={this.state.templateTypeInput}
                    typeInput={this.state.templateTypeInput}
                    onChange={(event, newValue, previousValue) => {
                      this.props.executeCode('onChangeInput', {
                        nameForm,
                        inputFullName: 'template',
                        formProps: this.props,
                        formState: this.props.formState,
                        event,
                        newValue,
                        previousValue,
                      });
                    }}
                  />
                  <Field
                    inputName="body"
                    name="body"
                    formProps={this.props}
                    pathInTables="ejobmail.fields.body"
                    formState={this.props.formState}
                    nameForm="ejobmailForm"
                    component={FormField}
                    executeCode={this.props.executeCode}
                    syncCode={this.props.syncCode}
                    type="textarea"
                    typeInput="textarea"
                  />
                </React.Fragment>
              )}
              {messageType == 'SMS' && (
                <React.Fragment>
                  <Field
                    inputName="templatesms"
                    name="templatesms"
                    formProps={this.props}
                    pathInTables="ejobmail.fields.templatesms"
                    formState={this.props.formState}
                    nameForm="ejobmailForm"
                    component={FormField}
                    executeCode={this.props.executeCode}
                    syncCode={this.props.syncCode}
                    type="textarea"
                    typeInput="textarea"
                    onChange={(event, newValue, previousValue) => {
                      this.props.executeCode('onChangeInput', {
                        nameForm,
                        inputFullName: 'templatesms',
                        formProps: this.props,
                        formState: this.props.formState,
                        event,
                        newValue,
                        previousValue,
                      });
                    }}
                  />
                  <Field
                    inputName="sms"
                    name="sms"
                    formProps={this.props}
                    pathInTables="ejobmail.fields.sms"
                    formState={this.props.formState}
                    nameForm="ejobmailForm"
                    component={FormField}
                    executeCode={this.props.executeCode}
                    syncCode={this.props.syncCode}
                    type="textarea"
                    typeInput="textarea"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          <Field
            inputName="_formstate"
            name="_formstate"
            typeInput="hidden"
            component={FormField}
            label=""
          />

          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">{invalid && t('form.haserrors')}</div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate: fieldsValidator,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );

    // adapted to SMS/TXT
    const search = ownProps.containerPropsForm.history.location.search;
    const params = new URLSearchParams(search);
    if (ownProps.formState.dialogType === 'SMS') {
      initialValues['channelsfilters'] = 20;
    } else {
      initialValues['channelsfilters'] = 7;
    }

    //props.formState.dialogType;
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

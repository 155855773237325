import {
  getInputValue,
  dateStandard,
  tryParseJSON,
} from '../../utils/commonutils';
import { date as dateInvalid } from '../../utils/validators';
import { fetchQl } from '../../apolloClient';
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    formState,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
  } = params;

  const result = {};

  let newStates = {
    disabledFields: {},
    hiddenFields: {},
    warningFields: {},
    errors: {},
  };
  // default case tour is empty
  let resTourCustomerStatusAndServices = [];

  const realTourId = formProps.containerPropsForm.match.params.parentid;
  const realcustomerStatusId = formProps.containerPropsForm.match.params.id;
  if (action === 'initForm') {
    // default case tour is empty

    let resTourRooms = [];
    let resTourServices = [];

    resTourCustomerStatusAndServices = await fetchQl(
      `
        query getTourCustomerStatusAndServices($tour_id: ID, ,  $customerstatus_id: ID,  $_qlType: String) {
          getTourCustomerStatusAndServices(tour_id: $tour_id,  customerstatus_id: $customerstatus_id,   _qlType: $_qlType) {
            id
            name
            nameOnly
            tourprice_id
            tourprice_price
            substatuss {
              id
              name
              price
              inputprice
              filterbyhotel
              filteredhotels
              filterbyservice
              filteredservices
              availablehotels {
                id
                name
                priceroom
              }
              availableservices {
                id
                idOnly
                name
                nameOnly
                typeEntry
                tour_id
                operator
                amount
                listOptions {
                  id
                  name
                  amount
                }
                cancellednotzero
                service_id
              }
            }
            filterbyhotel
            filteredhotels
            filterbyservice
            filteredservices
            availablehotels {
              id
              name
              priceroom
            }
            availableservices {
              id
              idOnly
              name
              nameOnly
              typeEntry
              tour_id
              operator
              amount
              listOptions {
                id
                name
                amount
              }
              cancellednotzero
              service_id
            }
          }
        }
      `,
      [
        { name: 'tour_id', type: 'ID', value: realTourId },
        { name: 'customerstatus_id', type: 'ID', value: realcustomerStatusId },
        { name: '_qlType', type: 'String', value: 'ListMini-price-' },
      ],
      {
        dataName: 'getTourCustomerStatusAndServices',
        props: formProps, // important object that contains myState , for pelemaster resolver org
      }
    );

    newStates._resTourCustomerStatusAndServices =
      resTourCustomerStatusAndServices;

    const customerStatus = resTourCustomerStatusAndServices.find(
      (resTourStat) => resTourStat.id === realcustomerStatusId
    );
    const idInputPrice = `tourprice_price__${realcustomerStatusId}`;
    const idInputFilteredServices = `filteredservices__${realcustomerStatusId}`;
    const idInputFilteredHotels = `filteredhotels__${realcustomerStatusId}`;

    formProps.change(idInputPrice, customerStatus.tourprice_price);

    formProps.change(
      idInputFilteredServices,
      tryParseJSON(customerStatus.filteredservices, [])
    );
    formProps.change(
      idInputFilteredHotels,
      tryParseJSON(customerStatus.filteredhotels, [])
    );

    let substatuss = tryParseJSON(customerStatus.substatuss, []);
    substatuss.map((substatus) => {
      // twin CSUB1401

      const idInputSubstatusPrice = `tourprice_price__substatus_${customerStatus.id}_${substatus.id}`;
      const idInputSubstatusFilteredServices = `tourprice_filteredservices__substatus_${customerStatus.id}_${substatus.id}`;
      const idInputSubstatusFilteredHotels = `tourprice_filteredhotels__substatus_${customerStatus.id}_${substatus.id}`;
      formProps.change(idInputSubstatusPrice, substatus.inputprice);
      formProps.change(
        idInputSubstatusFilteredServices,
        tryParseJSON(substatus.filteredservices, [])
      );
      formProps.change(
        idInputSubstatusFilteredHotels,
        tryParseJSON(substatus.filteredhotels, [])
      );
    });
  }

  let onlineregtype;
  if (inputFullName === 'onlineregtype') {
    onlineregtype = newValue;
  } else {
    // need always validad , since load // // if ( action!== 'initForm') {
    onlineregtype = getInputValue(formProps, 'onlineregtype', { nameForm });
  }
  let tourcategory_id;
  if (inputFullName === 'tourcategory_id') {
    tourcategory_id = newValue;
  } else {
    // need always validad , since load // if ( action!== 'initForm') {
    tourcategory_id = getInputValue(formProps, 'tourcategory_id', { nameForm });
  }
  let registrationstart;
  if (inputFullName === 'registrationstart') {
    registrationstart = newValue;
  } else {
    // need always validad , since load // if ( action!== 'initForm') {
    registrationstart = getInputValue(formProps, 'registrationstart', {
      nameForm,
    });
  }
  const registrationstartStandard = dateStandard(registrationstart);

  let registrationend;
  if (inputFullName === 'registrationend') {
    registrationend = newValue;
  } else {
    // need always validad , since load // if ( action!== 'initForm') {
    registrationend = getInputValue(formProps, 'registrationend', { nameForm });
  }
  const registrationendStandard = dateStandard(registrationend);

  let datestart;
  if (inputFullName === 'datestart') {
    datestart = newValue;
  } else {
    datestart = getInputValue(formProps, 'datestart', { nameForm });
  }
  const datestartStandard = dateStandard(datestart);

  let dateend;
  if (inputFullName === 'dateend') {
    dateend = newValue;
  } else {
    dateend = getInputValue(formProps, 'dateend', { nameForm });
  }
  const dateendStandard = dateStandard(dateend);

  /// dont need validate , partial required for datestart, or dateend, because the default is required, so
  //  show errors only if is incomplet, only show error when there is error in range

  // IMPORTANTE USE dateInvalid , before check date, because when is typing date is not valid, so dont show errors
  if (
    !dateInvalid(datestart, false) &&
    !dateInvalid(dateend, false) &&
    datestart &&
    dateend
  ) {
    if (dateend < datestart) {
      newStates.errors.dateend = 'info.dateEndGreaterStart';
    }
  }

  if (
    !dateInvalid(registrationstart, false) &&
    !dateInvalid(registrationend, false) &&
    registrationstart &&
    registrationend
  ) {
    if (registrationendStandard < registrationstartStandard) {
      newStates.errors.registrationend = 'info.dateEndGreaterStart';
    }
  }

  if (registrationstart && !registrationend) {
    newStates.errors.registrationend = 'validator.required';
  }
  if (registrationend && !registrationstart) {
    newStates.errors.registrationstart = 'validator.required';
  }

  if (!dateInvalid(registrationstart) && !dateInvalid(datestart)) {
    if (registrationstartStandard > datestartStandard) {
      newStates.errors.registrationstart = 'info.dateRegEndGreaterStart';
    }
  }
  if (!dateInvalid(registrationend) && !dateInvalid(datestart)) {
    if (registrationendStandard > datestartStandard) {
      if (!newStates.errors.registrationstart) {
        // dont show double error, enought one field
        newStates.errors.registrationend = 'info.dateRegEndGreaterStart';
      }
    }
  }

  // console.log('onlineregtype, tourcategory_id', onlineregtype, tourcategory_id);
  // validate dates, or when the date start or end is contradictoire , update the another date
  // dont needed validate datestat && datend with custom messages because  validaor  'required' is the default
  if (
    onlineregtype &&
    (!tourcategory_id ||
      !registrationstart ||
      !registrationend ||
      !datestart ||
      !dateend)
  ) {
    if (!registrationstart) {
      newStates.errors.registrationstart = 'validator.required';
    }
    if (!registrationend) {
      newStates.errors.registrationend = 'validator.required';
    }
    newStates.errors.onlineregtype = 'info.onlineregtype';
  }
  result.newStates = newStates;
  return result;
};

export default crudCode;

import React, { Component } from 'react';

import { Field, reduxForm } from 'redux-form';
import { Button } from '../../layouts/cssstyled';
import ShowIcon from '../icons/ShowIcon';

import {
  fieldsValidator,
  processError,
  processNewValues,
  processValues,
} from '../../utils/helper';
import { resolvePathObj, getInputValue } from '../../utils/commonutils';
import FormField from '../FormField';
import { Tables } from '../../defTables';
import { connect } from 'react-redux';
import LaunchRichText from '../slatejs/LaunchRichText';

const nameForm = 'formSettings';

const fields = {};

const validate = (values, props) => {
  let result = fieldsValidator(fields, values);
  /*
   if (Object.keys(result).length === 0 ) {
     if (values.newpass !== values.newpass2 ) {
      result.newpass2 = t('mobilsemError.newpassDiff');
     }
   }
  */
  return result;
};

/*
          <Field
            inputName="registration_tip"
            name="registration_tip"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label= "form.tip"
          />

 */
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: {},
    };
  }
  toolbarFunctions = (toolbar, params = {}) => {
    if (toolbar === 'gcu') {
      this.setState({
        dialogOpened: { ...this.state.dialogOpened, RichText: true },
        defaultValues: {
          /*customer_id: null,
            tour_id: tour_id,*/
        },
      });
    }

    if (toolbar === 'closeDialog') {
      let dialogOpenedNew = this.state.dialogOpened;
      if (dialogOpenedNew[params.dialogName]) {
        delete dialogOpenedNew[params.dialogName];
        this.setState({ dialogOpened: dialogOpenedNew });
      }
    }
  };

  render() {
    const textInitValue = getInputValue(this.props, 'textgcu', { nameForm });

    let titleStyle = {
      marginTop: '3px',
      fontSize: '23px',
      color: '#666666',
      marginBottom: '10px',
    };
    let subtitleStyle = {
      marginTop: '15px',
      fontSize: '19px',
      color: '#474747',
      marginBottom: '10px',
    };
    const { error, pristine, invalid, submitFailed, submitting, t } =
      this.props;
    const { handleSubmit } = this.props;
    //console.log('props childe', this.props);
    return (
      <form onSubmit={handleSubmit}>
        <div key="maintitle">
          <div style={{ color: '#969696', fontSize: '16px' }}>
            {t('info.refreshToSee')}
          </div>
        </div>

        <div className="formRow">
          <div style={{ position: 'relative', margin: 'auto' }}>
            <div className="iconInput">
              <ShowIcon size="25" color="#ffffff" icon="saveblack" />
            </div>
            <Button
              type="submit"
              disabled={submitting}
              style={{ paddingLeft: '35px' }}
            >
              {t('form.save')}
            </Button>
          </div>
        </div>

        <div>
          <div className="appBodySubTitle">
            <div style={subtitleStyle}>{t('form.customerForm')}</div>
          </div>
        </div>

        <div
          key="customer"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <Field
            inputName="customer_nationality"
            name="customer_nationality"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.nationality"
          />

          <Field
            inputName="customer_residence"
            name="customer_residence"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.residence"
          />
          <Field
            inputName="customer_fullnameparent1"
            name="customer_fullnameparent1"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.fullnameparent1"
          />
          <Field
            inputName="customer_fullnameparent2"
            name="customer_fullnameparent2"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.fullnameparent2"
          />
          <Field
            inputName="customer_socialsn"
            name="customer_socialsn"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.socialsn"
          />
          <Field
            inputName="customer_extrafieldstring1"
            name="customer_extrafieldstring1"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.otherinfo"
          />
        </div>

        <div>
          <div className="appBodySubTitle">
            <div style={subtitleStyle}>{t('table.transportation')}</div>
          </div>
        </div>

        <div
          key="transp"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <Field
            inputName="transport_seats"
            name="transport_seats"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.seatingconfiguration"
          />
        </div>
        <div>
          <div className="appBodySubTitle">
            <div style={subtitleStyle}>{t('form.inscriptionForm')} Online</div>
          </div>
        </div>
        <div
          key="registration"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <div className="formSection">
            <Field
              inputName="gcu"
              name="gcu"
              formProps={this.props}
              component={FormField}
              type="radio"
              typeInput="radio"
              label="form.gcu"
              listOptions={[
                { id: '', name: 'form.bytext' },
                { id: 2, name: 'form.bylink' },
              ]}
              styleInput={{
                fontSize: '17px',
                color: '#555555',
                padding: '7px 0px 7px 0px',
              }}
              inLine={true}
              itemShow="'row"
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ height: '33px' }}>&nbsp;</div>
              <div>
                <a
                  onClick={() => {
                    this.toolbarFunctions('gcu');
                  }}
                >
                  <ShowIcon icon="create" color="#222222" />
                </a>
                {this.state.dialogOpened.RichText && (
                  <LaunchRichText
                    key="LaunchRichText"
                    {...this.props}
                    ql="setOrgSettings"
                    nameForm={nameForm}
                    nameField="textgcu"
                    id={this.props.id}
                    textInitValue={textInitValue}
                    containerState={this.state}
                    toolbarFunctionsContainer={this.toolbarFunctions}
                  />
                )}
              </div>
              <Field
                inputName="urlgcu"
                name="urlgcu"
                formProps={this.props}
                component={FormField}
                type="text"
                typeInput="text"
                width="500px"
                nolabel={true}
              />
              <Field
                key="textgcu"
                inputName="textgcu"
                name="textgcu"
                formProps={this.props}
                label="form.textgcu"
                formState={this.state}
                nameForm="formSettings"
                component={FormField}
                type="hidden"
                typeInput="hidden"
                width="400px"
              />
            </div>
          </div>
          <Field
            inputName="onlineterms"
            name="onlineterms"
            component={FormField}
            type="text"
            typeInput="text"
            width="500px"
            label="form.onlineterms"
          />
        </div>

        <div key="maintitle2">
          <div className="appBodySubTitle">
            <div style={subtitleStyle}>{t('orgModules.Mailing')}</div>
          </div>
        </div>
        <div
          key="mailing_windowenvelope"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <Field
            inputName="mailing_windowenvelope"
            name="mailing_windowenvelope"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.windowEnvelope"
          />
        </div>
        <div key="maintitle2">
          <div className="appBodySubTitle">
            <div style={subtitleStyle}>{t('table.export')}</div>
          </div>
        </div>
        <div
          key="export_age"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <Field
            inputName="export_age"
            name="export_age"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.age"
          />
          <Field
            inputName="export_nameseparated"
            name="export_nameseparated"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.nameSeparated"
          />
          <Field
            inputName="export_datecreation"
            name="export_datecreation"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.createdAt"
          />
          <Field
            inputName="export_transport_from_time"
            name="export_transport_from_time"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.from_time"
          />
          <Field
            inputName="export_inscriptionlast"
            name="export_inscriptionlast"
            component={FormField}
            type="hidden"
            typeInput="hidden"
            width="300px"
            showError="submitFailed"
            label="form.inscriptionlast"
          />
          <Field
            inputName="export_transportphones"
            name="export_transportphones"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            label="form.transportphones"
          />
          <Field
            inputName="export_payment_customerstatus"
            name="export_payment_customerstatus"
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            showError="submitFailed"
            labelNoTranslate="1"
            label={t('table.payments') + ': ' + t('table.customerstatus')}
          />
        </div>

        <div className="appBodySubTitle">
          <div style={subtitleStyle}>{t('form.accountingForm')}</div>
        </div>

        <div
          key="accounting"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <Field
            key="orgAccountingName"
            inputName="orgAccountingName"
            name="orgAccountingName"
            nameForm="formSettings"
            component={FormField}
            label="form.orgAccountingName"
            type="text"
            typeInput="text"
            width="400px"
          />
          <Field
            key="orgBankId"
            inputName="orgBankId"
            name="orgBankId"
            formProps={this.props}
            pathInTables="payment.fields.bank_id"
            formState={this.state}
            nameForm="formSettings"
            component={FormField}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            width="400px"
          />
          <Field
            key="orgBankAccountNumber"
            inputName="orgBankAccountNumber"
            name="orgBankAccountNumber"
            nameForm="formSettings"
            component={FormField}
            label="form.orgBankAccountNumber"
            type="text"
            typeInput="text"
            width="400px"
          />
          <Field
            key="inbank_paymentdocstatus_id"
            inputName="inbank_paymentdocstatus_id"
            name="inbank_paymentdocstatus_id"
            formProps={this.props}
            label="form.inbank_paymentdocstatus_id"
            pathInTables="payment.fields.paymentdocstatus_id"
            formState={this.state}
            nameForm="formSettings"
            component={FormField}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            width="400px"
          />
          <Field
            key="exportdetailtemplate"
            inputName="exportdetailtemplate"
            name="exportdetailtemplate"
            formProps={this.props}
            label="form.exportdetailtemplate"
            formState={this.state}
            nameForm="formSettings"
            component={FormField}
            type="text"
            typeInput="text"
            width="400px"
          />
        </div>
        <div key="toolbar" style={{ flexDirection: 'column' }}>
          <div className="formRow">
            <div style={{ position: 'relative', margin: 'auto' }}>
              <div className="iconInput">
                <ShowIcon size="25" color="#ffffff" icon="saveblack" />
              </div>
              <Button
                type="submit"
                disabled={submitting}
                style={{ paddingLeft: '35px' }}
              >
                {t('form.save')}
              </Button>
            </div>
          </div>
          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">
              {invalid && submitFailed && t('form.haserrors')}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const ReduxedForm = reduxForm({
  form: nameForm,
  validate,
  enableReinitialize: true, // the state is changed a lot of times so , need this for the last change on state when finally ql is loaded
})(Form);

function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values
  const statesReturn = { myState: state };
  const initialValues = {};
  const record = resolvePathObj(
    ownProps,
    'containerPropsForm.getOrgSettings.getOrgSettings.record'
  );
  if (typeof record !== 'undefined') {
    const values = JSON.parse(record);
    statesReturn.initialValues = {};
    //console.log('fields',ownProps.aFields);
    for (const [key, fieldSource] of Object.entries(ownProps.aFields)) {
      if (typeof resolvePathObj(values, key) !== 'undefined') {
        let [group, field] = key.split('.');
        /*console.log('key',key);
        console.log(group,field);*/
        if (field) {
          statesReturn.initialValues[field] = values[group][field];
        } else {
          statesReturn.initialValues[group] = values[group];
        }
      }
    }
  }
  return statesReturn;
}

const ComponentWithDataAndState = connect(mapStateToProps, null)(ReduxedForm);

export default ComponentWithDataAndState;

import React, { Component } from 'react';
import { getInputValue, getRoomInfo } from '../../utils/commonutils';

import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import FormField from '../FormField';

const qlName = 'crud_view_tourroom';

const nameForm = 'formRoomNumber';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listMode: 'rows',
      counter: 0,
      date: Date.now,
    };
  }

  executeCode = async (ligne, methodCode = '', params = {}) => {
    let roomnumbers = {};
    // numéro line
    for (let r = 1; r <= this.props[qlName].tourroom.room_quantity; r++) {
      let valueLigne;
      if (r === ligne) {
        valueLigne = params.newValue;
      } else {
        valueLigne = getInputValue(
          this.props,
          'roomnumber' + r,
          { nameForm },
          false
        );
      }
      roomnumbers[r] = valueLigne || null;
    }
    // console.log('roomnumbers', roomnumbers);
    this.props.dispatch(change('tourroomForm', 'room_numbers', roomnumbers));
  };

  render() {
    const { t, ...otherProps } = this.props;
    let aElements = [];

    if (
      this.props[qlName] &&
      this.props[qlName].tourroom &&
      this.props[qlName].tourroom.room_quantity
    ) {
      const bookings = this.props[qlName].tourroom.bookings
        ? JSON.parse(this.props[qlName].tourroom.bookings)
        : {};

      // && this.props[qlName] <- not needed, no error not loading then ok
      aElements.push(
        <div
          key="tit"
          className="row header"
          style={{ display: 'flex', padding: '5px', alignItems: 'center' }}
        >
          <div
            key="tita"
            style={{ width: '100px', padding: '10px', textAlign: 'right' }}
          >
            {t('form.defaultName')}
          </div>
          <div key="titb" style={{ width: '100px' }}>
            {t('form.customName')}
          </div>
          <div key="titc" style={{ padding: '10px' }}>
            {t('table.customers')}
          </div>
        </div>
      );

      for (let r = 1; r <= this.props[qlName].tourroom.room_quantity; r++) {
        // TWIN peleserver RO901
        const aCustomers = [];
        // loop for people same bed
        let bedcol = 2;
        let roomInfo = getRoomInfo(this.props[qlName].tourroom.room_type);
        for (let bedNumber = 0; bedNumber < roomInfo.bedsPerRoom; bedNumber++) {
          let customersBed = [];
          if (
            bookings['room' + r] &&
            bookings['room' + r].beds[bedNumber] &&
            bookings['room' + r].beds[bedNumber].customers
          ) {
            bookings['room' + r].beds[bedNumber].customers.map((customer) => {
              if (customer && customer.name) {
                // heart when qty double bed is < bednumber, double bed are always saved and showed first
                if (customersBed.length > 0) customersBed.push(<br />);
                customersBed.push(
                  <div key={customer.id}>
                    {customer.name}{' '}
                    {roomInfo.roombase_type.bedBase['2'] > bedNumber
                      ? ' ♡'
                      : ''}
                  </div>
                );
              }
            });
            aCustomers.push(customersBed);
          }
        }
        aElements.push(
          <div key={'f' + r} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              key={'l' + r}
              style={{ width: '100px', padding: '10px', textAlign: 'right' }}
            >
              {r}
            </div>
            <div key={'f' + r} style={{ width: '100px' }}>
              <Field
                label=""
                inputName={'roomnumber' + r}
                name={'roomnumber' + r}
                formProps={this.props}
                nameForm="userForm"
                component={FormField}
                type="text"
                typeInput="text"
                width="100px"
                onChange={(event, newValue, previousValue) => {
                  //if (this.props.executeCode)
                  this.executeCode(r, 'onChangeInput', {
                    inputFullName: 'roomnumber' + r,
                    formProps: this.props,
                    formState: this.state,
                    event,
                    newValue,
                    previousValue,
                  });
                }}
              />
            </div>
            <div key={'cd' + r} style={{ fontSize: '11px', padding: '10px' }}>
              {aCustomers}
            </div>
          </div>
        );
      }
    }

    return (
      <form>
        <div key="asdd">
          <div key="ddd" style={{ fontSize: '20px', color: '#999999' }}>
            {t('form.roomNumber')}
          </div>
          <div key="fdd">{aElements}</div>
        </div>
      </form>
    );
  }
}

const ReduxedForm = reduxForm({
  form: nameForm,
  enableReinitialize: true, // the state is changed a lot of times so , need this for the last change on state when finally ql is loaded
})(Form);

export default ReduxedForm;

import React, { Component } from 'react';
import { getSession } from '../utils/auth';
import Constants from '../utils/environment';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, infoError: '' };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    info.href = window.location.href;
    this.setState({ hasError: true, error: error, infoError: info });

    // TWIN SE8281 senderror
    const session = getSession();
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      version: window?.appConfig?.REACT_APP_VERSION,
      is: session.is,
    };
    let qlVars = { error: error.toString(), info: JSON.stringify(info) };
    console.log('vars', qlVars);
    headers['Authorization'] = 'Bearer ' + session.token;
    let query = `mutation setError ( $error: String, $info: String )   {   
      setError ( error: $error, info: $info ){
         answer
         errors {
            key,
            value
         }        
      }
    }
    `;
    const qlBody = JSON.stringify({ query, variables: qlVars });
    try {
      fetch(Constants.URI + '/graphql', {
        headers,
        method: 'POST',
        body: qlBody,
      });
    } catch (err) {
      console.error(err); // silent error because fetch error is not the real error
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Error in Client App.</h1>
          <div>{this.state.error.toString()}</div>
          <div>{JSON.stringify(this.state.infoError)}</div>
          <a href="/signedout" style={{ color: 'blue', size: '25px' }}>
            {' '}
            Connection{' '}
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}

import React from 'react';
import {
  getInputValue,
  getRoomInfo,
  resolvePathObj,
  getObjFromListById,
  tt,
  getNameHotelAndRoom,
} from '../../utils/commonutils';
import { change } from 'redux-form';
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  // const log = true;
  const log = false;
  if (log) console.log('onChangeInput params:', params);
  const {
    nameForm,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
    fns,
  } = params;

  const result = {};
  // important define inside warningFields, these reset field on initForm (executed after save too)
  // if not warning fields will persist, because logic warnings enter only if is not initForm
  // different to payment crud when
  let newStates = { warningFields: { room_type: [], room_quantity: [] } };

  let people_capacity;
  if (action === 'initForm') {
    const room_quantity = getInputValue(formProps, 'room_quantity', {
      nameForm,
    });

    const room_numbers = getInputValue(formProps, 'room_numbers', { nameForm });
    // console.log('room_quantity, room_numbers', room_quantity, room_numbers);
    if (room_quantity && room_numbers) {
      for (let r = 1; r <= parseInt(room_quantity); r++) {
        if (room_numbers[r]) {
          formProps.dispatch(
            change('formRoomNumber', 'roomnumber' + r, room_numbers[r])
          );
        }
      }
    }
    const name = getInputValue(formProps, 'name', { nameForm });
    const isfullname = getInputValue(formProps, 'isfullname', { nameForm });
    const hotel_id = getInputValue(formProps, 'hotel_id', { nameForm });
    const priceroom = getInputValue(formProps, 'priceroom', { nameForm });

    const hotel_name = getObjFromListById(
      formProps.containerPropsForm.list_hotel.hotels,
      hotel_id
    ).name;
    const nameHotelAndRoom = getNameHotelAndRoom(formProps.t, {
      name,
      isfullname,
      room_type: getInputValue(formProps, 'room_type', { nameForm }),
      tourroomhotel_id: { name: hotel_name },
      priceroom,
    });

    formProps.change('_name', nameHotelAndRoom);
  } else {
    let room_type;
    if (inputFullName === 'room_type') {
      room_type = newValue;
      newStates.warningFields.room_type = null;
    } else {
      room_type = getInputValue(formProps, 'room_type', { nameForm });
    }
    let name;
    if (inputFullName === 'name') {
      name = newValue;
    } else {
      name = getInputValue(formProps, 'name', { nameForm });
    }
    let isfullname;
    if (inputFullName === 'isfullname') {
      isfullname = newValue;
    } else {
      isfullname = getInputValue(formProps, 'isfullname', { nameForm });
    }
    let hotel_id;
    if (inputFullName === 'hotel_id') {
      hotel_id = newValue;
    } else {
      hotel_id = getInputValue(formProps, 'hotel_id', { nameForm });
    }
    let priceroom;
    if (inputFullName === 'priceroom') {
      priceroom = newValue;
    } else {
      priceroom = getInputValue(formProps, 'priceroom', { nameForm });
    }
    const hotel_name = getObjFromListById(
      formProps.containerPropsForm.list_hotel.hotels,
      hotel_id
    ).name;
    const nameHotelAndRoom = getNameHotelAndRoom(formProps.t, {
      name,
      isfullname,
      room_type: room_type ? parseInt(room_type) : null,
      tourroomhotel_id: { name: hotel_name },
      priceroom,
    });
    formProps.change('_name', nameHotelAndRoom);

    let room_quantity;
    if (inputFullName === 'room_quantity') {
      room_quantity = newValue;
    } else {
      room_quantity = getInputValue(formProps, 'room_quantity', { nameForm });
    }
    room_quantity = parseFloat(room_quantity) || 0;

    if (room_type) {
      let roomInfo = getRoomInfo(room_type);
      if (log) console.log('roomInfo ', roomInfo);
      people_capacity = room_quantity * roomInfo.peoplePerRoom;
    } else {
      people_capacity = 0;
    }

    const old_people_allocated = resolvePathObj(
      formProps,
      'myState.form.tourroomForm.initial.people_allocated'
    );
    const old_room_type = resolvePathObj(
      formProps,
      'myState.form.tourroomForm.initial.room_type'
    );

    const old_room_quantity = resolvePathObj(
      formProps,
      'myState.form.tourroomForm.initial.room_quantity'
    );

    let old_bookings = resolvePathObj(
      formProps,
      'myState.form.tourroomForm.initial.bookings'
    );

    /*
    compare if old values has changed
      for type of room, must reset all the previous bookings
      for less room_quantity, must to reset from room number n to last n
     */
    let people_registered = getInputValue(formProps, 'people_registered', {
      nameForm,
    });
    let people_pre_registered = getInputValue(
      formProps,
      'people_pre_registered',
      {
        nameForm,
      }
    );

    let people_allocated = getInputValue(formProps, 'people_allocated', {
      nameForm,
    });
    people_allocated = parseFloat(people_allocated) || 0;

    /*
    start possible changes bookings
     */
    if (
      typeof old_room_quantity !== 'undefined' &&
      typeof old_room_type !== 'undefined'
    ) {
      let customers;
      let bookings;
      //console.log('old_room_type, room_type', old_room_type, room_type)
      if (parseInt(old_room_type || 0) !== parseInt(room_type || 0)) {
        newStates.warningFields.room_type = [];
        newStates.warningFields.room_type.push(
          <div key="rt1">{'⚠️ ' + tt(formProps.t, 'info.changeTypeRoom')}</div>
        );
        newStates.warningFields.room_type.push(
          <div key="rt2">
            {tt(formProps.t, 'info.deleteAssignment::' + old_room_quantity)}
          </div>
        );
        people_allocated = 0; // reset
        customers = [];
        bookings = {};
      } else {
        newStates.warningFields.room_type = [];
        customers = [];
        bookings = {};
        // removed this from if below: room_quantity < old_room_quantity  &&
        // because user can play first changint to more or less qty, so the process
        // store always the new customers
        if (old_bookings) {
          /*
          there are less rooms then must unbook some rooms
           */
          const removeQty = old_room_quantity - room_quantity;
          let newqt = 0;
          let room_moved_quantity = 0;
          let room_fix_filled_quantity = 0; // counter for rooms with customers are found already filled
          let room_filled_progress = 0;
          let aRoomsEmpty = [];
          people_allocated = 0;
          // twin BORO311
          //console.log('old_bookings', old_bookings);
          //console.log('room_quantity', room_quantity);
          for (const [keyRoom, room] of Object.entries(old_bookings)) {
            newqt++;

            let customersFoundInRoom = [];
            //console.log('keyRoom, room',keyRoom, room);
            room.beds.map((bed) => {
              //console.log('bed',bed);
              if (bed.customers) {
                bed.customers.map((customer) => {
                  customersFoundInRoom.push({
                    id: customer.id,
                    room: keyRoom,
                    bedId: bed.id,
                  });
                });
              } else {
              }
            });

            if (newqt > room_quantity) {
              /*
              room exceeds qty, try look an empty room to move there the customers
              but need change keyRoom and bedId
               */
              //console.log('newqt > room_quantity ',newqt , room_quantity );
              if (
                aRoomsEmpty.length > 0 &&
                customersFoundInRoom.length > 0 &&
                room_filled_progress < room_quantity
              ) {
                room_moved_quantity++;
                room_filled_progress++;
                //console.log('room_moved_quantity, room_filled_progress ', room_moved_quantity, room_filled_progress );
                let keyRoomEmpty = aRoomsEmpty[0]; // use key room empty
                aRoomsEmpty.shift(); // remove key used
                let roomChanged = {
                  beds: [],
                  roomnumber: room_filled_progress,
                };
                let bedChanged = [];
                room.beds.map((bed, bednumber) => {
                  bedChanged = {
                    ...bed,
                    id: room_filled_progress * 1000 + bednumber,
                  };
                  roomChanged.beds.push(bedChanged);
                  /*
                  add customer list wit modified room and bedid
                   */
                  bed.customers.map((customer, indexcust) => {
                    people_allocated++;
                    customers.push({
                      id: customer.id,
                      room: keyRoomEmpty,
                      bedId: bed.id,
                    });
                  });
                });

                bookings[keyRoomEmpty] = roomChanged;
              }
            } else {
              /*
              qty room is ok on the range, but if room is empty , will add to be filled
               */
              if (customersFoundInRoom.length === 0) {
                aRoomsEmpty.push(keyRoom);
              } else {
                room_fix_filled_quantity++;
                room_filled_progress++;
                bookings[keyRoom] = room;
                //console.log('already filled', keyRoom);
                customersFoundInRoom.map((customer) => {
                  people_allocated++;
                  customers.push(customer);
                });
              }
            }
          }
          newStates.warningFields.room_quantity = [];
          if (room_quantity < old_room_quantity) {
            newStates.warningFields.room_quantity.push(
              <div key="rq1">
                {'⚠️ ' + tt(formProps.t, 'info.lessQtyInRoom')}
              </div>
            );
            newStates.warningFields.room_quantity.push(
              <div key="rq2">
                {tt(formProps.t, 'info.deleteRooms::' + removeQty)}
              </div>
            );
            newStates.warningFields.room_quantity.push(
              <div key="rq3">
                {tt(formProps.t, 'form.people_allocated')}:{' '}
                {tt(formProps.t, 'info.before').toLowerCase()}{' '}
                {old_people_allocated},{' '}
                {tt(formProps.t, 'info.after').toLowerCase()} {people_allocated}
              </div>
            );
          }
        } else {
          // there no changes qty
          newStates.warningFields.room_quantity = [];
        }
      }
      /*
      dont need update field customer, resolver in server update customer and
      update registrations
       */
      //if (customers) formProps.change('customers', customers);
      if (bookings) formProps.change('bookings', bookings);

      // update name
      if (inputFullName === 'room_type') {
        // console.log("change to", room_type);
        formProps.change('_name', room_type);
      }
    }
    /*
    end  possible changes bookings
     */

    //console.log('old_room_quantity, old_room_type, old_bookings', old_room_quantity, old_room_type, old_bookings );
    // people_available changes to people_to_allocate
    //

    let people_available =
      people_capacity - (people_registered || 0) - (people_pre_registered || 0);
    let people_to_allocate =
      (people_registered || 0) +
      (people_pre_registered || 0) -
      (people_allocated || 0);
    formProps.change('people_allocated', people_allocated);
    formProps.change('people_capacity', people_capacity);
    formProps.change('people_available', people_available);
    formProps.change('people_to_allocate', people_to_allocate);
  }
  result.newStates = newStates;
  //console.log('newStates', newStates);
  return result;
};

export default crudCode;

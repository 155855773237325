import React, { Component } from 'react';
import { useState } from 'react';
import {
  getInputValue,
  tryParseJSON,
  resolvePathObj,
} from '../../utils/commonutils';

const SubListCheck = (props) => {
  const { formProps, updatePageSettingsFields, t, nameForm, disabled } = props;
  const tableRows = resolvePathObj(
    props,
    'formProps.containerPropsForm.list_customerstatus.customerstatuss'
  );
  if (!tableRows) return null;
  // console.log("tableRows", tableRows);
  let aFields = [];

  const fieldsDB = tryParseJSON(
    getInputValue(formProps, 'filteredcustomerstatuss', { nameForm }),
    []
  );
  // console.log("fieldsDB", fieldsDB);

  for (const fieldSet of tableRows) {
    let fieldDB = fieldsDB.find((fieldId) => fieldId === fieldSet.id);
    // console.log("fieldDB", fieldDB);
    let keyName = fieldSet.id;
    let printCell = true;

    if (printCell) {
      aFields.push(
        <div
          key={keyName}
          style={{
            fontSize: '11px',
            minWidth: '300px',
            maxWidtht: '300px',
            margin: '2px',
            padding: '2px',
            backgroundColor: fieldDB ? '#EFEFEF' : null,
          }}
        >
          <input
            type="checkbox"
            name={keyName}
            id={keyName}
            style={{
              fontSize: '13px',
            }}
            checked={fieldDB ? true : false}
            onChange={(e) => updatePageSettingsFields(e)}
            disabled={disabled}
          />
          <label
            style={{
              fontSize: '13px',
              color: disabled ? '#cccccc' : null,
            }}
            htmlFor={keyName}
          >
            {fieldSet.name}
          </label>
        </div>
      );
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        padding: '10px',
        border: 'solid 1px #cccccc',
      }}
    >
      <div style={{ padding: '10px' }}>
        <div>
          <div style={{ color: disabled ? '#cccccc' : null }}>
            {t('table.customerstatus')}
          </div>
          <div
            key="cv"
            style={{
              fontSize: '11x',
              marginTop: '15px',
            }}
          >
            {aFields}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubListCheck;

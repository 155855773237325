import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

const tableCrud = 'paymentdocstatus';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) =>
  fieldsValidator('paymentdocstatus', values, props, nameForm);

class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />

        <div className="formSection">
          <Field
            inputName="name"
            name="name"
            formProps={this.props}
            pathInTables="paymentdocstatus.fields.name"
            formState={this.props.formState}
            nameForm="paymentdocstatusForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            autoFocus={this.props.formState.id ? '' : '1'}
            typeInput="text"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="statusbase"
            name="statusbase"
            formProps={this.props}
            pathInTables="paymentdocstatus.fields.statusbase"
            formState={this.props.formState}
            nameForm="paymentdocstatusForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'statusbase',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
            listSource="paymentDocBaseStatus"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="color"
            name="color"
            formProps={this.props}
            pathInTables="paymentdocstatus.fields.color"
            formState={this.props.formState}
            nameForm="paymentdocstatusForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="color"
            typeInput="color"
          />
        </div>
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        if (log) console.log('fieldKey', fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

import { getInputValue } from '../../utils/commonutils';
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  let log = false;
  if (log) console.log('onChangeInput params:', params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
  } = params;
};

export default crudCode;

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IntegratedCheckdepositdetList from '../checkdepositdet/IntegratedCheckdepositdetList';

import { graphql, compose } from 'react-apollo';
import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';
import { submit, change, touch, initialize } from 'redux-form';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from '../../utils/helper';

import Form from './CheckdepositheadForm';
import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from '../../actions/appActions';
import { showConfirm, hideConfirm } from '../../actions/confirmActions';

import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import IconLoading from '../icons/IconLoading';
import { MsgError } from '../ListData';
import { getInputValue, tt, getObjFromListById } from '../../utils/commonutils';

import RecordInfo from '../RecordInfo';
import RecordHeadInfo from '../RecordHeadInfo';
// for checkdepositdet
const QUERYDET = loader('../checkdepositdet/CheckdepositdetListPage.query.gql');
const formPagerName = 'checkdepositdetPager';
//
const crudCode = {};

const templates = [];

const tableCrud = 'checkdeposithead';
const action = 'view';
const nameForm = tableCrud + 'Form';
const formFilterName = 'listFiltercheckdeposithead';

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: 'update',
      actionsave_origen: 'update',
      crudAction: 'View',
      crudTable: tableCrud,
      mainForm: nameForm,
      id: this.props.match.params.id || '',
      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
    };
  }

  handleSubmit = async (values) => {
    try {
      if (
        this.state.warningFields &&
        this.state.warningFields._submit &&
        this.state.warningFields._submit.length
      ) {
        const validation = await preSubmitValidation(
          tableCrud,
          this.state.actionsave,
          this.props
        );
        if (!validation) return false;
        this.props.showConfirm({
          id: uuid(),
          type: 'confirmation',
          text: [
            ...this.state.warningFields._submit,
            <div key="usure">{this.props.t('form.uSureSave')}</div>,
          ],
          buttons: [
            {
              label: this.props.t('form.yes'),
              onClick: async () => await this.handleSubmitOk(values),
            },
            {
              label: this.props.t('form.no'),
              onClick: () => null,
            },
          ],
        });
      } else {
        return await this.handleSubmitOk(values);
      }
    } catch (err) {
      throw err;
    }
  };

  handleSubmitOk = async (values) => {
    let newSettings = this.props.myState.app.appSettings;

    try {
      const res = await submitValues(
        tableCrud,
        this.state.actionsave,
        this.props
      );
      if (res) {
        newSettings.checkdeposit = res.lastcheckdepositopen;

        const ql = `query getOrgSettings  {
            getOrgSettings   {
              record
              errors {
                key,
                value
              }
            }
        }`;

        const valuesFetch = await fetchQl(ql, [], {
          noProcessParams: true,
          props: this.props,
        });
        newSettings = JSON.parse(valuesFetch.data.getOrgSettings.record);

        this.props.appSettingsLoad(newSettings);

        this.setState(
          {
            actionsave: 'update',
            actionsave_origen: 'update',
          },
          async () => {
            await this.executeCode('onChangeInput', {
              action: 'initForm',
              nameForm,

              formProps: { ...this.props, containerPropsForm: this.props },
              formState: this.state,
              event: null,
              newValue: null,
            });
          }
        );
      } else {
        this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      }
      return res;
    } catch (err) {}
  };

  toolbarFunctions = (toolbar, params = {}) => {
    params = { id: this.props.match.params.id };
    if (toolbar.includes('download')) {
      this.props.goUrl('', {
        callback: async () => {
          const resCheck = await fetchQl(
            `
          mutation setExportChecks ($id: ID!) {
            setExportChecks  (id: $id) {
             answer
             errors {
                key,
                value
             } 
            }
          }`,
            [{ name: 'id', type: 'ID!', value: params.id }],
            {
              props: this.props,
            }
          );

          const nameFile = resCheck.data.setExportChecks.answer;
          let url = `${window?.appConfig?.REACT_APP_DOWN_URI}${nameFile}`;

          window.location.replace(url);
        },
      });
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  handleDeleteRecord = () => {
    const countChecks =
      this.props.crud_list_checkdepositdet.pagecheckdepositdets.nodes.length;
    if (countChecks > 0) {
      this.props.showNotificationWithTimeout(
        this.props.t('info.deletecheckdeposit'),
        10
      );
      return false;
    }

    deleteRecord(tableCrud, this.props);
  };

  syncCode = (method, params = {}) => {
    let valueToReturn;

    if (typeof valueToReturn !== 'undefined') return valueToReturn;
  };

  render() {
    const { t, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_view_checkdeposithead: { table: 'checkdeposithead' },
      crud_update_checkdeposithead: { table: 'checkdeposithead' },
      crud_add_checkdeposithead: { table: 'checkdeposithead' },
      crud_delete_checkdeposithead: { table: 'checkdeposithead' },
      crud_list_checkdepositdet: { table: 'checkdepositdet' },
      view_checkdeposithead: { table: 'checkdeposithead' }, // repetitivo but by now ok to dont
    };
    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        id={this.props.match.params.id}
      />
    );
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      if (
        this.props['crud_view_' + tableCrud] &&
        this.props['crud_view_' + tableCrud][tableCrud]
      ) {
        if (this.props['crud_view_' + tableCrud][tableCrud].deleted) {
          aElements.push(
            <RecordHeadInfo
              key="recordhead"
              tableCrud={tableCrud}
              {...this.props}
              data={this.props['crud_view_' + tableCrud][tableCrud]}
            />
          );
        }
      }
    }
    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar1"
        crudCode={crudCode}
        executeCode={this.executeCode}
      />
    );
    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: '100%', height: '600px' }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }
    if (this.state.actionsave === 'add') {
      aElements.push(
        <div key="addlegend" style={{ color: 'darkred' }}>
          {t('info.' + this.state.actionsave_origen)}
        </div>
      );
    }
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <Form
          executeCode={this.executeCode}
          syncCode={this.syncCode}
          toolbarFunctions={this.toolbarFunctions}
          crudAction="View"
          key="mainform"
          id={this.props.match.params.id}
          data={this.props['crud_view_' + tableCrud][tableCrud]}
          onSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          formState={this.state}
          t={this.props.t}
        />
      );
      aElements.push(
        <IntegratedCheckdepositdetList
          key="IC"
          {...this.props}
          containerState={this.state}
          toolbarFunctionsContainer={this.toolbarFunctions}
        />
      );
    }

    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar2"
        crudCode={crudCode}
        executeCode={this.executeCode}
      />
    );

    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <RecordInfo
          key="recordinfo"
          tableCrud={tableCrud}
          {...this.props}
          data={this.props['crud_view_' + tableCrud][tableCrud]}
        />
      );
    }

    return <div>{aElements}</div>;
  }
}

const withGraphqlandRouter = compose(
  graphql(defQls.checkdeposithead.View, {
    name: 'crud_view_checkdeposithead',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'View' },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.checkdeposithead.Update, {
    name: 'crud_update_checkdeposithead',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'Update' },
      };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.checkdeposithead.Add, {
    name: 'crud_add_checkdeposithead',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'no-cache';
      optionsValues.variables = { _qlType: 'Add' };
      return optionsValues;
    },
  }),
  graphql(defQls.checkdeposithead.Delete, {
    name: 'crud_delete_checkdeposithead',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'Delete' },
      };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  // from checkdepositdet

  graphql(QUERYDET, {
    name: 'crud_list_checkdepositdet',
    options: (props) => {
      let filters = getFilters(tableCrud, props, formPagerName, formFilterName);

      const optionsValues = {
        variables: {
          ...filters,
          checkdeposithead_id: props.match.params.id,
          _orders: 'datedeferredDesc',
          _qlType: 'ListPage-RelatedFull',
        },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
  }),

  graphql(defQls.checkdeposithead.View, {
    name: 'view_checkdeposithead',
    options: (props) => {
      return {
        variables: { id: props.match.params.id, _qlType: 'View' },
        fetchPolicy: 'network-only',
      };
    },
  }),
  graphql(defQls.setCheckDepositedDet, {
    name: 'setCheckDepositedDet',
    options: (props) => {
      const optionsValues = { variables: {} };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      appSettingsLoad,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;

// www.mobilsem.com  - Copyright .src/components/payment/PaymentForm.js
import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';
import { Button } from '../../layouts/cssstyled';
import ShowIcon from '../icons/ShowIcon';
import IconLoading from '../icons/IconLoading';
import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  getObjFromListById,
  getInputValue,
  dateToISO,
  sourcesOptions,
} from '../../utils/commonutils';
import { MsgError, ListDataRercord, ListDataHeader } from '../ListData';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';
import crudCode from './crudCode';

const tableCrud = 'payment';
const action = 'list';
const qlName = 'crud_list_' + tableCrud;
let table = Tables[tableCrud];
const nameForm = 'paymentList'; //distinct from payment
const validate = (values, props) => fieldsValidator('payment', values, props);

class Form extends Component {
  constructor(props) {
    super(props);
    // mix of states of crud list and crud view
    this.state = {
      clicked: false,
      // is really used? %%% it'semms no, anothers props too ,
      // to see .actionsave from formState container
      crudAction: props.crudAction,
      crudTable: tableCrud,
      id: props.id ? props.id : '',
      disabledFields: [],
      hiddenFields: [],
      listMode: 'rows',
      counter: 0,
      date: Date.now,
    };

    /*setTimeout(function() {
       this.executeCode ( 'onChangeInput', { action: 'initForm' , props: this.props, formProps: this.props, formState: this.state  });

    }.bind(this), 1000);
*/
  }

  getCheckDepositState = (
    inbank_paymentdocstatus_id,
    opencheckdeposit_id,
    record
  ) => {
    let checkdeposit;
    if (typeof opencheckdeposit_id === 'undefined') {
      // to don't get values again
      checkdeposit = resolvePathObj(
        this.props,
        'myState.app.appSettings.checkdeposit'
      );
      opencheckdeposit_id =
        typeof checkdeposit === 'undefined' ? null : checkdeposit;
      //console.log('checkdeposit_id', opencheckdeposit_id);
    }

    inbank_paymentdocstatus_id = resolvePathObj(
      this.props,
      'myState.app.appSettings.accounting.inbank_paymentdocstatus_id',
      { notFound: null }
    );
    if (inbank_paymentdocstatus_id && inbank_paymentdocstatus_id.id) {
      inbank_paymentdocstatus_id = inbank_paymentdocstatus_id.id;
    }

    let showCheckbox = false;

    if (record) {
      // default there is check if there a checkdeposit open
      if (!record.inactive && !record.checkdeposit_id && opencheckdeposit_id) {
        showCheckbox = true;
      }
      // only documents with use of datadeferred (to carry to the bank)
      if (
        record.paymentpaymentmethod_id &&
        !record.paymentpaymentmethod_id.usedatedeferred
      ) {
        showCheckbox = false;
      }

      // if status is closed then don't show payment
      if (
        (record.paymentpaymentdocstatus_id &&
          record.paymentpaymentdocstatus_id.statusbase === 11) ||
        (record.paymentdocstatus_id == inbank_paymentdocstatus_id &&
          inbank_paymentdocstatus_id)
      ) {
        showCheckbox = false;
      }
    }
    return { inbank_paymentdocstatus_id, opencheckdeposit_id, showCheckbox };
  };

  handleClick = (e) => {
    if (this.props.myState.app.submitting) {
      e.preventDefault();
      ////console.error('submit twice');
      return;
    }
    //console.log('props', this.props.myState.form.paymentList.values);
    const seletedPayment = [];
    // twin CHB1209
    // check if line exist (input hidden exist for every line , after check if checkbox was touched)
    let l = 0;
    while (
      typeof this.props.myState.form[nameForm].values['itemid' + l] !==
      'undefined'
    ) {
      if (
        typeof this.props.myState.form[nameForm].values['itemcheck' + l] !==
        'undefined'
      ) {
        seletedPayment.push({
          id: this.props.myState.form[nameForm].values['itemid' + l],
          ok: this.props.myState.form[nameForm].values['itemcheck' + l],
        });
      }
      l++;
    }

    let values = {
      payments: JSON.stringify(seletedPayment),
      source: 'payments',
    };

    if (!this.state.clicked) {
      // TWIN CTR231 ctrl is not saving this
      this.setState({ clicked: true }, async () => {
        this.props.appSubmitStart();
        await this.props.handleSubmit(values);
        this.props.appSubmitStop();
        this.setState({ clicked: false });
      });
    }
  };

  executeCode = async (methodCode = '', params = {}) => {
    //console.log('executeCode() methodCode:'+ methodCode + ', params:', params);
    if (!crudCode[methodCode]) {
      //do Nothing, silent, because can happens it's normal, crud have no definitions to actions , like onDisable,etc
      //alert ('error crudCode: '+methodCode+' not defined');
      return;
    }
    // important to getInputValue() or anothers where actions get complicated to pass values like this
    params.tableCrud = tableCrud;
    const result = await crudCode[methodCode](params);
    if (result && result.newStates) {
      // update states

      //console.log ('result.newStates',result.newStates);
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        //console.log('set state: '+keyName+' ,value: ',result.newStates[keyName]);
        this.setState({ [keyName]: result.newStates[keyName] });
      });
    }
    // check not for true, but undefined, false is a valid value to return
    if (result && typeof result.valueToReturn !== 'undefined') {
      // update states

      return result.valueToReturn;
    }
  };

  checkAll = ({ target }) => {
    let marked = target.checked;
    const listRecords =
      this.props.containerPropsForm.crud_list_payment.pagepayments.nodes;
    let line = 0;
    //pagecheckdepositdets.nodes
    const { inbank_paymentdocstatus_id, opencheckdeposit_id } =
      this.getCheckDepositState(undefined, undefined);
    listRecords.map((record) => {
      //dispatch(change('listFilter' + tableCrud, 'itemcheck'+ line, marked));
      let checkitem = getInputValue(this.props, 'itemid' + line);
      // TWIN CHP5686
      const { showCheckbox } = this.getCheckDepositState(
        inbank_paymentdocstatus_id,
        opencheckdeposit_id,
        record
      );
      if (showCheckbox) this.props.change('itemcheck' + line, marked);
      line++;
    });
  };

  render() {
    let result = this.props.result;

    // TWIN CHK209
    //console.log('myState.app.', this.props.myState.app);
    //console.log('inbank_paymentdocstatus_id',inbank_paymentdocstatus_id);

    //console.log(' state',this.state);
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;

    let aSubEle = [];

    const { inbank_paymentdocstatus_id, opencheckdeposit_id } =
      this.getCheckDepositState(undefined, undefined);

    // why button handlesubmit does not work? refresh all the page,  e.preventDefault(); does not work
    //  <Button contrast onClick={e => this.handleSubmit(e)}>{t('form.depositchecks')}</Button>
    const aInfoCheck = [];
    const checkdepositLast = resolvePathObj(
      this.props,
      'myState.app.appSettings.checkdepositLast',
      { notFound: null }
    );

    let aMixSettings;
    if (this.props.settings) {
      aMixSettings = { ...this.state, ...this.props.settings };
    } else {
      aMixSettings = { ...this.props.settings };
    }

    //console.log('checkdepositLast',checkdepositLast);
    if (checkdepositLast && checkdepositLast.name) {
      // checkdepositLast  can be {} // make sure is not empty
      if ((sourcesOptions.processTransaction, checkdepositLast.statusbase)) {
        // make sure is not undefined
        const checkState = t(
          'processTransaction.' +
            getObjFromListById(
              sourcesOptions.processTransaction,
              checkdepositLast.statusbase
            ).name
        );
        aInfoCheck.push(
          <div key="infocheck">
            {t('table.checkdeposithead')}: # {checkdepositLast.name} (
            {checkState}) {t('form.date')}: {dateToISO(checkdepositLast.date)}
          </div>
        );
      }
    } else {
      aInfoCheck.push(
        <div key="infocheck">{t('info.nocheckdeposithead')}</div>
      );
    }

    if (
      opencheckdeposit_id &&
      (!this.props.myState || !this.props.myState.app.submitting)
    ) {
      aInfoCheck.push(
        <div key="save1" style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <a
              key="save1"
              title={t('form.save')}
              onClick={(e) => this.handleClick(e)}
            >
              <ShowIcon size="35" color="#5995c3" icon="saveblack" />
            </a>
          </div>
          <div>{t('form.depositchecks')}</div>
        </div>
      );
    }

    aSubEle.push(
      <div key="btnC" style={{ marginBottom: '15px', alignItems: 'center' }}>
        {aInfoCheck}
      </div>
    );

    if (this.props.myState && this.props.myState.app.submitting) {
      /* a wrapper to get same size button save */
      aSubEle.push(
        <a key="loading">
          <IconLoading size="35" />
        </a>
      );
    }

    aSubEle.push(
      <ListDataHeader
        key="list"
        myState={this.props.myState}
        t={t}
        tableCrud={tableCrud}
        settings={aMixSettings}
        checkAll={this.checkAll}
        refresh={
          !Tables[tableCrud].pollInterval &&
          this.props.containerPropsForm[qlName]
            ? this.props.containerPropsForm[qlName].refetch
            : false
        }
      />
    );

    for (let r = 0; r < result.nodes.length; r++) {
      let xrecord = result.nodes[r];
      let virtualFields = {};

      /*
      reformat application / color
       */
      let style;
      if (xrecord.application) {
        let appl = JSON.parse(xrecord.application);
        appl.map((ap, key) => {
          if (!ap.customer_id) {
            appl[key].customer_id = { id: '', name: t('form.nocustomer') };
            style = { color: '#ca1237' };
          }
        });
        virtualFields.application = [...appl];
        if (style) {
          virtualFields.applicationStyle = style;
        }
      } else {
        virtualFields.application = [];
      }
      if (xrecord.aentry) {
        JSON.parse(xrecord.aentry).map((line) => {
          virtualFields.application.push({
            tour_id: {
              id: '',
              name:
                line.aplan.name +
                ' ' +
                (line.atitle || '') +
                ' ' +
                (line.reference || ''),
            },
            amount: line.amount,
          });
        });
      }

      let nextrecord =
        table.listSendNextRecord && r + 1 < result.nodes.length
          ? result.nodes[r + 1]
          : {}; // send next record if exists
      let item = (result.page._page - 1) * result.page._recordsperpage + r + 1;

      let record = { ...xrecord, ...virtualFields };
      // show checks only if payment have not deposit id
      //console.log('record.checkdeposit_id', record.name, record.checkdeposit_id);
      // last control before showcheckbox, if status doc payment is not deposited
      //console.log('paymentdocstatus_id',record.paymentpaymentdocstatus_id);
      // //paymentpaymentdocstatus_id

      // TWIN CHP5686
      const { showCheckbox } = this.getCheckDepositState(
        inbank_paymentdocstatus_id,
        opencheckdeposit_id,
        record
      );

      aSubEle.push(
        <ListDataRercord
          showCheckbox={showCheckbox}
          key={record.id}
          myState={this.props.myState}
          t={t}
          line={r}
          item={item}
          toolbarFunctions={this.toolbarFunctions}
          tableCrud={tableCrud}
          record={record}
          nextrecord={nextrecord}
          settings={aMixSettings}
        />
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        {aSubEle}
        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: 'paymentList', // a unique identifier for this form
  // TWIN FR141 very important , the values that are loading and changing  after form is created
  // so need reload the last variables in form
  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values
  const statesReturn = { myState: state };
  let initialValues = {};

  /*
  import assign id to multiple checks
   */
  const listRecords =
    ownProps.containerPropsForm.crud_list_payment.pagepayments.nodes;
  // records found pagecheckdepositdets are already from checkdeposithead opened

  let depositlist = resolvePathObj(
    ownProps,
    'containerPropsForm.crud_list_checkdepositdet.pagecheckdepositdets.nodes',
    { notFound: null }
  );
  //if (depositlist) console.log('depositlist', depositlist);
  let line = 0;
  //pagecheckdepositdets.nodes
  listRecords.map((record) => {
    initialValues['itemid' + line] = record.id;
    //console.log('itemid'+ line, record.id);
    if (depositlist) {
      let objPayFound = getObjFromListById(depositlist, {
        payment_id: record.id,
      });
      //console.log('objPayFound', objPayFound);
      if (objPayFound.id) initialValues['itemcheck' + line] = true;
    }
    line++;
  });

  statesReturn.initialValues = initialValues;
  return statesReturn;
}

const ComponentWithDataAndState = connect(mapStateToProps, {
  appSubmitStart,
  appSubmitStop,
})(ComponentWithData);

export default ComponentWithDataAndState;

// www.mobilsem.com  - Copyright .src/components/transportation/TransportationForm.js
import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormField from '../FormField';
import ShowIcon from '../icons/ShowIcon';
import IconLoading from '../icons/IconLoading';
import { fieldsValidator } from '../../utils/helper';
import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import {
  getRoomInfo,
  getOptions,
  getObjFromListById,
  processValues,
  resolvePathObj,
  getInputValue,
  realTypeOf,
  getJSONValue,
} from '../../utils/commonutils';

const crudCode = {};

const tableCrud = 'transportation';
const nameForm = 'transportationForm';

const validate = (values, props) =>
  fieldsValidator(tableCrud, values, props, nameForm);

let log = false;
class Form extends Component {
  constructor(props) {
    super(props);
    const sourceObject = 'transportation';
    const filterByObj = {};
    const listTransport = getOptions(sourceObject, this.props, {}, filterByObj);
    const listStopPointsDestination = [];
    listTransport.map((transp) => {
      let routeplanner = [];
      if (transp.routeplanner) {
        routeplanner = JSON.parse(transp.routeplanner);
      }
      if (routeplanner.length === 0) {
        // has no stop points
        listStopPointsDestination.push({
          id: transp.id,
          name: transp.name,
          stop: null,
        });
      } else {
        routeplanner.map((stop) => {
          listStopPointsDestination.push({
            id: transp.id,
            name: transp.name,
            stop: stop.from_stopspoint_id,
          });
        });
      }
    });
    const t = props.t;
    const listTransportDestination = [...listTransport];
    listTransportDestination.unshift({
      id: '',
      name: t('form.all'),
      stop: null,
    });
    listTransport.unshift({ id: 'f2', name: t('form.errorInTransport') });
    listTransport.unshift({ id: 'f1', name: t('form.noTransport') });
    listTransport.unshift({ id: '', name: t('form.stoppointNoTransport') });
    this.state = {
      listTransport,
      listTransportDestination,
      listStopPointsDestination,
      checks: {},
    };
    // this.updateBookings(props, 'constructor');
    setTimeout(
      function () {
        this.executeCode('onChangeInput', {
          action: 'initForm',
          props: this.props,
          formProps: this.props,
          formState: this.state,
        });
      }.bind(this),
      1000
    );
  }

  handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (log) console.log('event', value);
    this.setState((state) => ({
      bedid: value,
    }));
  };

  /*
  test direct mutate from form to apollo
  saveRoomsBooking = () => {
    console.log(' this.props.containerPropsForm', this.props.containerPropsForm);
    this.props.containerPropsForm.crud_update_transportation(
      {
        variables:
          {
            id: this.props.id,
            bookings: JSON.stringify(this.state.aBookings),
            tour_id: this.props.containerPropsForm.crud_view_transportation.transportation,
          },
      },
    )
    .then(() => {
      alert('ok');
    });
  };*/

  handleSubmit = () => {
    if (log) console.log('this.props', this.props);
    this.props.appSubmitStart();
    // only these fiels are retrived to initialValues on form
    this.props.change('bookings', this.state.aBookings);
    this.props.change('people_capacity', this.state.totalsRoom.peoplecapacity);
    this.props.change('people_allocated', this.state.totalsRoom.peoplebooked);
    this.props.change(
      'people_available',
      this.state.totalsRoom.peoplefreeplaces
    );
    setTimeout(
      function () {
        this.props.handleSubmit();
      }.bind(this),
      1000
    );
  };

  handleChangeCustomer = (transpid, stopidreal) => {
    const aStops = [];
    for (let [key, value] of Object.entries(this.state.checks)) {
      if (value) {
        const aSeparated = key.split('_');
        const stop = {};
        stop.target_transpid = transpid;
        stop.target_stopidreal = stopidreal;
        stop.registration_id = aSeparated[0];
        stop.registration_customerid = aSeparated[1];
        stop.registration_transplineid = aSeparated[2];
        stop.registration_transpid = aSeparated[3];
        stop.registration_stopid = aSeparated[4];
        aStops.push(stop);
      }
    }
  };

  executeCode = async (methodCode = '', params = {}) => {
    console.log(
      'yessss executeCode() methodCode:' + methodCode + ', params:',
      params
    );
    const {
      nameForm,
      popup,
      tableCrud,
      inputFullName,
      inputName,
      line,
      action,
      parentField,
      event,
      newValue,
      previousValue,
      props,
      formProps,
      formState,
    } = params;

    if (inputFullName === 'transportation_id1') {
      // customers transports
      this.setState({ checks: {} });
    }
  };

  handleCheckChieldElement = (event) => {
    // console.log('event', event.target.id, event.target.value, event.target.checked);
    const newValues = event.target.value.split('_');
    // check
    let alreadyforCustomer = false;
    for (const [key, value] of Object.entries(this.state.checks)) {
      // console.log(`${key}: ${value}`);
      const aKeys = key.split('_');
      const customer_id = aKeys[1];
      if (newValues[1] === customer_id && value && event.target.checked) {
        alert('repeated');
        alreadyforCustomer = true;
        break;
      }
    }
    // this logic is simple, last value, overwrite existint it, from first object
    if (!alreadyforCustomer)
      this.setState({
        checks: {
          ...this.state.checks,
          [event.target.value]: event.target.checked,
        },
      });
  };

  processCustomerTransport = (col) => {
    // col, now is always '1'
    const transportation_id = getInputValue(
      this.props,
      `transportation_id${col}`,
      { nameForm }
    );
    let aCustomers = [];
    if (
      !resolvePathObj(
        this.props,
        `containerPropsForm.getTransportationCustomers${col}.getTransportationCustomers`,
        { notFound: null }
      )
    )
      return [];

    const aRecords =
      this.props.containerPropsForm[`getTransportationCustomers${col}`]
        .getTransportationCustomers;
    if (aRecords) {
      aRecords.map((record) => {
        let aTransportations = getJSONValue(record.transportation); // transport can be null in field
        const cntTransports = [];
        if (aTransportations.length === 0) {
          aTransportations.push({
            transportation_id: null,
            stopspoint_id: null,
          });
        }
        aTransportations.map((trans, index) => {
          let checkId =
            record.registration_id +
            '_' +
            record.customer_id +
            '_' +
            trans.id +
            '_' +
            (trans.transportation_id && trans.transportation_id.id
              ? trans.transportation_id.id
              : '') +
            '_' +
            (trans.stopspoint_id && trans.stopspoint_id.id
              ? trans.stopspoint_id.id
              : '');
          let isChecked = false;
          if (this.state.checks[checkId]) isChecked = true;
          let transportationLineId =
            trans.transportation_id && trans.transportation_id.id
              ? trans.transportation_id.id
              : '';
          // transportation_id  can be '', when fist select option is choosed, is 'f3' only when is sent to the server with the call graphql
          let transportMatch =
            (transportationLineId === transportation_id &&
              transportation_id.length > 2) ||
            !transportation_id ||
            transportation_id.length < 3; // last <3, included '' for first option in select

          cntTransports.push(
            <div
              style={{
                display: 'flex',
                alignItems: 'top',
                textDecoration: !transportMatch ? 'line-through' : '',
                backgroundColor: transportMatch
                  ? isChecked
                    ? '#ccf2ff'
                    : 'white'
                  : '#dddddd',
              }}
              key={checkId}
            >
              <div style={{ width: '20px' }}></div>

              <div
                style={{ width: '250px', display: 'flex', alignItems: 'top' }}
              >
                <div style={{ marginRight: '7px' }}>
                  <ShowIcon
                    size="22"
                    color={transportationLineId ? '#cccccc' : 'red'}
                    icon="transportation"
                  />
                </div>
                <div style={{ color: '#777777' }}>
                  {transportationLineId
                    ? trans.transportation_id.name
                    : this.props.t('form.noTransport')}
                </div>
              </div>
              <div
                style={{
                  width: '250px',
                  display: 'flex',
                  alignItems: 'top',
                  fontSize: '14px',
                }}
              >
                <ShowIcon size="20" color="#cccccc" icon="stopspoint" />
                <div style={{ color: '#777777' }}>
                  {trans.stopspoint_id && trans.stopspoint_id.name}
                </div>
              </div>
              <div>
                {transportMatch && (
                  <input
                    name={'itemcheck' + index}
                    id={'itemcheck' + index}
                    style={{ fontSize: '16px' }}
                    type="checkbox"
                    value={checkId}
                    onChange={this.handleCheckChieldElement}
                    checked={isChecked}
                  />
                )}
              </div>
            </div>
          );
        });
        aCustomers.push(
          <div key={'cntc' + record.customer_id} style={{ marginTop: '9px' }}>
            <div
              key={record.customer_id + 'n'}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <div>
                <a
                  className="aToolBarExtend"
                  onClick={() =>
                    this.props.changePage(
                      `/registration-view/${record.registration_id}`
                    )
                  }
                >
                  <ShowIcon size="25" icon="registration" color="gray" />
                </a>
              </div>
              <div style={{ color: '#555555' }}>{record.name}</div>
            </div>
            {cntTransports}
          </div>
        );
      });
    }
    return aCustomers;
  };

  processTransportDestination = () => {
    const transportationDestinationId = getInputValue(
      this.props,
      `transportation_id2`,
      { nameForm }
    );

    const aRecords = this.state.listStopPointsDestination;
    let aDestinations = [];
    if (aRecords) {
      let i = 0;
      while (i < aRecords.length) {
        let record = aRecords[i];
        let transpId = record.id;
        let addTransp = true;
        if (
          transportationDestinationId &&
          record.id !== transportationDestinationId
        )
          addTransp = false;

        if (addTransp) {
          aDestinations.push(
            <div key={'cnts' + record.id}>
              <div
                key={record.id}
                style={{
                  display: 'flex',
                  alignItems: 'top',
                  color: '#444444',
                  marginTop: '9px',
                }}
              >
                <div style={{ marginTop: '-4px', marginRight: '3px' }}>
                  <ShowIcon size="25" color="#cccccc" icon="transportation" />
                </div>
                <div>{record.name}</div>
              </div>
            </div>
          );
        }

        while (i < aRecords.length && aRecords[i].id === transpId) {
          record = aRecords[i];
          if (record.stop && addTransp) {
            aDestinations.push(
              <div key={'cntr' + record.stop.id + i}>
                <div
                  key={'l' + record.stop.id}
                  style={{ display: 'flex', alignItems: 'top' }}
                >
                  <div key={record.stop.id + 't'} style={{ width: '25px' }}>
                    &nbsp;
                  </div>
                  <div style={{ marginRight: '-2px' }}>
                    <a
                      key="set1"
                      onClick={() =>
                        this.handleChangeCustomer(transpId, record.stop.id)
                      }
                    >
                      <ShowIcon
                        viewBox="-2 -2 24 24"
                        size="25"
                        color="orange"
                        icon="navleft"
                      />
                    </a>
                  </div>
                  <div
                    key={record.stop.id + 's'}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '300px',
                      color: '#555555',
                      fontSize: '14px',
                    }}
                  >
                    <ShowIcon size="20" color="#cccccc" icon="stopspoint" />
                    {record.stop.name}
                  </div>
                </div>
              </div>
            );
          }
          i++;
        }
      }
    }
    return aDestinations;
  };

  render() {
    const listTransport = this.state.listTransport;
    const listTransportDestination = this.state.listTransportDestination;

    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      t,
      ...otherProps
    } = this.props;

    let aCustomers = {};
    aCustomers['1'] = this.processCustomerTransport('1');
    // aCustomers['2'] = this.processCustomerTransport('2');

    const aTransportDestination = this.processTransportDestination();

    let subToolBar = [];

    if (this.props.myState && this.props.myState.app.submitting) {
      /* a wrapper to get same size button save */
      subToolBar.push(
        <a key="loading">
          <IconLoading size="35" />
        </a>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ color: 'red', fontSize: '18px' }}>
          Page en Construction
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ borderRight: 'dashed 1px #999999' }}>
            <Field
              inputName="transportation_id1"
              name="transportation_id1"
              formProps={this.props}
              listOptions={listTransport}
              pathInTables="registration.fields.transportation.subfields.transportation_id"
              formState={this.state}
              nameForm="transportationForm"
              component={FormField}
              label={t('table.customers') + ' - ' + t('table.transportations')}
              noPrefixOptionLabel={true}
              labelNoTranslate={true}
              executeCode={this.executeCode}
              disableValidatorRequired="1"
              type="selectBox"
              typeInput="selectBox"
              tableCrud={tableCrud}
              onChange={(event, newValue, previousValue) => {
                this.executeCode('onChangeInput', {
                  nameForm,
                  inputFullName: 'transportation_id1',
                  formProps: this.props,
                  formState: this.state,
                  event,
                  newValue,
                  previousValue,
                });
              }}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '250px', marginRight: '7px' }}>
                {aCustomers['1']}
              </div>
            </div>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <Field
              inputName="transportation_id2"
              name="transportation_id2"
              formProps={this.props}
              listOptions={listTransportDestination}
              pathInTables="registration.fields.transportation.subfields.transportation_id"
              formState={this.state}
              nameForm="transportationForm"
              component={FormField}
              noPrefixOptionLabel={true}
              label="table.transportation"
              executeCode={this.executeCode}
              disableValidatorRequired="1"
              type="selectBox"
              typeInput="selectBox"
              tableCrud={tableCrud}
              mustOnChange={true}
            />

            {/*

            old double transport
            <Field
              inputName="transportation_id2"
              name="transportation_id2"
              formProps={this.props}
              listOptions={listTransport}
              pathInTables="registration.fields.transportation.subfields.transportation_id"
              formState={this.state}
              nameForm="transportationForm"
              component={FormField}
              label="table.transportation"
              executeCode={this.executeCode}
              disableValidatorRequired="1"
              type="selectBox"
              typeInput="selectBox"
              tableCrud={tableCrud}
              mustOnChange = {true}
            />
            <div style = { {display: 'flex' } }>
              <div style= { { minWidth: '250px', marginRight:'7px'}}>
                {aCustomers['2']}
              </div>
            </div>*/}
            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '250px', marginRight: '7px' }}>
                {aTransportDestination}
              </div>
            </div>
          </div>
        </div>

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">
            {pristine}
            {invalid && t('form.haserrors')}
          </div>
        </div>
      </form>
    );
  }
}
//flexGrow: roomInfo.bedsPerRoom < 4 ? roomInfo.bedsPerRoom : 3
//fontSize: '19px', flexGrow: 1,  'marginTop':'30px'

const ComponentWithData = reduxForm({
  form: nameForm, // a unique identifier for this form
  enableReinitialize: false, // to false , because the form don't reload again from graphql, and it's need to work
  touchOnChange: true, // important for this case, manual .change must touche to be controlled by exit wihtout save logic
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values

  const statesReturn = { myState: state };
  if (ownProps.id) {
    // not for add component, just view

    const transid = resolvePathObj(
      state,
      'form.transportationForm.values.transportation_id1',
      { notFound: null }
    );
    // console.log('transid', transid, realTypeOf(transid));
    let values = processValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'view'
    );
    if (transid) {
      values.transportation_id1 = transid; // check input valiue
    } else {
      values.transportation_id1 = ownProps.id; // router path
    }
    //console.log('values', values);
    statesReturn.initialValues = values;
    /*
    %%% tried just initializee only for field will update this form
    but get warning change field after save, study to retrieve initivalvalues again or manually
       i think turn off enableReinitialize: true,  and get new values by hand
    statesReturn.initialValues = {
       id: values.id,
       bookings: values.booking,
       people_capacity: values.people_capacity,
       people_allocated: values.people_available,
    };*/
  }
  return statesReturn;
}

const ComponentWithDataAndState = connect(mapStateToProps, {
  appSubmitStart,
  appSubmitStop,
})(withRouter(ComponentWithData));

export default ComponentWithDataAndState;

import React, { Component } from 'react';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import { Button } from '../../layouts/cssstyled';
import ShowIcon from '../icons/ShowIcon';
import { getHeaders } from '../../apolloClient';
import {
  fieldsValidator,
  processError,
  processNewValues,
  processValues,
} from '../../utils/helper';
import { resolvePathObj } from '../../utils/commonutils';
import ComponentFull from './BankStatement';

const fields = {
  signaturesms: {
    validators: ['maxLength:65'],
  },
};

const validate = (values, props) => {
  const result = fieldsValidator(fields, values);
  return result;
};

class Form extends Component {
  postFile = async (event) => {
    console.log('file', event.target.files[0]);
    const imagefile = event.target.files[0];

    let formData = new FormData();
    formData.append('file', imagefile);
    let headers = getHeaders();
    delete headers['Content-Type'];
    delete headers.Accept;
    const res = await fetch(
      `${window?.appConfig?.REACT_APP_GRAPHQL_URI}/fload`,
      {
        method: 'POST',
        headers,
        body: formData,
      }
    );
    // hacer que la respuesta, se envie a la base de datos (asociadoa con el tipo de archivo, asi se usa la misma logica para imagenes o no), y en el submit se mueve desde el server, del archivo files,
    //  hacia otra destination (en forma encryptada? ) asi, se puede elminiar por mantenimiento de vez en cuando toda esa carpeta files
    //  que ocurre cuando se elige el archivo, pero se confirma cuando se envia el submit
    //  see more for to crypt: https://stackoverflow.com/questions/59043812/how-to-encrypt-an-uploaded-file-before-saving-to-disk-after-posting-to-express-r
    console.log('result', res.data);
  };

  render() {
    let titleStyle = {
      marginTop: '3px',
      fontSize: '23px',
      color: '#666666',
      marginBottom: '10px',
    };
    let subtitleStyle = {
      marginTop: '15px',
      fontSize: '19px',
      color: '#474747',
      marginBottom: '10px',
    };
    const { error, pristine, invalid, submitFailed, submitting, t } =
      this.props;
    const { handleSubmit } = this.props;
    //console.log('props childe', this.props);

    return (
      <form>
        <div
          key="accounting"
          className="formSection"
          style={{ flexDirection: 'column' }}
        >
          <input type="file" id="file" name="file" onChange={this.postFile} />
        </div>
        <div key="toolbar" style={{ flexDirection: 'column' }}>
          <div className="formRow">
            <div style={{ position: 'relative', margin: 'auto' }}>
              <div className="iconInput">
                <ShowIcon size="25" color="#ffffff" icon="saveblack" />
              </div>
              <Button
                type="submit"
                disabled={submitting}
                style={{ paddingLeft: '35px' }}
              >
                {t('form.save')}
              </Button>
            </div>
          </div>
          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">
              {invalid && submitFailed && t('form.haserrors')}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;

import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

import LinkedPersonMainData from './LinkedPersonMainData';
import LinkedPersonContacts from './LinkedPersonContacts';
import LinkedPersonPhones from './LinkedPersonPhones';
import MemberAddress from './MemberAddress';
import LinkedPersonAddresses from './LinkedPersonAddresses';
import LinkedPersonIdentitydocs from './LinkedPersonIdentitydocs';

const tableCrud = 'customer';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) =>
  fieldsValidator('customer', values, props, nameForm);

class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />

        <div className="formSection">
          <Field
            inputName="gendertype_id"
            name="gendertype_id"
            formProps={this.props}
            pathInTables="customer.fields.gendertype_id"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
          />
          <Field
            inputName="lastname"
            name="lastname"
            formProps={this.props}
            pathInTables="customer.fields.lastname"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.UpperCase}
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.hocdebounced('onChangeInput', {
                nameForm,
                inputFullName: 'lastname',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="firstname"
            name="firstname"
            formProps={this.props}
            pathInTables="customer.fields.firstname"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.StartCase}
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.hocdebounced('onChangeInput', {
                nameForm,
                inputFullName: 'firstname',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.modules.DOSSIERCUSTOMER',
            true
          ) && (
            <Field
              inputName="numbering"
              name="numbering"
              formProps={this.props}
              pathInTables="customer.fields.numbering"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          )}
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_extrafieldstring1',
            true
          ) && (
            <Field
              inputName="extrafieldstring1"
              name="extrafieldstring1"
              formProps={this.props}
              pathInTables="customer.fields.extrafieldstring1"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          )}
          <Field
            inputName="email"
            name="email"
            formProps={this.props}
            pathInTables="customer.fields.email"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.LowerCase}
            typeInput="text"
          />
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_socialsn',
            true
          ) && (
            <Field
              inputName="socialsn"
              name="socialsn"
              formProps={this.props}
              pathInTables="customer.fields.socialsn"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          )}
          <Field
            inputName="occupation"
            name="occupation"
            formProps={this.props}
            pathInTables="customer.fields.occupation"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="imageright"
            name="imageright"
            formProps={this.props}
            pathInTables="customer.fields.imageright"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="yesNoEmpty"
          />
          <Field
            inputName="nosharedata"
            name="nosharedata"
            formProps={this.props}
            pathInTables="customer.fields.nosharedata"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="nonewsletter"
            name="nonewsletter"
            formProps={this.props}
            pathInTables="customer.fields.nonewsletter"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="inactive"
            name="inactive"
            formProps={this.props}
            pathInTables="customer.fields.inactive"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_deceased',
            true
          ) && (
            <Field
              inputName="deceased"
              name="deceased"
              formProps={this.props}
              pathInTables="customer.fields.deceased"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="checkbox"
              typeInput="checkbox"
            />
          )}
        </div>
        <LinkedPersonMainData
          {...this.props}
          containerState={this.state}
          formProps={this.props}
          formState={this.props.formState}
          executeCode={this.props.executeCode}
          t={t}
        />
        <div className="formSection">
          <Field
            inputName="customerstatus_id"
            name="customerstatus_id"
            formProps={this.props}
            pathInTables="customer.fields.customerstatus_id"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            mustOnChange={true}
          />
          <Field
            inputName="customersubstatus_id"
            name="customersubstatus_id"
            formProps={this.props}
            pathInTables="customer.fields.customersubstatus_id"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            filterBy={['customerstatus_id']}
            typeInput="selectAutocomplete"
          />
        </div>
        <Field
          inputName="dietnotes"
          name="dietnotes"
          formProps={this.props}
          pathInTables="customer.fields.dietnotes"
          formState={this.props.formState}
          nameForm="customerForm"
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
        />
        <Field
          inputName="handicapnotes"
          name="handicapnotes"
          formProps={this.props}
          pathInTables="customer.fields.handicapnotes"
          formState={this.props.formState}
          nameForm="customerForm"
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
        />
        <div className="formSection">
          <Field
            inputName="grouped_customer_id"
            name="grouped_customer_id"
            formProps={this.props}
            pathInTables="customer.fields.grouped_customer_id"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
          />
          <Field
            inputName="grouped_filiation"
            name="grouped_filiation"
            formProps={this.props}
            pathInTables="customer.fields.grouped_filiation"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="filiation"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="birthdate"
            name="birthdate"
            formProps={this.props}
            pathInTables="customer.fields.birthdate"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="birthplace"
            name="birthplace"
            formProps={this.props}
            pathInTables="customer.fields.birthplace"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="birthcountry"
            name="birthcountry"
            formProps={this.props}
            pathInTables="customer.fields.birthcountry"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            listSource="countries"
          />
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_nationality',
            true
          ) && (
            <Field
              inputName="nationality"
              name="nationality"
              formProps={this.props}
              pathInTables="customer.fields.nationality"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="selectAutocomplete"
              typeInput="selectAutocomplete"
              listSource="countries"
            />
          )}
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_residence',
            true
          ) && (
            <Field
              inputName="residence"
              name="residence"
              formProps={this.props}
              pathInTables="customer.fields.residence"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="selectAutocomplete"
              typeInput="selectAutocomplete"
              listSource="countries"
            />
          )}
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_fullnameparent1',
            true
          ) && (
            <Field
              inputName="fullnameparent1"
              name="fullnameparent1"
              formProps={this.props}
              pathInTables="customer.fields.fullnameparent1"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          )}
          {resolvePathObj(
            this.props,
            'myState.app.appSettings.settingsforms.customer_fullnameparent2',
            true
          ) && (
            <Field
              inputName="fullnameparent2"
              name="fullnameparent2"
              formProps={this.props}
              pathInTables="customer.fields.fullnameparent2"
              formState={this.props.formState}
              nameForm="customerForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          )}
          <Field
            inputName="nameunmarried"
            name="nameunmarried"
            formProps={this.props}
            pathInTables="customer.fields.nameunmarried"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="contacts"
            name="contacts"
            formProps={this.props}
            pathInTables="customer.fields.contacts"
            formState={this.props.formState}
            nameForm="customerForm"
            fieldParentKey="contacts"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
            preComponentSet={{ position: 'insideUnderTitle' }}
            preComponent={LinkedPersonContacts}
          />
        </div>
        <div className="formSection">
          <Field
            inputName="smsdisabled"
            name="smsdisabled"
            formProps={this.props}
            pathInTables="customer.fields.smsdisabled"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
          <Field
            inputName="mobile"
            name="mobile"
            formProps={this.props}
            pathInTables="customer.fields.mobile"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
            disabled={true}
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="phones"
            name="phones"
            formProps={this.props}
            pathInTables="customer.fields.phones"
            formState={this.props.formState}
            nameForm="customerForm"
            fieldParentKey="phones"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
            preComponentSet={{ position: 'insideUnderTitle' }}
            preComponent={LinkedPersonPhones}
          />
          <Field
            inputName="customeraddress_id"
            name="customeraddress_id"
            formProps={this.props}
            pathInTables="customer.fields.customeraddress_id"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
          <Field
            inputName="customeraddress_customers"
            name="customeraddress_customers"
            formProps={this.props}
            pathInTables="customer.fields.customeraddress_customers"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="addresses"
            name="addresses"
            formProps={this.props}
            pathInTables="customer.fields.addresses"
            formState={this.props.formState}
            nameForm="customerForm"
            fieldParentKey="addresses"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
            labelChildsShow={true}
            preComponentSet={[
              { position: 'insideAfterTitle' },
              { position: 'insideUnderTitle' },
            ]}
            preComponent={[MemberAddress, LinkedPersonAddresses]}
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="identitydocs"
            name="identitydocs"
            formProps={this.props}
            pathInTables="customer.fields.identitydocs"
            formState={this.props.formState}
            nameForm="customerForm"
            fieldParentKey="identitydocs"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
            labelChildsShow={true}
            preComponentSet={{ position: 'insideUnderTitle' }}
            preComponent={LinkedPersonIdentitydocs}
          />
        </div>
        <Field
          inputName="notes"
          name="notes"
          formProps={this.props}
          pathInTables="customer.fields.notes"
          formState={this.props.formState}
          nameForm="customerForm"
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
        />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        if (log) console.log('fieldKey', fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;

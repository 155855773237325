import strFragments from './defStrFragmentsQls.js';
import strArgs from './defStrArgsQls.js';
const strQls = {};
strQls['organizer'] = {};

strQls['customeraddress'] = {};

strQls['customeraddress']['ListPage'] = `
        query CustomeraddressPageList ($id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "customeraddress"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecustomeraddresss  (id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.customeraddress}
              }   
          }    
        }
          `;
strQls['person'] = {};

strQls['person']['ListPage'] = `
        query PersonPageList ($lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "person"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagepersons  (lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.person}
              }   
          }    
        }
          `;
strQls['customer'] = {};

strQls['customer']['ListPage'] = `
        query CustomerPageList ($numbering: String, $lastname: String, $firstname: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $nosharedata: Boolean, $nonewsletter: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "customer"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecustomers  (numbering: $numbering, lastname: $lastname, firstname: $firstname, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, nosharedata: $nosharedata, nonewsletter: $nonewsletter, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.customer}
              }   
          }    
        }
          `;
strQls['tour'] = {};

strQls['tour']['ListPage'] = `
        query TourPageList ($name: String, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "tour"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagetours  (name: $name, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.tour}
              }   
          }    
        }
          `;
strQls['tourcategory'] = {};

strQls['tourcategory']['ListPage'] = `
        query TourcategoryPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "tourcategory"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagetourcategorys  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.tourcategory}
              }   
          }    
        }
          `;
strQls['transportation'] = {};

strQls['transportation']['ListPage'] = `
        query TransportationPageList ($tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "transportation"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagetransportations  (tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.transportation}
              }   
          }    
        }
          `;
strQls['tourprice'] = {};

strQls['tourprice']['ListPage'] = `
        query TourpricePageList ($tour_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "tourprice"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagetourprices  (tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.tourprice}
              }   
          }    
        }
          `;
strQls['tourroom'] = {};

strQls['tourroom']['ListPage'] = `
        query TourroomPageList ($tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "tourroom"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagetourrooms  (tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.tourroom}
              }   
          }    
        }
          `;
strQls['regonline'] = {};

strQls['regonline']['ListPage'] = `
        query RegonlinePageList ($tour_id: ID, $state: Int, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "regonline"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageregonlines  (tour_id: $tour_id, state: $state, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.regonline}
              }   
          }    
        }
          `;
strQls['registration'] = {};

strQls['registration']['ListPage'] = `
        query RegistrationPageList ($numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "registration"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageregistrations  (numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.registration}
              }   
          }    
        }
          `;
strQls['customerhistory'] = {};

strQls['customerhistory']['ListPage'] = `
        query CustomerhistoryPageList ($customer_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "customerhistory"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecustomerhistorys  (customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.customerhistory}
              }   
          }    
        }
          `;
strQls['paymenthistory'] = {};

strQls['paymenthistory']['ListPage'] = `
        query PaymenthistoryPageList ($payment_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "paymenthistory"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagepaymenthistorys  (payment_id: $payment_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.paymenthistory}
              }   
          }    
        }
          `;
strQls['registrationhistory'] = {};

strQls['registrationhistory']['ListPage'] = `
        query RegistrationhistoryPageList ($registration_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "registrationhistory"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageregistrationhistorys  (registration_id: $registration_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.registrationhistory}
              }   
          }    
        }
          `;
strQls['payment'] = {};

strQls['payment']['ListPage'] = `
        query PaymentPageList ($check_number: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $paymentmethod_id_ajournalcode_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "payment"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagepayments  (check_number: $check_number, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, paymentmethod_id_ajournalcode_id: $paymentmethod_id_ajournalcode_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.payment}
              }   
          }    
        }
          `;
strQls['checkdeposithead'] = {};

strQls['checkdeposithead']['ListPage'] = `
        query CheckdepositheadPageList ($name: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "checkdeposithead"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecheckdepositheads  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.checkdeposithead}
              }   
          }    
        }
          `;
strQls['checkdepositdet'] = {};

strQls['checkdepositdet']['ListPage'] = `
        query CheckdepositdetPageList ($checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "checkdepositdet"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecheckdepositdets  (checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.checkdepositdet}
              }   
          }    
        }
          `;
strQls['export'] = {};

strQls['export']['ListPage'] = `
        query ExportPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "export"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageexports  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.export}
              }   
          }    
        }
          `;
strQls['hotel'] = {};

strQls['hotel']['ListPage'] = `
        query HotelPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "hotel"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagehotels  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.hotel}
              }   
          }    
        }
          `;
strQls['stopspoint'] = {};

strQls['stopspoint']['ListPage'] = `
        query StopspointPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "stopspoint"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagestopspoints  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.stopspoint}
              }   
          }    
        }
          `;
strQls['service'] = {};

strQls['service']['ListPage'] = `
        query ServicePageList ($name: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "service"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageservices  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.service}
              }   
          }    
        }
          `;
strQls['city'] = {};

strQls['city']['ListPage'] = `
        query CityPageList ($name: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "city"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecitys  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.city}
              }   
          }    
        }
          `;
strQls['registrationstatus'] = {};

strQls['registrationstatus']['ListPage'] = `
        query RegistrationstatusPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "registrationstatus"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageregistrationstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.registrationstatus}
              }   
          }    
        }
          `;
strQls['confirmationstatus'] = {};

strQls['confirmationstatus']['ListPage'] = `
        query ConfirmationstatusPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "confirmationstatus"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageconfirmationstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.confirmationstatus}
              }   
          }    
        }
          `;
strQls['bank'] = {};

strQls['bank']['ListPage'] = `
        query BankPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "bank"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagebanks  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.bank}
              }   
          }    
        }
          `;
strQls['paymentmethod'] = {};

strQls['paymentmethod']['ListPage'] = `
        query PaymentmethodPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "paymentmethod"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagepaymentmethods  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.paymentmethod}
              }   
          }    
        }
          `;
strQls['paymentdocstatus'] = {};

strQls['paymentdocstatus']['ListPage'] = `
        query PaymentdocstatusPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "paymentdocstatus"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagepaymentdocstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.paymentdocstatus}
              }   
          }    
        }
          `;
strQls['customerstatus'] = {};

strQls['customerstatus']['ListPage'] = `
        query CustomerstatusPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "customerstatus"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagecustomerstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.customerstatus}
              }   
          }    
        }
          `;
strQls['gendertype'] = {};

strQls['gendertype']['ListPage'] = `
        query GendertypePageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "gendertype"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pagegendertypes  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.gendertype}
              }   
          }    
        }
          `;
strQls['userlog'] = {};

strQls['userlog']['ListPage'] = `
        query UserlogPageList ($user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "userlog"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageuserlogs  (user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.userlog}
              }   
          }    
        }
          `;
strQls['user'] = {};

strQls['user']['ListPage'] = `
        query UserPageList ($nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "user"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageusers  (nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.user}
              }   
          }    
        }
          `;
strQls['usergroup'] = {};

strQls['usergroup']['ListPage'] = `
        query UsergroupPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
          getPagesSetting(
            page_name: "usergroup"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageusergroups  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.usergroup}
              }   
          }    
        }
          `;
strQls['ejobmail'] = {};

strQls['ejobmail']['ListPage'] = `
        query EjobmailPageList ($title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "ejobmail"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageejobmails  (title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.ejobmail}
              }   
          }    
        }
          `;
strQls['etemplate'] = {};

strQls['etemplate']['ListPage'] = `
        query EtemplatePageList ($name: String, $title: String, $type: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "etemplate"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageetemplates  (name: $name, title: $title, type: $type, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.etemplate}
              }   
          }    
        }
          `;
strQls['ejobday'] = {};

strQls['ejobday']['ListPage'] = `
        query EjobdayPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "ejobday"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageejobdays  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.ejobday}
              }   
          }    
        }
          `;
strQls['ejobmonth'] = {};

strQls['ejobmonth']['ListPage'] = `
        query EjobmonthPageList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "ejobmonth"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageejobmonths  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.ejobmonth}
              }   
          }    
        }
          `;
strQls['aplan'] = {};

strQls['aplan']['ListPage'] = `
        query AplanPageList ($name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "aplan"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageaplans  (name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.aplan}
              }   
          }    
        }
          `;
strQls['acostplan'] = {};

strQls['acostplan']['ListPage'] = `
        query AcostplanPageList ($name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "acostplan"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageacostplans  (name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.acostplan}
              }   
          }    
        }
          `;
strQls['ajournalcode'] = {};

strQls['ajournalcode']['ListPage'] = `
        query AjournalcodePageList ($name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
          getPagesSetting(
            page_name: "ajournalcode"
          ) {
            currentPageSetting {
              id
              listviewFields
            }
            pagesSettings {
              id
              name
              fields
            }
          }  
          pageajournalcodes  (name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
              page {
                _page
                _totalrecords
                _totalpages
                _recordsperpage
              }
              nodes {
                 ${strFragments.ajournalcode}
              }   
          }    
        }
          `;
export default strQls;

/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import {
  toPrice,
  getInputValue,
  getToday,
  resolvePathObj,
  tryParseJSON,
  getObjFromListById,
  realTypeOf,
  tt,
} from '../../utils/commonutils';
import { getRecordFromOptionsIndirectOnFilter } from '../../utils/helper';
import { arrayPush } from 'redux-form';

const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //const log = true;
  let log = false;
  if (log) console.log('onChangeInput params:', params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    fns,
    rowsObjInmutable,
  } = params;

  const result = {};

  if (inputFullName === 'filterlistcustomerstatus_id') {
    // TWIN SU10902

    let allLines = getInputValue(formProps, 'filterlist');
    let currentLine = allLines[line];
    let changeRow = false;

    if (!newValue) {
      // if idNew is null, then clean related field
      currentLine.customerstatus_id = null;
      changeRow = true;
    }
    currentLine = { ...currentLine, customerstatus_id: newValue };

    // autocomplete  get only pure id, before compare changes with old (not really old, but previous value)

    const idNew = newValue && newValue.id ? newValue.id : newValue;

    let idOld = previousValue; // special for autocomplete, because the value already was changed, so newValue is not the future
    if (idOld && idOld.id) idOld = idOld.id;
    // change tour id then reset room, transport, and services
    if (idNew !== idOld || !idNew) {
      // if idNew is null, then clean too
      currentLine.customersubstatus_id = null;
      changeRow = true;
    }
    if (changeRow) {
      allLines[line] = currentLine;
      result.changeFieldsLater = { filterlist: allLines }; // best modern way to change fields, it's more sure
    }
  }

  return result;
};

export default crudCode;

import React from 'react';
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from '../../utils/commonutils';

const Comp = (props) => {
  const fieldValue = resolvePathObj(
    props,
    'formProps.containerPropsForm.crud_view_registration.registration.registrationregonline_id.notes',
    { notFound: null }
  );
  if (!fieldValue) {
    return null;
  }
  return (
    <div className="linkedContainer">
      <div>{fieldValue}</div>
    </div>
  );
};

export default Comp;
